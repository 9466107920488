import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { CardContainer } from "../../components/CardContainer";
import CustomInputForm from "../../components/customInputForm";
import { fetchOrders } from '../../redux-store/mod-analitics/actions/fetchOrders';
import { IReport, IOrder } from '../../redux-store/mod-analitics/reportType';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { colorPalette } from '../../utils/colorPalette';
import CustomButton from '../../components/CustomButton';
import AsyncLoader from '../../components/AsyncLoader';
import moment from 'moment';
import 'moment/locale/it';

interface IProps {
    newReport: IReport;
    setNewReport?: Dispatch<SetStateAction<IReport>>;
};

export const InitReport: React.FC<IProps> = ({ newReport, setNewReport }) => {
    const dispatch = useAppDispatch()
    const orders = useAppSelector(state => state.report.orders);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [result, setResult] = useState<IOrder[]>(undefined)
    const [reportName, setReportName] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [reportDates, setReportDates] = useState<{ startDate: string, endDate: string }>({
        startDate: "",
        endDate: ""
    });

    const validateDates = () => {
        if (reportDates.startDate.length === 0 || reportDates.endDate.length === 0) {
            return false;
        }

        // check if start date is before end date
        const startDate = new Date(reportDates.startDate);
        const endDate = new Date(reportDates.endDate);

        if (startDate > endDate) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        dispatch(fetchOrders());
    }, [dispatch]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            const response = orders.filter(order => order.name.includes(searchQuery) || order.rdcCode.includes(searchQuery));
            setResult(response);
        } else {
            setResult(orders);
        }
    }, [searchQuery, orders]);

    const handleFormatDates = (date: { startDate: string, endDate: string }) => {
        const newDates = {
            startDate: new Date(date.startDate),
            endDate: new Date(date.endDate)
        }
        return newDates;
    };

    const displayDates = (date: string) => {
        // check if string is empty
        if (date.length === 0) {
            return "Selezionare data";
        }

        // format string date with moment to dddd, MMMM Do YYYY
        moment.locale('it');
        const formattedDate = moment(date).format('dddd, D MMMM YYYY');
        return formattedDate;
    };

    const handleNextStep = (id: string) => {
        if (reportName?.length === 0) {
            setErrorMessage('Inserire un nome per il report!');
            return false;
        } else {
            setNewReport({
                ...newReport,
                name: reportName,
                commessaId: id,
                itemExpenses: [],
                date: handleFormatDates(reportDates)
            })
        }
    }

    return <>
        <div className='w-full flex flex-col items-center mt-8'>
            <TextField className='w-full' label="Nome Report" value={reportName} onChange={(e) => setReportName(e.target.value)} />
            <CardContainer title={"Inserisci le date di inizio e fine del report per continuare"} wFull={false} additionalStyle=" w-1/2 min-w-fit" titleCenter>
                <div className='flex items-center justify-around p-8'>
                    <div className='flex flex-col items-center'>
                        <TextField type="date" label="Data Inizio" InputLabelProps={{ shrink: true, required: true }} value={reportDates.startDate} onChange={(e) => setReportDates({ ...reportDates, startDate: e.target.value })} />
                        <p className='text-sm font-medium mt-8'>
                            {displayDates(reportDates.startDate)}
                        </p>
                    </div>
                    <div className='flex flex-col items-center'>
                        <TextField type="date" label="Data Fine" InputLabelProps={{ shrink: true, required: true }} value={reportDates.endDate} onChange={(e) => setReportDates({ ...reportDates, endDate: e.target.value })} />
                        <p className='text-sm font-medium mt-8'>
                            {displayDates(reportDates.endDate)}
                        </p>
                    </div>
                </div>
                {!validateDates() && <p className='text-error font-medium text-base text-center'>Inserisci date valide</p>}
            </CardContainer >
        </div >

        <p className='text-error text-sm font-semibold text-center p-2'>{errorMessage}</p>

        {validateDates() && <CardContainer title={"Cerca Commessa"} additionalStyle='pb-0'>
            <CustomInputForm label='Nome o RDC' value={searchQuery} setValue={setSearchQuery} />
            <p className='m-4 ml-0 text-lg font-semibold text-text' >Result: {result.length}</p>
            <div className='max-h-96 w-full overflow-y-scroll'>
                <AsyncLoader condition={typeof result !== 'undefined'} height={'100%'}>
                    {(result.length > 0)
                        ? result.map((item, index) => {
                            return (
                                <div className='m-6 ml-0 mt-0 flex justify-between items-center' key={index}>
                                    <div>
                                        <p className='text-lg font-bold capitalize text-text'>{item.name} </p>
                                        <p className='text-sm font-medium text-text'>RDC: {item.rdcCode}</p>
                                    </div>
                                    <Link to={(reportName?.length !== 0) ? '/monitoraggio/report/create?step=1' : null}>
                                        <CustomButton onClickHandler={() => handleNextStep(item._id)} color={colorPalette.Accent} label='Seleziona' />
                                    </Link>
                                </div>
                            )
                        })
                        : <div className='m-6 ml-0 mt-0 '>
                            <p className='text-base font-light text-center capitalize text-text' >Nessun Risultato</p>
                        </div>}
                </AsyncLoader>
            </div>
        </CardContainer>
        }
    </>
}