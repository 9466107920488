import { endpoints } from "../../utils/endpoints"
import { updateData } from "../../utils/updateData";

export const changePasswordService = async (userId: string, password: string) => {
    try {
        const data = await updateData(endpoints.changePassword, { userId, password });
        // if (data instanceof Error || typeof data === "undefined") {
        //     throw new Error(data?.message);
        // }

        return data;
    } catch (error: any) {
        console.log("ERROR changing password: ", error)
    }
}