import React, { useEffect, useState } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { useSearchParams } from "react-router-dom";
import { almec_machinery_list, tenec_machinery_list } from "../data/machinery_list";
import AsyncLoader from "../components/AsyncLoader";
import GeoMap from "../components/GeoMap";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/endpoints";
import CustomButton from "../components/CustomButton";
import { colorPalette } from "../utils/colorPalette";
import { Divider } from "@mui/material";
import { DataTable } from "../components/DataTable";
import moment from "moment";

interface IPropsDefaultInfo {
    machineSelected: any;
}

interface IPropsApiAction {
    name: string;
    action: () => void;
}

enum ApiActions {
    ANAGRAFICA_MACCHINA = "anagrafica_macchina",
    INFORMAZIONI_SENSORI = "informazioni_sensori",
    INFORMAZIONI_DISPOSITIVI = "informazioni_dispositivi",
    VALORI_SENSORI = "valori_sensori",
    INFORMAZIONI_ALLARMI = "informazioni_allarmi",
    STORICO_MACCHINA = "storico_macchina",
    POSIZIONI = "posizioni"
}

const MacchinariAlmecDetails: React.FunctionComponent = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [machineSelected, setMarchineSelected] = useState(undefined);

    // state for api actions results
    const [anagraficaMacchina, setAnagraficaMacchina] = useState(undefined);
    const [informazioniSensori, setInformazioniSensori] = useState(undefined);
    const [informazioniDispositivi, setInformazioniDispositivi] = useState(undefined);
    const [valoriSensori, setValoriSensori] = useState(undefined);
    const [informazioniAllarmi, setInformazioniAllarmi] = useState(undefined);
    const [storicoMacchina, setStoricoMacchina] = useState(undefined);
    const [posizioni, setPosizioni] = useState(undefined);
    const [lastPosition, setLastPosition] = useState(undefined);


    // state for code and value for api action
    const [code, setCode] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const sendConfiguration = async () => {
        try {
            setIsSending(true);
            setErrorDisplay(false);
            setErrorMessage("");
            const response = await fetchData(endpoints.AlmecSendConfiguration, { code, value }, "POST");
            console.log(response);
            setIsSending(false);
        } catch (error) {
            console.log("ERROR: sending configuration: " + error)
            setIsSending(false);
            setErrorDisplay(true);
            setErrorMessage("Errore nell'invio della configurazione: " + error);
        }
    }


    useEffect(() => {
        const id = searchParams.get("id");
        setMarchineSelected(almec_machinery_list.find((machine) => machine.id === id));
    }, []);

    const retrieveApiData = async (api_action: ApiActions) => {
        try {
            switch (api_action) {
                case ApiActions.ANAGRAFICA_MACCHINA:
                    const responseInfo = await fetchData(endpoints.fetchAlmecData);
                    setAnagraficaMacchina(responseInfo.data);
                    break;

                case ApiActions.INFORMAZIONI_SENSORI:
                    const responseSensors = await fetchData(endpoints.fetchAlmecSensors);
                    setInformazioniSensori(responseSensors.data);
                    break;

                case ApiActions.INFORMAZIONI_DISPOSITIVI:
                    const responseDevices = await fetchData(endpoints.fetchAlmecDevices);
                    setInformazioniDispositivi(responseDevices.data);
                    break;

                case ApiActions.VALORI_SENSORI:
                    // dt_from is equal to yesterday at 00:00:00 and dt_to is equal to today at now
                    const responseValues = await fetchData(endpoints.fetchAlmecSensorsValues + "?" + queryDate());
                    setValoriSensori(responseValues.data);
                    break;

                case ApiActions.INFORMAZIONI_ALLARMI:
                    const responseAlarms = await fetchData(endpoints.fetchAlmecAlarms + "?" + queryDate());
                    const formattedAlarms = responseAlarms.data.map((alarm: any) => {
                        return {
                            ...alarm,
                            alarm: alarm.alarm.name,
                            sensor: alarm.sensor.name
                        }
                    })
                    setInformazioniAllarmi(formattedAlarms);
                    break;

                case ApiActions.STORICO_MACCHINA:
                    const responseHistory = await fetchData(endpoints.fetchAlmecHistory + "?" + queryDate());
                    setStoricoMacchina(responseHistory.data);
                    break;

                case ApiActions.POSIZIONI:
                    const responsePositions = await fetchData(endpoints.fetchAlmecPositions + "?" + queryDate());
                    setPosizioni(responsePositions.data);

                    setLastPosition({
                        date: responsePositions.data[responsePositions.data.length - 1].date,
                        Latitude: responsePositions.data[responsePositions.data.length - 1].lat,
                        Longitude: responsePositions.data[responsePositions.data.length - 1].lng
                    });
                    break;

                default:
                    break;

            }
        } catch (error) {
            console.log("ERROR: fetching almec data: " + error)
        }
    }

    const queryDate = () => {
        return "dt_from=" + new Date(new Date().setDate(new Date().getDate() - 1)).toISOString() + "&dt_to=" + new Date().toISOString()
    }

    return (
        <AsyncLoader condition={typeof machineSelected !== 'undefined'} height={300}>
            <GlobalPageTemplate title={`Dettagli Macchinario: ${machineSelected?.name}`}>
                <>
                    <DefaultInfo machineSelected={machineSelected} />
                    <div className="flex flex-row items-center mt-8">
                        <p className="text-2xl font-medium">Azioni Api Almec (Acquisizione Informazioni)</p>
                    </div>

                    <ApiAction name="Visualizza Informazioni Macchina" action={() => retrieveApiData(ApiActions.ANAGRAFICA_MACCHINA)} />
                    {
                        (typeof anagraficaMacchina !== 'undefined')
                            ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                <p className="text-xl font-medium">Informazioni Macchina</p>
                                <p className="text-lg font-medium">Id: {anagraficaMacchina?.id}</p>
                                <p className="text-lg font-medium">Imei: {anagraficaMacchina?.imei}</p>
                                <p className="text-lg font-medium">Tipo Macchina: {anagraficaMacchina?.machine_type}</p>
                                <p className="text-lg font-medium">Descrizione: {anagraficaMacchina?.description}</p>
                                <p className="text-lg font-medium">Codice Cliente: {anagraficaMacchina?.machine_client_code}</p>
                                <p className="text-lg font-medium">Codice Azienda: {anagraficaMacchina?.company_code}</p>
                                <p className="text-lg font-medium">Realtime: {anagraficaMacchina?.realtime}</p>
                                <p className="text-lg font-medium">Scadenza Sim: {anagraficaMacchina?.sim_expiry}</p>
                                <p className="text-lg font-medium">Frequenza Check Allarmi: {anagraficaMacchina?.check_alarms_frequency}</p>
                            </div>
                            : null
                    }
                    <Divider />

                    <ApiAction name="Visualizza Informazioni Sensori" action={() => retrieveApiData(ApiActions.INFORMAZIONI_SENSORI)} />
                    {
                        (typeof informazioniSensori !== 'undefined')
                            ? (informazioniSensori.length > 0) ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                <p className="text-xl font-medium">Informazioni Sensori</p>
                                <DataTable
                                    size="small"
                                    headerFields={["id", "nome", "sequenza", "uom", "numero", "tipo_dato", "tipo_dato_testo", "default", "bit_start", "bit_length", "signed", "is_virtual", "descrizione"]}
                                    fieldsToPrint={["id", "name", "sequence", "uom", "number", "sensor_data_type", "sensor_data_type_text", "default", "bit_start", "bit_length", "signed", "is_virtual", "description"]}
                                    data={informazioniSensori}
                                    defaultSortField={"id"}
                                    defaultSortDirection={"asc"}
                                    showDetailsButton={false}
                                    redirectPath=""
                                />
                            </div> : <p className="text-md font-medium p-4">Nessun sensore trovato.</p>
                            : null
                    }
                    <Divider />


                    <ApiAction name="Visualizza Valori Sensori (ultimo giorno)" action={() => retrieveApiData(ApiActions.VALORI_SENSORI)} />
                    {
                        (typeof valoriSensori !== 'undefined')
                            ? (valoriSensori.length > 0)
                                ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                    <p className="text-xl font-medium">Valori Sensori</p>
                                    <DataTable
                                        size="small"
                                        headerFields={["data", "valore", "id sensore", "nome sensore", "descrizione sensore"]}
                                        fieldsToPrint={["date", "value", "sensorId", "sensorName", "sensorDescription"]}
                                        data={valoriSensori}
                                        defaultSortField={"date"}
                                        defaultSortDirection={"asc"}
                                        showDetailsButton={false}
                                        redirectPath=""
                                    />
                                </div>
                                : <p className="text-md font-medium p-4">Nessun valore sensore trovato.</p>
                            : null
                    }
                    <Divider />

                    <ApiAction name="Visualizza Informazioni Allarmi (ultimo giorno)" action={() => retrieveApiData(ApiActions.INFORMAZIONI_ALLARMI)} />
                    {
                        (typeof informazioniAllarmi !== 'undefined')
                            ? (informazioniAllarmi.length > 0)
                                ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                    <p className="text-xl font-medium">Informazioni Allarmi </p>

                                    <DataTable
                                        size="small"
                                        headerFields={["id", "valore", "stato", "allarme", "sensore", "data inizio", "data fine"]}
                                        fieldsToPrint={["id", "last_value", "status", "alarm", "sensor", "firedDateIn", "firedDateOut"]}
                                        data={informazioniAllarmi}
                                        defaultSortField={"id"}
                                        defaultSortDirection={"asc"}
                                        showDetailsButton={false}
                                        redirectPath=""
                                    />
                                </div>
                                : <p className="text-md font-medium p-4">Nessun allarme trovato.</p>
                            : null
                    }
                    <Divider />

                    <ApiAction name="Visualizza Storico Macchina (ultimo giorno)" action={() => retrieveApiData(ApiActions.STORICO_MACCHINA)} />
                    {
                        (typeof storicoMacchina !== 'undefined')
                            ? (storicoMacchina.length > 0)
                                ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                    <p className="text-xl font-medium">Storico Macchina</p>

                                    <DataTable
                                        size="small"
                                        headerFields={["id", "utente", "topic", "nome topic", "azione", "data azione", "url ref file", "data", "descrizione macchina", "nome utente"]}
                                        fieldsToPrint={["id", "user", "topic", "topicName", "action", "dtAction", "urlRefFile", "data", "machineDescr", "userName"]}
                                        data={storicoMacchina}
                                        defaultSortField={"date"}
                                        defaultSortDirection={"asc"}
                                        showDetailsButton={false}
                                        redirectPath=""
                                    />
                                </div>
                                : <p className="text-md font-medium p-4">Nessun storico.</p>
                            : null
                    }
                    <Divider />

                    <ApiAction name="Visualizza Ultima Posizione (ultimo giorno)" action={() => retrieveApiData(ApiActions.POSIZIONI)} />
                    {
                        (typeof posizioni !== 'undefined')
                            ? (posizioni.length > 0)
                                ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                    <p className="text-xl font-medium">Ultime Posizione</p>
                                    <DataTable
                                        size="small"
                                        headerFields={["data", "latitudine", "longitudine"]}
                                        fieldsToPrint={["date", "lat", "lng"]}
                                        data={posizioni}
                                        defaultSortField={"date"}
                                        defaultSortDirection={"asc"}
                                        showDetailsButton={false}
                                        redirectPath=""
                                    />

                                    <p className="text-2xl font-medium mt-8 mb-4">Geolocalizzazione ultima posizione registrata {moment(lastPosition.date).format("DD/MM/YYYY-HH:mm:ss")}:</p>
                                    <GeoMap centerLatidude={lastPosition.Latitude} centerLongitude={lastPosition.Longitude} data={lastPosition} />
                                </div>
                                : <p className="text-md font-medium p-4">Nessuna posizione trovata.</p>
                            : null
                    }
                    < Divider />

                    <div className="flex flex-row items-center mt-8">
                        <p className="text-2xl font-medium">Azioni Api Almec (Invio Dati)</p>
                    </div>

                    <p className="text-xl font-medium mt-4 mb-4">Invio Configurazione Macchina</p>
                    <p className="text-lg font-medium">Esempio configurazione:
                        <span className="text-green-500">{" {\"code\":20, \"value\": 200}, {\"code\":30, \"value\": \"DF\"}"}</span>
                    </p>
                    <p className="text-sm font-medium mb-4">NB: la configurazione inviata dovrà corrispondere con l'anagrafica impostata dal
                        portale, gli elementi che non coincidono non verranno inviati.
                        Questa operazione verrà aggiunta allo storico come se fosse stata fatta dal portale.  </p>

                    <p className="text-lg font-medium mt-4 mb-4">Invio Configurazione Macchina:</p>
                    {/* input form for the code and one for the value */}
                    <input className="border-2 border-gray-300 p-2 rounded-lg w-full" type="text" placeholder="Codice" onChange={(value) => { setCode(value.toString()) }} />
                    <input className="border-2 border-gray-300 p-2 rounded-lg w-full mt-4 mb-8" type="text" placeholder="Valore" onChange={(value) => { setValue(value.toString()) }} />

                    <div>{errorDisplay && <p className="text-red-500 font-medium m-4">{errorMessage}</p>}</div>

                    <CustomButton
                        label="Invia"
                        color={colorPalette.Primary}
                        onClickHandler={() => { sendConfiguration() }}
                        disabled={isSending}
                    />
                </>
            </GlobalPageTemplate>
        </AsyncLoader>
    )
}


const DefaultInfo: React.FunctionComponent<IPropsDefaultInfo> = ({ machineSelected }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Nome Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Tipo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.type}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Data Creazione:</p>
                        <p className="text-lg">{machineSelected?.created_at}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ApiAction: React.FunctionComponent<IPropsApiAction> = ({ name, action }) => {
    return (
        <div className="flex flex-row items-center mt-4 mb-4 justify-between w-full">
            <p className="text-xl font-medium">{name}</p>
            <CustomButton
                label="Esegui"
                color={colorPalette.Primary}
                onClickHandler={() => action()}
            />
        </div>
    )
}

export default MacchinariAlmecDetails;