import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { replaceS3Elements } from "../reportSlice";
import { endpoints } from "../../../utils/endpoints"

export const fetchStorageFiles = (prefix: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await fetchData(endpoints.fetchS3Elements, { prefix: prefix }, 'POST');
            dispatch(
                replaceS3Elements(response)
            )
        } catch (error: any) {
            console.log("ERROR fetching report: ", error)
        }
    }
}