import moment from "moment";
import { useEffect, useState } from "react";
import CustomButton from "../components/CustomButton";
import FuelDataFilter from "../components/FuelDataFIlter";
import PopoverContainer from "../components/PopoverContainer";
import { RifTotType, fetchFuelData } from "../redux-store/mod-analitics/actions/fetchFuelData";
import { getFuelDataLabels } from "../redux-store/mod-analitics/actions/getFuelDataLabels";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import Fuel from "../templates/Fuel";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { colorPalette } from "../utils/colorPalette";
import TankStatus from "../templates/TankStatus";

export enum filterValue {
    currentMonth,
    lastThreeMonths,
    lastSixMonths,
    lastYear,
    year,
    allTime
};

const FuelView: React.FunctionComponent = () => {

    // REDUX 
    const dispatch = useAppDispatch();
    const fuelDatas = useAppSelector(state => state.report.fuelDatas);
    const labels = useAppSelector(state => state.report.fuelDataLabels);

    // DATE PICKER STATES
    const [yearToFilter, setYearToFilter] = useState<number>(undefined)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popover, setPopover] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchFuelData());
        dispatch(getFuelDataLabels());
    }, [dispatch]);

    /**
        * Handles the onClick event for the submit button in the filter popover.
        * Dispatches an action to fetch fuel data based on the selected filter value and year (if applicable).
        * Sets the chart labels based on the fetched data.
        * 
        * @param value The filter value selected by the user.
        * @param year The year selected by the user (only applicable if `value` is 4).
    */
    const onClickSubmit = (value: filterValue, year?: number) => {
        switch (value) {
            case 0:
                setYearToFilter(undefined);
                dispatch(fetchFuelData());
                dispatch(getFuelDataLabels());
                break;
            case 1:
                setYearToFilter(undefined);
                dispatch(fetchFuelData(moment().startOf("month").subtract(2, "months").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), RifTotType.MONTHLY));
                dispatch(getFuelDataLabels(moment().startOf("month").subtract(2, "months").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")));
                break;
            case 2:
                setYearToFilter(undefined);
                dispatch(fetchFuelData(moment().startOf("month").subtract(5, "months").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), RifTotType.MONTHLY));
                dispatch(getFuelDataLabels(moment().startOf("month").subtract(5, "months").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")));
                break;
            case 3:
                setYearToFilter(moment().year());
                dispatch(fetchFuelData(moment().startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), RifTotType.MONTHLY));
                dispatch(getFuelDataLabels(moment().startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")));
                break;
            case 4:
                setYearToFilter(year);
                if (year) {
                    const startDate = moment().year(year).startOf("year").format("YYYY-MM-DD");
                    const endDate = moment().year(year).endOf("year").format("YYYY-MM-DD");
                    dispatch(fetchFuelData(startDate, endDate, RifTotType.MONTHLY));
                    dispatch(getFuelDataLabels(startDate, endDate));
                };
                break;
            case 5:
                setYearToFilter(undefined);
                dispatch(fetchFuelData(moment().year(2017).startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), RifTotType.YEARLY));
                dispatch(getFuelDataLabels(moment().year(2017).startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")));
                break;
            default:
                setYearToFilter(undefined);
                dispatch(fetchFuelData());
                dispatch(getFuelDataLabels());
                break;
        };
        setPopover(false);
    };

    /** Open the Popover */
    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setPopover(true);
    };

    const dateInput = (<CustomButton onClickHandler={handleOpenPopover} color={colorPalette.Accent} label='Filtra' />);

    return <GlobalPageTemplate title='Analisi Carburante' headerElement={dateInput}>
        <>
            {/* FUEL DATA CHARTS */}
            <Fuel
                labels={labels}
                data={fuelDatas}
                year={yearToFilter} />

            <PopoverContainer
                anchorEl={anchorEl}
                isOpen={popover}
                setIsOpen={setPopover}
                content={<FuelDataFilter onClickSubmit={onClickSubmit} />}
                title='Seleziona un filtro' />

            <TankStatus />
        </>
    </GlobalPageTemplate>
}


export default FuelView