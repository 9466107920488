import { Dispatch } from "redux"
import { AlertColor } from "../uiType"
import { uiManagerActions } from "../UI-Manager-slice"

export const setSnackbar = (message: string, severity: AlertColor) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(
                uiManagerActions.openSnackbar({
                    isOpen: true,
                    message: message,
                    severity: severity
                })
            )
        } catch (error: any) {
            console.log("ERROR: ", error)
        }
    }
}