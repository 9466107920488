import React from 'react'
import { colorPalette } from '../utils/colorPalette'

interface IProps {
    size: 'sm' | 'md' | 'lg' | number,
    label: string,
    customStyle?: React.CSSProperties,
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase',
    weight?: number | 'bold'
};

const CustomText: React.FunctionComponent<IProps> = (props) => {
    const size: number = (typeof props.size === 'number') ? props.size : (props.size === 'sm') ? 10 : (props.size === 'md') ? 14 : 20
    return (<div style={props.customStyle}>
        <p style={{ color: colorPalette.Text, fontSize: size, textTransform: props.textTransform, fontWeight: props.weight }}>{props.label}</p>
    </div>
    )
}

export default CustomText