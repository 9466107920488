import { createSlice, Slice } from "@reduxjs/toolkit";
import { IMonthlyMovement } from "./magoType";

interface MagoState {
    monthlyMovement: IMonthlyMovement[]
};

const initialState: MagoState = {
    monthlyMovement: []
};

export const MagoSlice: Slice<MagoState> = createSlice({
    name: "mago",
    initialState,
    reducers: {
        replaceMonthlyMovement(state, action) {
            state.monthlyMovement = action.payload.monthlyMovement
        }
    }
});

export const { replaceMonthlyMovement } = MagoSlice.actions;
export default MagoSlice.reducer;