import React, { ReactNode, useEffect } from "react";
import { ItemExpense } from "../redux-store/mod-analitics/reportType";

interface IProps {
    expensesState: ItemExpense[];
};

const TotalExpenseDisplay: React.FunctionComponent<IProps> = ({ expensesState }) => {

    const [totalExpense, setTotalExpense] = React.useState(0);

    useEffect(() => {
        const total = expensesState.reduce((acc, curr) => {
            return acc + curr.qta * curr.price
        }, 0)
        setTotalExpense(total)
    }, [expensesState])


    return <div>
        <h3>Totale spese: {totalExpense.toFixed(2)}€</h3>
    </div>
}

export default TotalExpenseDisplay;
