import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { replaceOrders } from "../reportSlice";
import { endpoints } from "../../../utils/endpoints"

export const fetchOrders = () => {
    return async (dispatch: Dispatch) => {
        try {
            const orders = await fetchData(endpoints.source + endpoints.fetchOrders);
            dispatch(
                replaceOrders({
                    orders: orders.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching orders: ", error)
        }
    }
}