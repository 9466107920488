import { fetchData } from "../../utils/fetchData";
import { endpoints } from "../../utils/endpoints";

export const checkToken = async () => {
    try {
        const response = await fetchData(endpoints.checkToken, {}, 'POST');

        return response;

    } catch (error: any) {
        console.log("ERROR checking token: ", error)
    }
};