import React from "react";
import CustomButton from "../components/CustomButton";
import { colorPalette } from "../utils/colorPalette";
import { IOrder } from "../redux-store/mod-analitics/reportType";
import BillingBox from "../components/BillingBox";
import { Billing } from "../enums/enumBilling";
import { useNavigate } from "react-router-dom";

const HEADER_FIELDS = [
  "Id",
  "Name",
  "QTA",
  "QTAUnity"
];

interface IProps {
  title: string,
  RDC: string,
  profit: number,
  credit: number,
  expense: number,
  status: string,
  id: string,
  reports: any[],
  date: string,
  order: IOrder
};

const Order: React.FunctionComponent<IProps> = ({ title, RDC, profit, credit, expense, status, order, date, reports }) => {

  const navigate = useNavigate();


  const showDetails = () => {
    // navigate to details page
    navigate(`/monitoraggio/commesse/details/?id=${order._id}`);
  };


  return (
    <div
      className="flex w-full rounded-lg shadow-md p-4 mb-10 mt-4"
      style={{ backgroundColor: colorPalette.Background }}
    >
      <div className="flex justify-between w-full">
        <div className="flex flex-col justify-between h-full gap-4 w-full">
          <div className="flex flex-row justify-between w-full">
            <div className="w-2/5">
              <p className="text-lg font-semibold uppercase mb-2" style={{ color: colorPalette.Text }}>{title}</p>
              <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Codice RDC: ${RDC}`}</p>
              <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Numero Report Caricati: ${reports.length}`}</p>
              <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Data Commessa: ${date}`}</p>
            </div>

            <div className="w-full flex  flex-col">
              <p className="text-lg font-medium mb-2" style={{ color: colorPalette.Text }}>Fatturazione:</p>
              <div className="flex gap-6">
                <BillingBox billingValue={credit} billingType={Billing.CREDIT} />
                <BillingBox billingValue={profit} billingType={Billing.PROFIT} />
                <BillingBox billingValue={expense} billingType={Billing.EXPENSE} />
              </div>

              <div className="flex flex-row mt-8 gap-4 justify-end">
                <CustomButton label="Visualizza" color={colorPalette.Info} onClickHandler={showDetails} />
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Order;
