import { IOrder } from "../../redux-store/mod-analitics/reportType";
import { endpoints } from "../../utils/endpoints";
import { updateData } from "../../utils/updateData";

export const updateOrder = async (item: IOrder, id: string) => {
    try {
        const data = await updateData(endpoints.source + endpoints.updateOrder + id, item);

        if (data instanceof Error || typeof data === "undefined") {
            throw new Error(data.message);
        }

        return data;
    } catch (error: any) {
        console.log("ERROR updating order: ", error)
        return new Error(error);
    }
}