import { createSlice, Slice } from "@reduxjs/toolkit";
import { ItemTemplate, IOrder, IReport, IFuelData, IDateLabel, IS3Elements } from "./reportType";

const initialDateLabels: IDateLabel = {
    dateLabels: {
        days: [],
        months: [],
        years: []
    }
};

interface IReportState {
    orders: IOrder[],
    reports: IReport[],
    customItem: { "currentPage": number, "totalPages": number, "totalItems": number, "data": ItemTemplate[] },
    magoItem: { "currentPage": number, "totalPages": number, "totalItems": number, "data": ItemTemplate[] },
    fuelDatas: IFuelData[],
    fuelDataLabels: IDateLabel,
    s3Elements: IS3Elements,
    storageHistory: { name: string, prefix: string }[],
    uploadProgress: { [key: string]: number }
};


const initialState: IReportState = {
    orders: [],
    reports: [],
    customItem: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    magoItem: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    fuelDatas: [],
    fuelDataLabels: initialDateLabels,
    s3Elements: undefined,
    storageHistory: [{ name: "Home", prefix: "zecchini2023/" }],
    uploadProgress: {}

};

export const ReportSlice: Slice<IReportState> = createSlice({
    name: "report",
    initialState,
    reducers: {
        replaceOrders(state, action) {
            state.orders = action.payload.orders;
        },
        replaceReports(state, action) {
            state.reports = action.payload.reports;
        },
        replaceCustomItem(state, action) {
            state.customItem = action.payload.customItem;
        },
        replaceMagoItem(state, action) {
            state.magoItem = action.payload.magoItem;
        },
        replaceFuelData(state, action) {
            state.fuelDatas = action.payload.fuelDatas;
        },
        replaceFuelDataLabels(state, action) {
            state.fuelDataLabels = action.payload.fuelDataLabels;
        },
        replaceS3Elements(state, action) {
            state.s3Elements = action.payload;
        },
        addHistory(state, action) {
            state.storageHistory.push({
                name: action.payload.name,
                prefix: action.payload.prefix
            });
        },
        popHistory(state) {
            state.storageHistory = state.storageHistory.slice(0, -1);
        },
        updateProgress(state, action) {
            const { name, percent } = action.payload;
            state.uploadProgress[name] = percent;
        },
        removeProgress(state, action) {
            const name = action.payload;
            delete state.uploadProgress[name];
        }
    }
});

export default ReportSlice.reducer;
export const { replaceOrders, replaceReports, replaceCustomItem, replaceMagoItem, replaceFuelData, replaceFuelDataLabels, replaceS3Elements, addHistory, popHistory, updateProgress, removeProgress } = ReportSlice.actions;