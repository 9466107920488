import { useEffect, useState } from "react";
import { GeoMapV2 } from "../components/GeoMap";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { Chip } from "@mui/material";
import { endpoints } from "../utils/endpoints";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { waynet_machinery_list } from "../data/machinery_list";
import moment from "moment";
import { fetchData } from "../utils/fetchData";

const MacchinariWaynetDetails: React.FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [machine, setMachine] = useState(undefined);
    const [apiData, setApiData] = useState(undefined);
    const [lastEventData, setLastEventData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const [messageBroadcast, setMessageBroadcast] = useState("");
    const [fleet, setFleet] = useState("");
    const [errorBroadcast, setErrorBroadcast] = useState(false);
    const [errorBroadcastMessage, setErrorBroadcastMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [messageToVehicle, setMessageToVehicle] = useState("");
    const [errorVehicle, setErrorVehicle] = useState(false);
    const [errorVehicleMessage, setErrorVehicleMessage] = useState("");
    const [successMessageVehicle, setSuccessMessageVehicle] = useState("");

    const [releName, setReleName] = useState("");
    const [releValue, setReleValue] = useState("");
    const [errorCommand, setErrorCommand] = useState(false);
    const [errorCommandMessage, setErrorCommandMessage] = useState("");
    const [successMessageRele, setSuccessMessageRele] = useState("");

    useEffect(() => {
        const id = searchParams.get("id");
        waynet_machinery_list.forEach((machine) => {
            if (machine.id === id) {
                setMachine(machine);
            }
        })

        // call api and set api data to state apiData
        retrieveApiData();
    }, []);

    useEffect(() => {
        if (machine?.id) {
            // call api and set api data to state apiData
            retrieveLastEventData();
        }
    }, [machine]);

    const broadCastMessage = async () => {
        try {
            setErrorBroadcast(false);
            setErrorBroadcastMessage("");

            const response: any = await fetchData(endpoints.waynetBroadcast, {
                message: messageBroadcast,
                fleet: fleet
            }, "POST")

            console.log(response)

            setMessageBroadcast("");
            setSuccessMessage("Messaggio inviato con successo");
        } catch (error: any) {
            setErrorBroadcast(true);
            setErrorBroadcastMessage(error.message);
        }
    }

    const sendMessageToVehicle = async () => {
        try {
            setErrorVehicle(false);
            setErrorVehicleMessage("");

            const response: any = await fetchData(endpoints.waynetSendMessage, {
                message: messageToVehicle,
                vehicle: machine?.id
            }, "POST")

            console.log(response)

            setMessageToVehicle("");
            setSuccessMessageVehicle("Messaggio inviato con successo");
        } catch (error: any) {
            setErrorVehicle(true);
            setErrorVehicleMessage(error.message);
        }
    }

    const sendCommandToVehicle = async () => {
        try {
            setErrorCommand(false);
            setErrorCommandMessage("");

            const response: any = await fetchData(endpoints.waynetSendCommand, {
                vehicle: machine?.id,
                releName: releName,
                releValue: releValue
            }, "POST")

            console.log(response)

            setReleName("");
            setReleValue("");
            setSuccessMessageRele("Comando inviato con successo");
        } catch (error: any) {
            setErrorCommand(true);
            setErrorCommandMessage(error.message);
        }
    }

    const retrieveApiData = async () => {
        try {
            setIsLoading(true);
            const response: any = await axios.get(endpoints.fetchWaynetVehicleList + "?id=" + searchParams.get("id"));

            console.log(response)

            if (!response.data.Vehicle) {
                console.log("NO VEHICLE FOUND")
                return;
            }

            setApiData(response.data.Vehicle);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
            console.log("ERROR fetchin tenec: " + error)
        }
    }

    const retrieveLastEventData = async () => {
        try {
            setIsLoading(true);

            const response: any = await axios.get(endpoints.fetchWaynetVehicleLastEvent + "?id=" + machine?.id);

            if (!response.data.VehicleEvent) {
                console.log("NO EVENT FOUND")
                return;
            }

            setLastEventData(response.data.VehicleEvent);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
            console.log("ERROR fetchin tenec: " + error)
        }
    }

    useEffect(() => {
        console.log("API DATA: ", lastEventData)
    }, [lastEventData])

    return (
        <GlobalPageTemplate title={`Dettagli Macchinario: ${machine?.name}`}>
            <>
                {/* api retrieved info */}
                {(!isLoading)
                    ? <>
                        <DefaultInfo machineSelected={machine} />

                        <div className="flex flex-col mt-4">
                            <p className="text-lg font-medium">AppVersion: {apiData?.AppVersion}</p>
                            <p className="text-lg font-medium">Brand: {apiData?.Brand}</p>
                            <p className="text-lg font-medium">Numero identificativo del veicolo: {apiData?.ChassisNumber}</p>
                            <p className="text-lg font-medium">Codice Hw: {apiData?.CodeHw}</p>
                            <p className="text-lg font-medium">Modello: {apiData?.Model}</p>
                            <p className="text-lg font-medium">Nome Missione: {apiData?.MissionName}</p>
                            <p className="text-lg font-medium">Id Missione: {apiData?.IdMission}</p>
                            <p className="text-lg font-medium mt-4 mb-4">Attributi:</p>
                            <div className="flex flex-row flex-wrap">
                                {
                                    (apiData?.Attributes?.VehicleAttribute)
                                        ? apiData?.Attributes?.VehicleAttribute.map((item: any, index: number) => {
                                            return (
                                                <Chip key={index} label={item.Code} className="m-1" color="info" />
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </div>

                        <p className="text-2xl font-medium mt-8">Geolocalizzazione:</p>
                        {
                            (lastEventData && lastEventData?.Latitude && lastEventData?.Longitude)
                                ? <div className="flex flex-col">
                                    <p className="text-lg font-medium">Ultimo Aggiornamento posizione: {moment(lastEventData?.PositionDate).locale('it').format('DD/MM/YYYY HH:mm:ss')}</p>
                                    <GeoMapV2 centerLatidude={lastEventData?.Latitude} centerLongitude={lastEventData?.Longitude} />
                                </div>
                                : <p className="p-8 text-center text-sm font-medium text-text">Non sono presenti dati di geolocalizzazione</p>
                        }

                        <p className="text-2xl font-medium mt-8">Messaggio Broadcast:</p>
                        <p className="text-lg font-medium">Messaggio da inviare</p>
                        <input type="text" className="border border-gray-300 p-2 w-full" />
                        <button className="bg-primary text-white p-2 mt-4 w-full" onClick={broadCastMessage}>Invia</button>
                        {errorBroadcast && <p className="text-red-500 text-sm mt-2">{errorBroadcastMessage}</p>}

                        <p className="text-2xl font-medium mt-8">Invio Messaggio</p>
                        <p className="text-lg font-medium">Messaggio da inviare</p>
                        <input type="text" className="border border-gray-300 p-2 w-full" />
                        <button className="bg-primary text-white p-2 mt-4 w-full" onClick={broadCastMessage}>Invia</button>
                        {errorVehicle && <p className="text-red-500 text-sm mt-2">{errorVehicleMessage}</p>}

                        <p className="text-2xl font-medium mt-8">Invio Commando Remoto</p>
                        <p className="text-lg font-medium">Nome Rele</p>
                        <input type="text" className="border border-gray-300 p-2 w-full" />
                        <p className="text-lg font-medium">Valore</p>
                        <input type="text" className="border border-gray-300 p-2 w-full" />
                        <button className="bg-primary text-white p-2 mt-4 w-full" onClick={broadCastMessage}>Invia</button>
                        {errorCommand && <p className="text-red-500 text-sm mt-2">{errorCommandMessage}</p>}

                    </>
                    : <p className="p-8 text-center text-sm font-medium text-text">Caricamento...</p>
                }
            </>
        </GlobalPageTemplate>
    )
}

interface IPropsDefaultInfo {
    machineSelected: any
}

const DefaultInfo: React.FunctionComponent<IPropsDefaultInfo> = ({ machineSelected }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Identificativo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.id}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Nome Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Tipo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.type}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Data Creazione:</p>
                        <p className="text-lg">{machineSelected?.created_at}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MacchinariWaynetDetails;