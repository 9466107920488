import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { replaceReports } from "../reportSlice";
import { endpoints } from "../../../utils/endpoints"

export const fetchReports = () => {
    return async (dispatch: Dispatch) => {
        try {
            const reports = await fetchData(endpoints.source + endpoints.fetchReports);
            dispatch(
                replaceReports({
                    reports: reports.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching report: ", error)
        }
    }
}