import { configureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { DieselwebSlice } from "./mod-dieselweb/dieselwebSlice";
import { MagoSlice } from "./mod-mago/magoSlice";
import { ReportSlice } from "./mod-analitics/reportSlice";
import uiManagerSlice from "./uiManager/UI-Manager-slice";
import { AuthSlice } from "./auth/authSlice";

export const store = configureStore({
    reducer: {
        dieselweb: DieselwebSlice.reducer,
        mago: MagoSlice.reducer,
        report: ReportSlice.reducer,
        auth: AuthSlice.reducer,
        uiManager: uiManagerSlice.reducer
    }
});

// To use useAppDispatch & useAppSelector correctly with typescript you need this two line of code

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;