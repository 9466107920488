import React, { Dispatch, SetStateAction } from 'react';
import * as IoIcons from 'react-icons/io';
import { colorPalette } from '../utils/colorPalette';
import { Popover } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomButton from './CustomButton';

interface IProps {
    anchorEl: SVGElement | HTMLButtonElement;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>
    content: JSX.Element | React.FC;
    title: string;
    button?: boolean;
    label?: string;
}


const PopoverContainer: React.FC<IProps> = ({ anchorEl, isOpen, setIsOpen, content, title, button, label }) => {

    const handleClose = () => {
        setIsOpen(false);
    };


    return (
        <Popover
            id={isOpen ? 'simple-popover' : undefined}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className='p-4 flex flex-col' style={{ backgroundColor: colorPalette.Background, minWidth: 450 }}>
                <>
                    <div className='flex justify-between items-baseline'>
                        <p className='text-lg font-bold text-text' >{title}</p>
                        <div className='p-1 rounded-lg border border-gray-200'>
                            <IoIcons.IoMdClose onClick={handleClose} size={20} color='gray' />
                        </div>
                    </div>
                    {content}
                    <div className='flex items-end self-end h-full'>
                        {
                            (button)
                                ? <Link to={'/notifiche'}>
                                    <CustomButton onClickHandler={handleClose} color={colorPalette.Accent} label={label} />
                                </Link>
                                : null
                        }
                    </div>
                </>
            </div>
        </Popover>
    )
}

export default PopoverContainer