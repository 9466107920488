import { mainSource } from "../config/mainSource";

interface IEndpoints {
    source: string,

    //fetch
    fetchOrders: string,
    fetchReports: string,
    fetchItemTemplates: string,
    fetchFuelDatas: string,
    fetchVehicles: string,
    fetchDrivers: string,
    fetchSupplies: string,
    fetchTankRefills: string,
    fetchTanks: string,
    fetchMonthlyMovement: string,
    fetchStorageFiles: string,
    fetchStorageFolders: string,
    fetchS3Elements: string,

    //get
    searchItemTemplates: string,
    dailyRifCisDates: string,
    searchAutCodes: string,
    searchGasPrice: string,
    searchMezziTarga: string,
    fetchItemTemplateById: string,
    fetchReportById: string,
    fetchOrderById: string,
    fetchMonthlyMovementById: string,
    getFileDownloadBuffer: string,
    searchFile: string,

    //create
    createOrder: string,
    createReport: string,
    createItemTemplate: string,
    uploadToS3: string,
    createFolder: string,

    //update
    updateItemTemplate: string,
    updateOrder: string,
    updateTankName: string,

    //delete
    deleteOrderById: string,
    deleteReportById: string,
    deleteFileStorage: string,
    deleteFolderDB: string,

    //auth
    fetchUsers: string,
    loginRoute: string,
    registerRoute: string,
    changePassword: string,
    checkToken: string

    //macchinari tenec
    fetchTenecData: string,

    //macchinari almec
    fetchAlmecData: string,
    fetchAlmecSensors: string,
    fetchAlmecSensorsValues: string,
    fetchAlmecDevices: string,
    fetchAlmecPositions: string,
    fetchAlmecAlarms: string,
    fetchAlmecAlarmValues: string,
    fetchAlmecHistory: string

    AlmecSendConfiguration: string,

    //macchinari movimatica
    fetchMovimaticaVehicles: string,
    fetchMovimaticaVehicleById: string,
    fetchMovimaticaVehiclePositions: string,
    fetchMovimaticaVehicleUpdate: string,
    fetchMovimaticaVehicleTrips: string,
    fetchMovimaticaVehicleActiveAlarms: string,
    fetchMovimaticaVehicleTelemetry: string,
    fetchMovimaticaVehicleTelemetryStats: string
    fetchMovimaticaVehicleActivities: string,

    //post
    postMovimaticaVehicleActivity: string,

    // delete
    deleteMovimaticaVehicleActivity: string,

    //macchinari trackVision
    fetchTrackVisionMachineUtilizationReport: string,
    fetchTrackVisionMachineData: string,
    fetchTrackVisionAccessHistory: string,

    //macchinari waynet
    fetchWaynetVehicleList: string,
    fetchWaynetDriverList: string,
    fetchWaynetVehicleLastEvent: string,
    fetchWaynetVehicleHistoryEvents: string

    waynetBroadcast: string,
    waynetSendMessage: string,
    waynetSendCommand: string,
};

export const endpoints: IEndpoints = {
    source: mainSource + '/report',

    //fetch
    fetchOrders: '/order/get-all',
    fetchReports: '/report/get-all',
    fetchItemTemplates: '/item-template/get-all-paginated?',
    fetchFuelDatas: mainSource + '/dieselweb/curated/rif-tot-by-date-range',
    fetchVehicles: mainSource + "/dieselweb/mezzi-paginated",
    fetchDrivers: mainSource + "/dieselweb/autisti-paginated",
    fetchSupplies: mainSource + "/dieselweb/rifornimenti-paginated",
    fetchTankRefills: mainSource + "/dieselweb/carichi-paginated",
    fetchTanks: mainSource + "/dieselweb/cisterne",
    fetchMonthlyMovement: mainSource + "/mago/get/monthly-report",
    fetchStorageFiles: mainSource + '/storage/file/get-all-filtered',
    fetchStorageFolders: mainSource + '/storage/folder/get-all-filtered',
    fetchS3Elements: mainSource + '/storage/file/listS3',

    //get
    searchItemTemplates: '/item-template/search',
    searchAutCodes: mainSource + '/dieselweb/aut-codes/search',
    searchGasPrice: mainSource + '/dieselweb/gas-price',
    searchMezziTarga: mainSource + '/dieselweb/mezzi-targa/search',
    dailyRifCisDates: mainSource + '/dieselweb/rifs/by-date',
    fetchItemTemplateById: '/item-template/get-by-id/',
    fetchReportById: '/report/get-by-id/',
    fetchOrderById: '/order/get-by-id/',
    fetchMonthlyMovementById: mainSource + "/mago/get/monthly-report-by-id/",
    getFileDownloadBuffer: mainSource + '/storage/file/download',
    searchFile: mainSource + '/storage/file/search',


    //create
    createOrder: '/order/create',
    createReport: '/report/create',
    createItemTemplate: '/item-template/create',
    uploadToS3: mainSource + '/storage/file/upload',
    createFolder: mainSource + '/storage/folder/create',

    //update need id after endpoint --> /item-template/update/:id
    updateItemTemplate: '/item-template/update/',
    updateOrder: '/order/update/',
    updateTankName: mainSource + '/dieselweb/cisterne/update/',

    //delete
    deleteOrderById: '/order/delete/',
    deleteReportById: '/report/delete/',
    deleteFileStorage: mainSource + '/storage/file/delete',
    deleteFolderDB: mainSource + '/storage/folder/delete/',

    //auth
    fetchUsers: mainSource + '/user/admin/users',
    loginRoute: mainSource + '/user/login',
    registerRoute: mainSource + '/user/register',
    changePassword: mainSource + '/user/admin/change-password',
    checkToken: mainSource + '/user/check-token',

    //macchinari tenec
    fetchTenecData: mainSource + '/machinery/tenec/info',

    //macchinari almec
    fetchAlmecData: mainSource + '/machinery/almec/info',
    fetchAlmecSensors: mainSource + '/machinery/almec/sensors',
    fetchAlmecSensorsValues: mainSource + '/machinery/almec/sensors-values',
    fetchAlmecDevices: mainSource + '/machinery/almec/devices',
    fetchAlmecPositions: mainSource + '/machinery/almec/positions',
    fetchAlmecAlarms: mainSource + '/machinery/almec/alarms',
    fetchAlmecAlarmValues: mainSource + '/machinery/almec/alarmValues',
    fetchAlmecHistory: mainSource + '/machinery/almec/history',

    AlmecSendConfiguration: mainSource + '/machinery/almec/sendConfiguration',

    //macchinari movimatica
    fetchMovimaticaVehicles: mainSource + '/machinery/movimatica/vehicles',
    fetchMovimaticaVehicleById: mainSource + '/machinery/movimatica/vehicles/:id',
    fetchMovimaticaVehiclePositions: mainSource + '/machinery/movimatica/vehicles/positions',
    fetchMovimaticaVehicleUpdate: mainSource + '/machinery/movimatica/vehicles/:id/update',
    fetchMovimaticaVehicleTrips: mainSource + '/machinery/movimatica/vehicles/:id/trips',
    fetchMovimaticaVehicleActiveAlarms: mainSource + '/machinery/movimatica/vehicles/:id/active-alarms',
    fetchMovimaticaVehicleTelemetry: mainSource + '/machinery/movimatica/vehicles/:id/telemetry',
    fetchMovimaticaVehicleTelemetryStats: mainSource + '/machinery/movimatica/vehicles/:id/telemetry/stats',
    fetchMovimaticaVehicleActivities: mainSource + '/machinery/movimatica/activities/:id',

    //post
    postMovimaticaVehicleActivity: mainSource + '/machinery/movimatica/send-activity-vehicle/:id',

    // delete
    deleteMovimaticaVehicleActivity: mainSource + '/machinery/movimatica/delete-activity-vehicle/:id',

    //macchinari trackVision 
    fetchTrackVisionMachineUtilizationReport: mainSource + '/machinery/trackvision/machine-utilization-report',
    fetchTrackVisionMachineData: mainSource + '/machinery/trackvision/machine-data',
    fetchTrackVisionAccessHistory: mainSource + '/machinery/trackvision/access-history',

    //macchinari waynet
    fetchWaynetVehicleList: mainSource + '/machinery/waynet/get-vehicle-list',
    fetchWaynetDriverList: mainSource + '/machinery/waynet/driver-list',
    fetchWaynetVehicleLastEvent: mainSource + '/machinery/waynet/get-vehicle-last-event',
    fetchWaynetVehicleHistoryEvents: mainSource + '/machinery/waynet/get-vehicle-history-events',

    waynetBroadcast: mainSource + '/waynet/broadcast-message',
    waynetSendMessage: mainSource + '/waynet/send-message',
    waynetSendCommand: mainSource + '/waynet/send-remote-command',

};