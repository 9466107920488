import { createSlice, Slice } from "@reduxjs/toolkit";
import { IDriver, ISupply, ITank, ITankRefill, IVehicle } from "./dieselwebType";

interface IDieselwebState {
    drivers: { "currentPage": number, "totalPages": number, "totalItems": number, "data": IDriver[] },
    vehicles: { "currentPage": number, "totalPages": number, "totalItems": number, "data": IVehicle[] },
    supplies: { "currentPage": number, "totalPages": number, "totalItems": number, "data": ISupply[] },
    tankRefills: { "currentPage": number, "totalPages": number, "totalItems": number, "data": ITankRefill[] },
    tanks: ITank[]
};

const initialState: IDieselwebState = {
    drivers: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    vehicles: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    supplies: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    tankRefills: { "currentPage": 0, "totalPages": 0, "totalItems": 0, "data": [] },
    tanks: []
};

export const DieselwebSlice: Slice<IDieselwebState> = createSlice({
    name: "dieselWeb",
    initialState,
    reducers: {
        replaceDrivers(state, action) {
            state.drivers = action.payload.drivers
        },
        replaceVehicles(state, action) {
            state.vehicles = action.payload.vehicles
        },
        replaceSupplies(state, action) {
            state.supplies = action.payload.supplies
        },
        replaceTankRefills(state, action) {
            state.tankRefills = action.payload.tankRefills
        },
        replaceTanks(state, action) {
            state.tanks = action.payload.tanks
        }
    }
});

export default DieselwebSlice.reducer;
export const { replaceDrivers, replaceVehicles, replaceSupplies, replaceTankRefills, replaceTanks } = DieselwebSlice.actions;