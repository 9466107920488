import { Button } from '@mui/material'
import React from 'react'
import { colorPalette } from '../utils/colorPalette'

interface IProps {
    color: colorPalette,
    label: string,
    title?: string,
    padding?: string,
    fullWidth?: boolean,
    disabled?: boolean,
    onClickHandler?: React.MouseEventHandler<HTMLButtonElement>
};


const CustomButton: React.FC<IProps> = ({ color, label, title, padding = 'pt-4 pb-4 pl-10 pr-10', fullWidth, onClickHandler, disabled }) => {

    return <div className="flex flex-col gap-1" style={(fullWidth) ? { width: '100%' } : null}>
        <p className="text-md font-medium">{title}</p>
        <Button onClick={onClickHandler} className={`${padding} rounded-md flex justify-center cursor-pointer ${(disabled) ? "pointer-events-none cursor-default" : null}`} style={{ backgroundColor: (disabled) ? colorPalette.MediumGray : color }}>
            <p className={`text-center text-white text-sm font-bold ${(!title) ? 'cursor-pointer' : 'cursor-default'}`}>{label}</p>
        </Button>
    </div>
}


export default CustomButton