import { Autocomplete, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CardContainer } from '../components/CardContainer';
import CustomButton from '../components/CustomButton';
import { ItemExpense } from '../redux-store/mod-analitics/reportType';
import { colorPalette } from '../utils/colorPalette';
import { searchItemTemplates } from '../api/mod-analitics/searchItemTemplate';

interface IProps {
    expenseState: ItemExpense[],
    setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>
}

const ExpenseAutoCompletePanel: React.FC<IProps> = ({ expenseState, setExpensesState }) => {

    const [expenseSelected, setExpenseSelected] = useState({
        name: '',
        price: 0,
        qta: 1,
    });

    const [options, setOptions] = useState<any[]>([]);
    const [nameQuery, setNameQuery] = useState<string>("");

    useEffect(() => {
        // run db query only when user ends typing
        if (nameQuery.length > 1) {
            const timer = setTimeout(() => {
                handleAutocompleteChange(nameQuery);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [nameQuery]);

    const handleAutocompleteChange = (value: string) => {
        if (value.length > 1) {
            searchItemTemplates(value)
                .then(data => {
                    const retrievedData = data.map((item: any, index: number) => { return { ...item, index: index + 1 } })

                    setOptions(retrievedData);
                })
        }
    }

    const handleInput = (value: string) => {
        setNameQuery(value);
        setExpenseSelected({ ...expenseSelected, name: value });
    }

    const handleClick = (element: any) => {
        const newExpenseCopy = { ...expenseSelected, price: element.price, name: element.name };
        setExpenseSelected(newExpenseCopy);
    }

    const renderOptionHandler = (props: any, option: any) => {
        return (
            <div {...props} key={option.index} className="">
                <div onClick={() => handleClick(option)} className="flex flex-row justify-between p-4 cursor-pointer hover:bg-gray-100">
                    <p className="font-medium">#{option.index} {option.name}</p>
                    <p className="font-medium">{option.price}€</p>
                </div>
            </div >
        );
    }

    const handleInputChange = (newValue: any, field: string) => {
        const newExpense = { ...expenseSelected, [field]: newValue }
        setExpenseSelected(newExpense)
    }


    const handleClickAutoComplete = () => {
        const currentState: ItemExpense[] = [];

        expenseState.forEach((item: any) => {
            currentState.push({ ...item, ...expenseSelected })
        })

        setExpensesState(currentState);
    }


    return (
        <CardContainer wFull={false} >
            <div className="flex flex-col gap-4">
                <Autocomplete
                    id="name-autocomplete"
                    options={options}
                    noOptionsText="Nessun risultato"
                    groupBy={(option) => option?.source?.sourceName}
                    getOptionLabel={(option) => option.name || expenseSelected.name}
                    sx={{ width: "100%" }}
                    clearOnBlur={false}
                    clearOnEscape={false}
                    clearText="Cancella"
                    freeSolo
                    includeInputInList
                    value={expenseSelected.name}


                    renderOption={(props, options) => renderOptionHandler(props, options)}
                    renderInput={(params) => <TextField
                        {...params}
                        onChange={(e) => handleInput(e.target.value)}
                        onBlur={(e) => handleInput(e.target.value)}
                        label="Nome Spesa"
                    />}
                />

                <TextField label="Prezzo/U" value={expenseSelected.price} onChange={(e) => handleInputChange(e.target.value, "price")} type='number' />
                <TextField label="Qta" value={expenseSelected.qta} onChange={(e) => handleInputChange(e.target.value, 'qta')} type='number' />

                <CustomButton label="AUTOCOMPLETA" color={colorPalette.Accent} onClickHandler={handleClickAutoComplete} />
            </div>
        </CardContainer>
    )
}

export default ExpenseAutoCompletePanel