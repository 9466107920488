import React, { useEffect } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import MonthlyMovement from "../templates/MonthlyMovement";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import { fetchMonthlyMovement } from "../redux-store/mod-mago/actions/fetchMonthlyMovement";
import AsyncLoader from "../components/AsyncLoader";
import { IMonthlyMovement } from "../redux-store/mod-mago/magoType";
import { sortArrayByDate } from "../utils/sortArrayByDate";


const MagoMonthlyMovement: React.FC = () => {

    const dispatch = useAppDispatch();
    const monthlyMovement = useAppSelector(state => state.mago.monthlyMovement);

    useEffect(() => {
        dispatch(fetchMonthlyMovement());
    }, [dispatch])

    return <GlobalPageTemplate title='Overview' >
        <AsyncLoader condition={monthlyMovement.length > 0} height={300}>
            {
                sortArrayByDate("ascending", "startDate", monthlyMovement)
                    .map((movement, index) => {
                        return <MonthlyMovement key={index} movement={movement} />
                    })
            }
        </AsyncLoader>
    </GlobalPageTemplate>
}

export default MagoMonthlyMovement;