import React, { useEffect, useState } from 'react';
import CustomButton from "../components/CustomButton";
import GlobalPageTemplate from "../templates/GlobalPageTemplate"
import { colorPalette } from "../utils/colorPalette";
import CustomInputForm from '../components/customInputForm';
import { useAppDispatch } from '../redux-store/store';
import { setSnackbar } from '../redux-store/uiManager/actions/setSnackbar';
import { IOrder } from '../redux-store/mod-analitics/reportType';
import { fetchOrders } from '../redux-store/mod-analitics/actions/fetchOrders';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CardContainer } from '../components/CardContainer';
import { getOrderById } from '../redux-store/mod-analitics/actions/getOrderById';
import { createOrder } from '../api/mod-analitics/createOrder';
import { updateOrder } from '../api/mod-analitics/updateOrder';

interface IProps {
    update?: boolean;
}

const ManageOrder: React.FunctionComponent<IProps> = ({ update = false }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [orderId, setOrderId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('')

    const [name, setName] = useState('');
    const [RDC, setRDC] = useState('');
    const [description, setDescription] = useState('');
    const [note, setNote] = useState('');
    const [customer, setCustomer] = useState('');
    const [credit, setCredit] = useState(0);
    const [status, setStatus] = useState('In corso');
    const [profit, setProfit] = useState(0);
    const [expense, setExpense] = useState(0);
    const [dateCreated, setDateCreated] = useState(new Date());

    useEffect(() => {
        //retrieve item data if update is true and id is present
        if (update && searchParams.get('id')) {
            setLoading(true);
            const id = searchParams.get('id');
            setOrderId(id);
            // fetch itemtemplate data by id
            fetchDataOrderTemplate(id);
        }
    }, []);

    const fetchDataOrderTemplate = async (id: string): Promise<IOrder> => {
        const response = await getOrderById(id);
        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante il recupero della commessa", "error"));
            setLoading(false);
            return;
        }

        setName(response.name);
        setRDC(response.rdcCode);
        setDescription(response.description);
        setNote(response.notes);
        setCustomer(response.client);
        setProfit(response.profit);
        setExpense(response.expense);
        setCredit(response.credit);
        setStatus(response.status);
        setDateCreated(response.date);
        setLoading(false);
    }

    const handleSubmit = () => {
        if (name.length <= 0) {
            setErrorMessage('Inserire un nome per la commessa!');
            return;
        };
        if (credit <= 0) {
            setErrorMessage('Inserire valore credito positivo!');
            return;
        };

        const newOrder: IOrder = {
            name: name,
            description: description,
            date: dateCreated,
            credit: credit,
            expense: expense,
            profit: profit,
            status: status,
            client: customer.length > 0 ? customer : "Non specificato",
            rdcCode: RDC.length > 0 ? RDC : "Non specificato",
            notes: note,
        };

        if (update) {
            updateOrderHandler(newOrder);
            return;
        }

        createOrderHandler(newOrder);
    };


    const createOrderHandler = async (order: IOrder) => {
        dispatch(setSnackbar("Creazione nuova commessa in corso...", "info"));
        const response = await createOrder(order);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante la creazione della commessa", "error"));
            return;
        }

        await dispatch(fetchOrders());
        dispatch(setSnackbar("Commessa creata con successo!", "success"));
        navigate(-1)
    };

    const updateOrderHandler = async (order: IOrder) => {
        dispatch(setSnackbar("Aggiornamento commessa in corso...", "info"));
        const response = await updateOrder(order, orderId);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante l'aggiornamento della commessa", "error"));
            return;
        }

        await dispatch(fetchOrders());
        dispatch(setSnackbar("Commessa aggiornata con successo!", "success"));
        navigate(-1)
    };

    return <GlobalPageTemplate title={update ? "Modifica Commessa" : "Creazione Commessa"}>
        <>
            <CardContainer title={update ? "Modifica dati commessa" : "Inserimento Dati Nuova Commessa"}>
                <div className="flex flex-col gap-4">
                    <CustomInputForm label='Nome *' value={name} setValue={setName} />
                    <CustomInputForm label='Credito *' value={credit} setValue={setCredit} type="number" />
                    <CustomInputForm label='RDC' value={RDC} setValue={setRDC} />
                    <CustomInputForm label='Descrizione' value={description} setValue={setDescription} />
                    <CustomInputForm label='Note' value={note} setValue={setNote} />
                    <CustomInputForm label='Cliente' value={customer} setValue={setCustomer} />
                </div>
                <p className='text-error text-sm font-semibold text-center p-2'>{errorMessage}</p>
                <div className="w-full mt-4 flex flex-row justify-between items-center">
                    <p className='text-xs font-thin'>* Campi obbligatori</p>
                    <CustomButton disabled={loading} label={update ? "Modifica Commessa" : "Crea Commessa"} padding={"w-64 pr-10 pl-10 pb-2 pt-2"} color={colorPalette.Info} onClickHandler={handleSubmit} />
                </div>
            </CardContainer>
        </>
    </GlobalPageTemplate>
};


export default ManageOrder;