import moment from "moment";
import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData";
import { replaceFuelData } from "../reportSlice";
import { endpoints } from "../../../utils/endpoints";

export const RifTotType = {
    DAILY: 'daily',
    MONTHLY: 'monthly',
    YEARLY: 'yearly'
}

/**
 * Fetches fuel data from the server between the given start and end dates.
 * If no start or end date is provided, defaults to the start of the current month
 * and the current date, respectively.
 * 
 * @param {string} [startDate] The start date in YYYY-MM-DD format (default: start of current month)
 * @param {string} [endDate] The end date in YYYY-MM-DD format (default: current date)
 * 
 * @returns {Function} An async function that dispatches the fetched fuel data to the store
 */
export const fetchFuelData = (
    startDate: string = moment().startOf("month").format("YYYY-MM-DD"),
    endDate: string = moment().format("YYYY-MM-DD"),
    model: string = RifTotType.DAILY
) => {
    return async (dispatch: Dispatch) => {
        try {
            const fuelDatas = await fetchData(
                endpoints.fetchFuelDatas,
                {
                    startDate: startDate,
                    endDate: endDate,
                    model: model
                },
                "POST");
            const sortedData = fuelDatas.sort((a: any, b: any) => Date.parse(a.date) - Date.parse(b.date))
            dispatch(
                replaceFuelData({
                    fuelDatas: sortedData
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching orders: ", error)
        }
    }
};