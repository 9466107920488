import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { CardContainer } from "../../components/CardContainer";
import { IReport, ItemExpense } from '../../redux-store/mod-analitics/reportType';
import { colorPalette } from '../../utils/colorPalette';
import CustomButton from '../../components/CustomButton';
import Expense from '../../components/Expense';
import ExpenseAutoCompletePanel from '../ExpenseAutoCompletePanel';
import DieselWebAutoComplete from '../../components/DieselWebAutoComplete';
import TotalExpenseDisplay from '../../components/TotalExpenseDisplay';
import PopoverContainer from '../../components/PopoverContainer';
import moment from 'moment';
import 'moment/locale/it';

interface IProps {
    newReport: IReport;
    setNewReport?: Dispatch<SetStateAction<IReport>>;
};

export const AddExpenseReport: React.FC<IProps> = ({ newReport, setNewReport }) => {
    const navigate = useNavigate();

    const [fuelExpenses, setFuelExpenses] = useState<ItemExpense[]>([]);
    const [serviceExpenses, setServiceExpenses] = useState<ItemExpense[]>([]);
    const [workerExpenses, setWorkerExpenses] = useState<ItemExpense[]>([]);
    const [materialExpenses, setMaterialExpenses] = useState<ItemExpense[]>([]);

    const [anchorEl, setAnchorEl] = useState<SVGElement>(null);
    const [firstPopover, setFirstPopover] = useState<boolean>(false);
    const [secondPopover, setSecondPopover] = useState<boolean>(false);
    const [thirdPopover, setThirdPopover] = useState<boolean>(false);

    useEffect(() => {
        generateExpensesByDates();
    }, []);
    console.log(newReport)

    const generateExpensesByDates = () => {
        // Calculate the difference in milliseconds between the two dates
        const timeDiff: number = newReport.date.endDate.getTime() - newReport.date.startDate.getTime();

        // Calculate the number of days by dividing the time difference by the number of milliseconds in a day
        const days: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        console.log(days)
        const fuel = [];
        const service = [];
        const worker = [];

        const fuelExpense = {
            name: 'Gasolio',
            price: 0.00,
            qta: 0,
            date: moment(newReport.date.startDate).format('DD/MM/YYYY')
        };

        const newExpense = {
            name: 'Autostrada',
            price: 0,
            qta: 0,
            date: moment(newReport.date.startDate).format('DD/MM/YYYY')
        };

        const workerExpense = {
            name: 'Manodopera',
            price: 0,
            qta: 0,
            date: moment(newReport.date.startDate).format('DD/MM/YYYY')
        }

        for (let x = 0; x <= days; x++) {
            fuel.push({ ...fuelExpense, date: moment(newReport.date.startDate).add(x, 'days').format('DD/MM/YYYY') });
            service.push({ ...newExpense, date: moment(newReport.date.startDate).add(x, 'days').format('DD/MM/YYYY') });
            worker.push({ ...workerExpense, date: moment(newReport.date.startDate).add(x, 'days').format('DD/MM/YYYY') });
        }

        setFuelExpenses(fuel);
        setServiceExpenses(service);
        setWorkerExpenses(worker);
    }

    const addExpense = (expensesState: ItemExpense[], setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>) => {
        const item = {
            name: '',
            price: 0,
            qta: 1
        };
        const currentState = [...expensesState];
        currentState.push(item);
        setExpensesState(currentState)
    };

    const deleteExpense = (index: number, expensesState: ItemExpense[], setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>) => {
        const currentState = [...expensesState];
        currentState.splice(index, 1);
        setExpensesState(currentState);
    };

    const updateExpense = (index: number, field: string, value: string | number, expensesState: ItemExpense[], setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>) => {
        const currentState = [...expensesState];
        currentState[index] = { ...currentState[index], [field]: value };
        setExpensesState(currentState);
    };
    const populateReportSubmit = () => {
        const allExpenses = [
            {
                name: 'Carburante',
                expenses: fuelExpenses
            },
            {
                name: 'Manodopera',
                expenses: workerExpenses
            },
            {
                name: 'Servizi',
                expenses: serviceExpenses
            },
            {
                name: 'Materiali',
                expenses: materialExpenses
            }
        ]

        let totalExpenses: number = 0;

        fuelExpenses.map(item => totalExpenses += item.price * item.qta);
        workerExpenses.map(item => totalExpenses += item.price * item.qta);
        serviceExpenses.map(item => totalExpenses += item.price * item.qta);
        materialExpenses.map(item => totalExpenses += item.price * item.qta);

        const newReportWithExpenses = { ...newReport, expense: totalExpenses, itemExpenses: allExpenses };
        setNewReport(newReportWithExpenses);
    };


    const handleBack = () => {
        //get all expenses and set them in the newReport
        populateReportSubmit();
        navigate(-1);
    };

    const onSubmit = () => {
        //get all expenses and set them in the newReport
        populateReportSubmit();
        navigate('/monitoraggio/report/create?step=2')
    };

    const onOpenPopover = (event: React.MouseEvent<SVGElement>, number: number) => {
        setAnchorEl(event.currentTarget);
        switch (number) {
            case 1:
                setFirstPopover(true);
                break;
            case 2:
                setSecondPopover(true);
                break;
            case 3:
                setThirdPopover(true);
                break;
            default:
                break;
        }
    };

    return <>
        <div className='flex flex-row items-start gap-8'>
            <PopoverContainer anchorEl={anchorEl} isOpen={firstPopover} setIsOpen={setFirstPopover} title='AUTOCOMPLETE' content={<DieselWebAutoComplete dates={newReport.date} fuelExpenses={fuelExpenses} setFuelExpenses={setFuelExpenses} />} />
            <CardContainer title={"Spese Carburante"} headerElement={<FaIcons.FaSearch size={32} className='p-2 cursor-pointer' onClick={(event) => onOpenPopover(event, 1)} color={colorPalette.Primary} />}>
                {
                    (fuelExpenses.map((expense, index) => {
                        return <Expense index={index} itemExpense={expense} expensesState={fuelExpenses} setExpensesState={setFuelExpenses} onChangeUpdate={updateExpense} key={index} deleteHandler={deleteExpense} />
                    }))
                }
                <TotalExpenseDisplay expensesState={fuelExpenses} />
            </CardContainer>
        </div>

        <div className='flex flex-row items-start gap-8'>
            <PopoverContainer anchorEl={anchorEl} isOpen={secondPopover} setIsOpen={setSecondPopover} title='AUTOCOMPLETE' content={<ExpenseAutoCompletePanel expenseState={workerExpenses} setExpensesState={setWorkerExpenses} />} />
            <CardContainer title={"Spese Manodopera"} headerElement={<FaIcons.FaSearch size={32} className='p-2 cursor-pointer' onClick={(event) => onOpenPopover(event, 2)} color={colorPalette.Primary} />}>
                {
                    (workerExpenses.map((expense, index) => {
                        return <Expense index={index} itemExpense={expense} expensesState={workerExpenses} setExpensesState={setWorkerExpenses} onChangeUpdate={updateExpense} key={index} deleteHandler={deleteExpense} />
                    }))
                }
                <TotalExpenseDisplay expensesState={workerExpenses} />
            </CardContainer>
        </div>

        <div className='flex flex-row items-start gap-8'>
            <PopoverContainer anchorEl={anchorEl} isOpen={thirdPopover} setIsOpen={setThirdPopover} title='AUTOCOMPLETE' content={<ExpenseAutoCompletePanel expenseState={serviceExpenses} setExpensesState={setServiceExpenses} />} />
            <CardContainer title={"Spese Servizi"} headerElement={<FaIcons.FaSearch size={32} className='p-2 cursor-pointer' onClick={(event) => onOpenPopover(event, 3)} color={colorPalette.Primary} />}>
                {
                    (serviceExpenses.map((expense, index) => {
                        return <Expense index={index} itemExpense={expense} expensesState={serviceExpenses} setExpensesState={setServiceExpenses} onChangeUpdate={updateExpense} key={index} deleteHandler={deleteExpense} />
                    }))
                }
                <TotalExpenseDisplay expensesState={serviceExpenses} />
            </CardContainer>
        </div>

        <CardContainer title={"Spese Materiali/Varie"}>
            {
                (materialExpenses.map((expense, index) => {
                    return <Expense index={index} itemExpense={expense} expensesState={materialExpenses} setExpensesState={setMaterialExpenses} onChangeUpdate={updateExpense} key={index} deleteHandler={deleteExpense} />
                }))
            }
            <TotalExpenseDisplay expensesState={materialExpenses} />
            <CustomButton onClickHandler={() => addExpense(materialExpenses, setMaterialExpenses)} label='Aggiungi Spesa' padding={"w-54 pr-10 pl-10 pb-2 pt-2 self-end"} color={colorPalette.Accent} />
        </CardContainer>

        <div className='flex justify-between mt-8' >
            <CustomButton
                color={colorPalette.Warning}
                label='Back'
                onClickHandler={handleBack} />
            <CustomButton
                color={colorPalette.Accent}
                label={'Next'}
                onClickHandler={onSubmit} />
        </div>
    </>
}