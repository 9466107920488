import React from "react";
import Scrollbars from "react-custom-scrollbars";
import SnackBar from "../utils/snackBar";

interface IProps {
   title: string,
   headerElement?: JSX.Element,
   children?: JSX.Element
}

const GlobalPageTemplate: React.FunctionComponent<IProps> = ({ title, headerElement, children }) => {
   return <Scrollbars>
      <div className="w-full p-8 justify-center">
         <div className="flex justify-between w-full">
            <h1 className="font-bold text-3xl pb-8 text-text" >{title}</h1>
            {headerElement}
         </div>
         {children}
         <SnackBar />
      </div>
   </Scrollbars>
}

export default GlobalPageTemplate;