import { endpoints } from "../../utils/endpoints";
import { fetchData } from "../../utils/fetchData";

export const searchGasPrice = async (page: string) => {
    try {
        const items = await fetchData(endpoints.searchGasPrice + `?page=${page}&limit=1&sortField=DataOra`);

        return items.data.data[0];
    } catch (error: any) {
        console.log("ERROR fetching gas price: ", error)
    }
}