import React from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { DataTable } from "../components/DataTable";
import { almec_machinery_list, movimatica_machinery_list, tenec_machinery_list, trackvision_machinery_list, waynet_machinery_list } from "../data/machinery_list";

const HEADER_FIELDS = ["#", "Nome Macchinario", "Tipo Macchinario", "Data Creazione"];
const fieldsToPrint = ["id", "name", "type", "created_at"];

const MacchinariView: React.FunctionComponent = () => {
    return <GlobalPageTemplate title='Industria 4.0' >
        <>

            <p className="text-xl">Macchinari TENEC</p>
            <DataTable
                headerFields={HEADER_FIELDS}
                fieldsToPrint={fieldsToPrint}
                data={tenec_machinery_list}
                defaultSortField={"id"}
                defaultSortDirection={"asc"}
                showDetailsButton={true}
                redirectPath="/monitoraggio/macchinari/tenec/details?id="
            />


            <p className="text-xl mt-16">Macchinari ALMEC</p>
            <DataTable
                headerFields={HEADER_FIELDS}
                fieldsToPrint={fieldsToPrint}
                data={almec_machinery_list}
                defaultSortField={"id"}
                defaultSortDirection={"asc"}
                showDetailsButton={true}
                redirectPath="/monitoraggio/macchinari/almec/details?id="
            />

            <p className="text-xl mb-2 mt-16">Macchinari MOVIMATICA</p>
            <DataTable
                headerFields={HEADER_FIELDS}
                fieldsToPrint={fieldsToPrint}
                data={movimatica_machinery_list}
                defaultSortField={"id"}
                defaultSortDirection={"asc"}
                showDetailsButton={true}
                redirectPath="/monitoraggio/macchinari/movimatica/details?id="
            />

            <p className="text-xl mt-16">Macchinari TrackVision</p>
            <DataTable
                headerFields={HEADER_FIELDS}
                fieldsToPrint={fieldsToPrint}
                data={trackvision_machinery_list}
                defaultSortField={"id"}
                defaultSortDirection={"asc"}
                showDetailsButton={true}
                redirectPath="/monitoraggio/macchinari/trackvision/details?id="
            />

            <p className="text-xl mt-16">Macchinari WAYNET</p>
            <DataTable
                headerFields={HEADER_FIELDS}
                fieldsToPrint={fieldsToPrint}
                data={waynet_machinery_list}
                defaultSortField={"id"}
                defaultSortDirection={"asc"}
                showDetailsButton={true}
                redirectPath="/monitoraggio/macchinari/waynet/details?id="
            />

        </>
    </GlobalPageTemplate>
}


export default MacchinariView;