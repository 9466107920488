import { createSlice, Slice } from "@reduxjs/toolkit";
import { ISnackBar } from "./uiType";

interface UIMangerState {
    snackbar: ISnackBar;
}

const initialUIManagerState: UIMangerState = {
    snackbar: {
        isOpen: false,
        message: "",
        severity: "info"
    }
};

const uiManagerSlice: Slice<UIMangerState> = createSlice({
    name: "uiManager",
    initialState: initialUIManagerState,
    reducers: {
        openSnackbar(state, action) {
            state.snackbar.isOpen = true;
            state.snackbar.message = action.payload.message;
            state.snackbar.severity = action.payload.severity;
        },
        closeSnackbar(state, action) {
            state.snackbar.isOpen = action.payload.isOpen;
        }
    },
});

export const uiManagerActions = uiManagerSlice.actions;

export default uiManagerSlice;
