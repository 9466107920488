import { fetchData } from "../../utils/fetchData";
import { endpoints } from "../../utils/endpoints";
import { IUser } from "../../redux-store/auth/authType";

export const registerService = async (user: IUser) => {
    try {
        const auth = await fetchData(endpoints.registerRoute, user, 'POST');

        return auth;

    } catch (error: any) {
        console.log("ERROR register: ", error)
    }
};
