import React, { useEffect, useState } from 'react';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import { fetchVehicles } from '../redux-store/mod-dieselweb/actions/fetchVehicles';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import AsyncLoader from '../components/AsyncLoader';
import { DataTable } from '../components/DataTable';
import { useSearchParams } from 'react-router-dom';
import CustomInputForm from '../components/customInputForm';

const HEADER_FIELDS = ["#", "Id", "Nome", "Targa", "Transponder", "Litri Max", "Data Creazione", "Stato"];
const fieldsToPrint = ["Code", "Id", "Desc", "Targa", "Trans", "LitriMax", "Crea", "Abilita"];

const DieselwebVehicles: React.FC = () => {

    const [searchString, setSearchString] = useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();

    // REDUX 
    const dispatch = useAppDispatch();
    const vehicles = useAppSelector(state => state.dieselweb.vehicles);

    useEffect(() => {
        setSearchParams(`limit=15&page=1`);
    }, []);

    useEffect(() => {
        if (searchParams.get("limit") !== null)
            dispatch(fetchVehicles(`?${searchParams.toString()}`));
    }, [searchParams]);

    useEffect(() => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=1&filter_field=Targa&field_value=${searchString.toUpperCase()}&isSearch=true`);
        }
        if (searchString?.length === 0) {
            setSearchParams("?limit=15&page=1");
        }
    }, [searchString]);

    const fetchNextPage = () => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=${vehicles.currentPage + 1}&filter_field=Targa&field_value=${searchString.toUpperCase()}&isSearch=true`);
        } else {
            setSearchParams(`?limit=15&page=${vehicles.currentPage + 1}`);
        }
    };

    const fetchBackPage = () => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=${vehicles.currentPage - 1}&filter_field=Targa&field_value=${searchString.toUpperCase()}&isSearch=true`);
        } else {
            setSearchParams(`?limit=15&page=${vehicles.currentPage - 1}`);
        }
    };

    return (
        <GlobalPageTemplate title='Veicoli' >
            <>
                <CustomInputForm value={searchString} setValue={setSearchString} label='Filtra per targa' style='mb-4' />
                <AsyncLoader height={500} condition={vehicles.data.length !== 0}>
                    <DataTable
                        data={vehicles.data}
                        defaultSortField={"Code"}
                        headerFields={HEADER_FIELDS}
                        fieldsToPrint={fieldsToPrint}
                        size='small'
                        showDetailsButton={false}
                        dataCurrentPage={vehicles.currentPage - 1}
                        dataCount={vehicles.totalItems}
                        rowsPage={15}
                        rowsOptions={[]}
                        nextIconButtonHandler={fetchNextPage}
                        backIconButtonHandler={fetchBackPage}
                    />
                </AsyncLoader>
            </>

        </GlobalPageTemplate>
    )
}

export default DieselwebVehicles