import React from 'react'
import { Billing } from '../enums/enumBilling'
import EastIcon from '@mui/icons-material/East';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { colorPalette } from '../utils/colorPalette';

interface IProps {
    billingType: Billing,
    billingValue: number
}

const BillingBox: React.FunctionComponent<IProps> = ({ billingType, billingValue }) => {

    const renderIcon = () => {
        switch (billingType) {
            case Billing.CREDIT:
                return <EastIcon style={{ color: colorPalette.Info, marginRight: 10 }} />
            case Billing.PROFIT:
                return <NorthEastIcon style={{ color: colorPalette.Success, marginRight: 10 }} />
            case Billing.EXPENSE:
                return <SouthEastIcon style={{ color: colorPalette.Error, marginRight: 10 }} />
            case Billing.DISCOUNT:
                return <EastIcon style={{ color: colorPalette.Info, marginRight: 10 }} />
            default:
                return null
        }
    }

    const renderTitle = () => {
        switch (billingType) {
            case Billing.CREDIT:
                return 'Credito'
            case Billing.PROFIT:
                return 'Profitto'
            case Billing.EXPENSE:
                return 'Costi riscontrati'
            case Billing.DISCOUNT:
                return 'Sconto'
            default:
                return null
        }
    }


    return (
        <div className='p-4 w-full rounded-xl border-2 border-gray-200 shadow-sm'>
            <span className='font-medium'>{renderTitle()}</span><br />
            <div className='flex'>
                {renderIcon()}
                {billingValue?.toFixed(2)} €
            </div>
        </div>
    )
}

export default BillingBox

