import { createData } from "../../utils/createData";
import { endpoints } from "../../utils/endpoints"
import { ItemTemplate } from "../../redux-store/mod-analitics/reportType";

export const createItemTemplate = async (newData: ItemTemplate) => {
    try {
        const data = await createData(endpoints.source + endpoints.createItemTemplate, newData);

        if (data instanceof Error || typeof data === "undefined") {
            throw new Error(data.message);
        }

        return data;
    } catch (error: any) {
        console.log("ERROR: ", error)
        return new Error(error);
    }
};