import * as FaIcons from 'react-icons/fa';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';


import { colorPalette } from '../utils/colorPalette';

export const SidebarDataAdmin = [
    // {
    //     title: 'Home',
    //     path: '/',
    // },
    {
        title: 'Monitoraggio',
        subNav: [
            {
                title: "Commesse",
                path: "/monitoraggio/commesse",
                icon: <TbIcons.TbChartBar size={18} color={colorPalette.Text} />,
            },
            {
                title: "Report",
                path: "/monitoraggio/report",
                icon: <TbIcons.TbReportAnalytics size={18} color={colorPalette.Text} />,
            },
            {
                title: "Oggetti",
                path: "/monitoraggio/oggetti",
                icon: <TbIcons.TbBoxSeam size={18} color={colorPalette.Text} />,
            },
            {
                title: "Carburante",
                path: "/monitoraggio/carburante",
                icon: <TbIcons.TbGasStation size={18} color={colorPalette.Text} />,
            },
            {
                title: "Archivio",
                path: "/monitoraggio/archivio",
                icon: <TbIcons.TbArchive size={18} color={colorPalette.Text} />,
            },
            {
                title: "Macchinari 4.0",
                path: "/monitoraggio/macchinari",
                icon: <TbIcons.TbManualGearbox size={18} color={colorPalette.Text} />,
            }
        ]
    },
    {
        title: 'Mago',
        subNav: [
            {
                title: "Overview",
                path: "/mago/movement",
                icon: <TbIcons.TbReportMoney size={18} color={colorPalette.Text} />
            }
        ]
    },
    {
        title: 'DieselWeb',
        subNav: [
            {
                title: "Veicoli",
                path: "/dieselweb/veicoli",
                icon: <MdIcons.MdDirectionsCar size={18} color={colorPalette.Text} />
            },
            {
                title: "Autisti",
                path: "/dieselweb/autisti",
                icon: <MdIcons.MdEmojiPeople size={18} color={colorPalette.Text} />
            },
            {
                title: "Rifornimenti",
                path: "/dieselweb/rifornimenti",
                icon: <GiIcons.GiSteeringWheel size={18} color={colorPalette.Text} />
            },
            {
                title: "Carichi Cisterne",
                path: "/dieselweb/cisterne",
                icon: <GiIcons.GiWaterTank size={18} color={colorPalette.Text} />
            }
        ]

    }
]
export const SidebarDataUser = [
    {
        title: 'Monitoraggio',
        subNav: [
            {
                title: "Report",
                path: "/monitoraggio/report",
                icon: <TbIcons.TbReportAnalytics size={18} color={colorPalette.Text} />,
            }
        ]
    },
]