import { Dispatch } from "redux"
import { AlertColor } from "../uiType"
import { uiManagerActions } from "../UI-Manager-slice"

export const closeSnackbar = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(
                uiManagerActions.closeSnackbar({
                    isOpen: false
                })
            )
        } catch (error: any) {
            console.log("ERROR: ", error)
        }
    }
}