import React from 'react';
import { IReport } from '../redux-store/mod-analitics/reportType';

interface IProps {
    newReport: IReport;
    addStyle?: string;
};

const ReportTable: React.FC<IProps> = ({ newReport, addStyle }) => {

    let itemExpenseTotal: number = 0;

    return (
        <div className={`rounded-xl overflow-hidden border border-primary ${addStyle}`}>
            <table className="w-full table-auto ">
                <thead>
                    <tr className="bg-primary text-background">
                        <th className="py-2 px-4 text-left">Nome</th>
                        <th className="py-2 px-4 text-left">Prezzo</th>
                        <th className="py-2 px-4 text-left">Quantità</th>
                        <th className="py-2 px-4 text-left">Data</th>
                    </tr>
                </thead>
                <tbody className="bg-lightGray">
                    {newReport?.itemExpenses?.map((itemExpense, i) => {
                        let total = 0;
                        return <React.Fragment key={i}>
                            <tr className="bg-mediumGray text-primary">
                                <td colSpan={4} className="py-2 px-4">
                                    {itemExpense.name}
                                </td>
                            </tr>
                            {itemExpense?.expenses?.map((expense, j) => {
                                total += expense.price * expense.qta;
                                itemExpenseTotal += expense.price * expense.qta;

                                // check if expense.price is a number and not a string
                                if (typeof expense.price !== 'number') {
                                    // convert string to number
                                    expense.price = parseFloat(expense.price);
                                }

                                return <tr key={j} className="border-b border-primary text-text">
                                    <td className="py-2 px-4">{expense.name}</td>
                                    <td className="py-2 px-4">€ {expense?.price?.toFixed(2)}</td>
                                    <td className="py-2 px-4">{expense.qta}</td>
                                    <td className="py-2 px-4">{expense.date}</td>
                                </tr>

                            })}
                            <tr className=" text-text">
                                <td colSpan={4} className="py-2 px-4 text-right">
                                    Totale {itemExpense.name}: € {total?.toFixed(2)}
                                </td>
                            </tr>
                        </React.Fragment>
                    })}
                    <tr className="bg-mediumGray text-primary">
                        <td colSpan={4} className="py-2 px-4">
                            Totale Spese € {itemExpenseTotal?.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ReportTable