import React from "react";
import { Routes, Route } from "react-router-dom";
import MagoMovementDetails from "../views/MagoMovementDetails";
// import HomeView from "../views/HomeView";
import MagoMonthlyMovement from "../views/MagoMonthlyMovement";
import OrdersView from "../views/OrdersView";
import ManageReport from "../views/ManageReport";
import ManageOrder from "../views/ManageOrder";
import ItemsView from "../views/ItemsView";
import ManageItem from "../views/ManageItem";
// import AlertView from "../views/AlertView";
import ReportsView from "../views/ReportsView";
import FuelView from "../views/FuelView";
import ReportDetails from "../views/ReportDetails";
import OrderDetails from "../views/OrderDetails";
import DieselwebVehicles from "../views/DieselwebVehicles";
import DieselwebDrivers from "../views/DieselwebDrivers";
import DieselwebSupplies from "../views/DieselwebSupplies";
import DieselwebTankRefills from "../views/DieselwebTankRefills";
import Account from "../views/Account";
import AdminRoute from "./AdminRoute";
import Archive from "../views/Archive";
import MacchinariView from "../views/MacchinariView";
import MacchinariTenecDetails from "../views/MacchinariTenecDetails";
import MacchinariAlmecDetails from "../views/MacchinariAlmecDetails";
import MacchinariMovimaticaDetails from "../views/MacchinariMovimaticaDetails";
import MacchinariTrackVisionDetails from "../views/MacchinariTrackVisionDetails";
import MacchinariWaynetDetails from "../views/MacchinariWaynetDetails";

const RoutesPage: React.FC = () => {

    return (
        <Routes >

            <Route path="/" element={<AdminRoute><OrdersView /></AdminRoute>} />

            {/* <Route path="/notifiche" element={<AlertView />} /> */}
            <Route path="/monitoraggio/commesse" element={<AdminRoute><OrdersView /></AdminRoute>} />
            <Route path="/monitoraggio/commesse/details" element={<AdminRoute><OrderDetails /></AdminRoute>} />
            <Route path="/monitoraggio/commesse/create" element={<AdminRoute><ManageOrder /></AdminRoute>} />
            <Route path="/monitoraggio/commesse/update/" element={<AdminRoute><ManageOrder update={true} /></AdminRoute>} />
            <Route path="/monitoraggio/report" element={<ReportsView />} />
            <Route path="/monitoraggio/report/details" element={<ReportDetails />} />
            <Route path="/monitoraggio/report/create" element={<ManageReport />} />
            <Route path="/monitoraggio/archivio" element={<AdminRoute><Archive /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari" element={<AdminRoute><MacchinariView /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari/tenec/details" element={<AdminRoute><MacchinariTenecDetails /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari/almec/details" element={<AdminRoute><MacchinariAlmecDetails /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari/movimatica/details" element={<AdminRoute><MacchinariMovimaticaDetails /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari/trackvision/details" element={<AdminRoute><MacchinariTrackVisionDetails /></AdminRoute>} />
            <Route path="/monitoraggio/macchinari/waynet/details" element={<AdminRoute><MacchinariWaynetDetails /></AdminRoute>} />

            <Route path="/monitoraggio/oggetti" element={<AdminRoute><ItemsView /></AdminRoute>} />
            <Route path="/monitoraggio/oggetti/create" element={<AdminRoute><ManageItem /></AdminRoute>} />
            <Route path="/monitoraggio/oggetti/update/" element={<AdminRoute><ManageItem update={true} /></AdminRoute>} />

            <Route path="/monitoraggio/carburante" element={<AdminRoute><FuelView /></AdminRoute>} />


            <Route path="/mago/movement" element={<AdminRoute><MagoMonthlyMovement /></AdminRoute>} />
            <Route path="/mago/movement/details" element={<AdminRoute><MagoMovementDetails /></AdminRoute>} />

            <Route path='/dieselweb/veicoli' element={<AdminRoute><DieselwebVehicles /></AdminRoute>} />
            <Route path='/dieselweb/autisti' element={<AdminRoute><DieselwebDrivers /></AdminRoute>} />
            <Route path='/dieselweb/rifornimenti' element={<AdminRoute><DieselwebSupplies /></AdminRoute>} />
            <Route path='/dieselweb/cisterne' element={<AdminRoute><DieselwebTankRefills /></AdminRoute>} />

            <Route path="/account" element={<AdminRoute><Account /></AdminRoute>} />

        </Routes>
    )
}

export default RoutesPage;