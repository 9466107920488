import React from 'react'
import { Marker } from '@react-google-maps/api';

interface IProps {
    latitude: number;
    longitude: number;
}

const AssetsMarker: React.FunctionComponent<IProps> = ({ latitude, longitude }) => {
    return <Marker position={{ lat: latitude, lng: longitude }} icon={{ url: ('/images/MarkerIcon.svg') }} />

}

export default AssetsMarker