import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { endpoints } from "../../../utils/endpoints"
import { replaceSupplies } from "../dieselwebSlice";
import { ISupply } from "../dieselwebType";

export const fetchSupplies = (searchParams: string = '') => {
    return async (dispatch: Dispatch) => {
        try {
            const supplies = await fetchData(endpoints.fetchSupplies + searchParams);
            supplies.data.data.map((supply: ISupply) => {
                supply.Cll /= 100
            });

            dispatch(
                replaceSupplies({
                    supplies: supplies.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching supplies: ", error)
        }
    }
}