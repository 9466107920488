import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardContainer } from "../components/CardContainer";
import CustomButton from "../components/CustomButton";
import CustomInputForm from "../components/customInputForm";
import { createItemTemplate } from "../api/mod-analitics/createItemTemplate";
import { fetchCustomItem } from "../redux-store/mod-analitics/actions/fetchCustomItem";
import { getItemTemplateById } from "../redux-store/mod-analitics/actions/getItemTemplateById";
import { ItemTemplate } from "../redux-store/mod-analitics/reportType";
import { useAppDispatch } from "../redux-store/store";
import { setSnackbar } from "../redux-store/uiManager/actions/setSnackbar";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { colorPalette } from "../utils/colorPalette";
import { updateItemTemplate } from "../api/mod-analitics/updateItemTemplate";

interface IProps {
    update?: boolean;
}

const ManageItem: React.FunctionComponent<IProps> = ({ update = false }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [itemId, setItemId] = useState<string>('');

    const [name, setName] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [price, setPrice] = useState<string>("0");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        //retrieve item data if update is true and id is present
        if (update && searchParams.get('id')) {
            setLoading(true);
            const id = searchParams.get('id');
            setItemId(id);
            // fetch itemtemplate data by id
            fetchDataItemTemplate(id);
        }
    }, [])

    const fetchDataItemTemplate = async (id: string): Promise<ItemTemplate> => {
        const response = await getItemTemplateById(id);
        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante il recupero dell'oggetto", "error"));
            setLoading(false);
            return;
        }

        setName(response.name);
        setCategory(response.category);
        setPrice(response.price.toString());
        setLoading(false);
    }


    const handleSubmit = () => {
        if (name === '' || category === '') {
            dispatch(setSnackbar("Completa tutti i campi", "error"));
            return;
        }

        const itemTemplate = {
            name: name,
            category: category,
            price: Number(price),
            sourceName: 'CUSTOM',
            sourceId: 'CUSTOM'
        }

        if (update) {
            updateItemTemplateHandler(itemTemplate);
            return;
        }

        createItemTemplateHandler(itemTemplate);
    }

    const createItemTemplateHandler = async (itemTemplate: ItemTemplate) => {
        dispatch(setSnackbar("Creazione nuovo oggetto in corso...", "info"));
        const response = await createItemTemplate(itemTemplate);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante la creazione dell'oggetto", "error"));
            return;
        }

        await dispatch(fetchCustomItem(15, 1));
        dispatch(setSnackbar("Oggetto creato con successo!", "success"));
        navigate(-1)
    };

    const updateItemTemplateHandler = async (itemTemplate: ItemTemplate) => {
        dispatch(setSnackbar("Aggiornamento oggetto in corso...", "info"));
        const response = await updateItemTemplate(itemTemplate, itemId);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante l'aggiornamento dell'oggetto", "error"));
            return;
        }

        await dispatch(fetchCustomItem(15, 1));
        dispatch(setSnackbar("Oggetto aggiornato con successo!", "success"));
        navigate(-1)
    };

    return <GlobalPageTemplate title={update ? "Aggiornamento Oggetto" : "Inserimento Nuovo Oggetto"}>
        <>
            <CardContainer title={update ? "Inserimento Dati Aggiornamento Oggetto" : "Inserimento Dati Nuovo Oggetto"}>
                <div className="flex flex-col gap-4">
                    {
                        loading
                            ? <div className="w-full h-20 flex justify-center items-center">
                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-400 h-10 w-10"></div>
                            </div>
                            : <>
                                <CustomInputForm label='Nome' value={name} setValue={setName} />
                                <CustomInputForm label='Categoria' value={category} setValue={setCategory} />
                                <CustomInputForm label='Prezzo' value={price} setValue={setPrice} type="number" />
                            </>
                    }
                </div>
                <div className="w-full mt-4 flex flex-row justify-end">
                    <CustomButton label={
                        update ? "Aggiorna" : "Crea"
                    } padding={"w-64 pr-10 pl-10 pb-2 pt-2"} color={colorPalette.Info} onClickHandler={handleSubmit} />
                </div>
            </CardContainer>
        </>
    </GlobalPageTemplate>
}

export default ManageItem;