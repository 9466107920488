import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { fetchRifsByDates } from "../redux-store/mod-analitics/actions/fetchRifsByDates";
import { ItemExpense } from "../redux-store/mod-analitics/reportType";
import { useAppDispatch } from "../redux-store/store";
import { setSnackbar } from "../redux-store/uiManager/actions/setSnackbar";
import { colorPalette } from "../utils/colorPalette";
import CustomButton from "./CustomButton";
import { searchGasPrice } from "../api/mod-analitics/searchGasPrice";
import { ITankRefill } from "../redux-store/mod-dieselweb/dieselwebType";

interface IProps {
    fuelExpenses: ItemExpense[],
    setFuelExpenses: Dispatch<SetStateAction<ItemExpense[]>>,
    dates: {
        startDate: Date;
        endDate: Date;
    }
}

const DieselWebAutoComplete: React.FC<IProps> = ({ fuelExpenses, setFuelExpenses, dates }) => {
    const dispatch = useAppDispatch();
    let counter = 0;
    const [viaggioCodes, setViaggioCodes] = useState([
        {
            index: 1,
            viaggioCode: '',
            name: ''
        }
    ])
    const [focused, setFocused] = useState<number>(0)
    const [options, setOptions] = useState<any[]>([]);

    /** Reset the fuel expenses before add new autocomplete data from dieselweb. */
    const resetFuelExpenses = () => {
        fuelExpenses.map(expense => {
            expense.price = 0
            expense.qta = 0
        })
    }

    /** Retrieve data from DieselWeb and update the fuel expenses. */
    const retrieveDataFromDieselWebAndFill = async () => {
        if (viaggioCodes.length === 0) return;

        //reset fuel expenses
        resetFuelExpenses()

        // Clone the current fuel expenses array.
        const currentExpenses = [...fuelExpenses];
        // Store the totals of rifs by date.
        const totalsByDate: { [date: string]: number } = {};
        // The output array with date and total.
        const output: { date: string; tot: number }[] = [];

        // Calculate totals of rifs by date
        options.forEach(obj => {
            const date = moment(obj.Ora).format('DD/MM/YYYY');

            if (!totalsByDate[date]) {
                totalsByDate[date] = 0;
            }
            totalsByDate[date] += obj.Cll;
        });

        Object.keys(totalsByDate).forEach(date => {
            output.push({ date, tot: totalsByDate[date] });
        });

        if (output.length === 0) {
            dispatch(setSnackbar("Nessun risultato trovato", "warning"));
            return
        }

        try {
            const gasPrice = await fetchGasPrice();

            // Update the current qta and gas price of currentExpenses
            output.forEach(el => {
                // Find the index of the date in currentExpenses
                const indexInCurrentExpenses = currentExpenses.findIndex(el2 => el2.date === el.date);
                if (indexInCurrentExpenses !== -1) {
                    currentExpenses[indexInCurrentExpenses].qta = el.tot;
                    currentExpenses[indexInCurrentExpenses].price = gasPrice.FisPrezzo;
                }
            });

            setFuelExpenses(currentExpenses);
            dispatch(setSnackbar("Dati recuperati da DieselWeb", "success"));
        } catch (error) {
            dispatch(setSnackbar("Errore nel recupero dei dati", "error"));
        }
    };

    /** Fetch the gas price from the API. */
    const fetchGasPrice = async (): Promise<ITankRefill> => {
        counter++;
        let response = await searchGasPrice(counter.toString());

        // Check if FisPrezzo is empty and rerun searchGasPrice
        if (response.FisPrezzo === 0) {
            response = await fetchGasPrice();
        }
        return response;
    };

    const addViaggioCode = () => {
        const newViaggioCodes = [...viaggioCodes]
        newViaggioCodes.push({
            index: newViaggioCodes.length + 1,
            viaggioCode: "",
            name: ""
        })
        setViaggioCodes(newViaggioCodes)
    }

    const removeViaggioCode = (index: number) => {
        const newAutCodes = [...viaggioCodes]
        newAutCodes.splice(index, 1)
        setViaggioCodes(newAutCodes)
    }

    useEffect(() => {
        // run db query only when user ends typing
        const timer = setTimeout(() => {
            if (viaggioCodes[focused]?.viaggioCode.length > 0) {
                handleAutocompleteChange(viaggioCodes[focused].viaggioCode)
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [viaggioCodes]);

    const handleAutocompleteChange = async (value: string) => {
        const retrievedRifs = await fetchRifsByDates(dates);
        const foundRifs = retrievedRifs.filter((item: any) => {
            return item.Via.toString() === value
        })
        setOptions(foundRifs);
    }

    const renderOptionHandler = (props: any, option: any) => {
        return (
            <div {...props} key={option.Num} className="flex flex-row justify-between p-4">
                <p className="font-medium">#{option.Num} {option.Aux}</p>
                <p className="font-medium">{option.Via}</p>
            </div>
        )
    }

    const handleInputChange = (e: any, index: number) => {
        const newAutCodes = [...viaggioCodes]
        newAutCodes[index].viaggioCode = e.target.value
        setViaggioCodes(newAutCodes)
    }

    return (
        <div>
            <p className="mb-4 text-md text-text font-medium text-sm">Ricezione dati DieselWeb</p>
            <div className="flex flex-col justify-between gap-2">
                {
                    viaggioCodes.map((el, index) => {
                        return <div key={index} className="flex flex-row items-center justify-between">
                            <Autocomplete
                                id="name-autocomplete"
                                options={options}
                                noOptionsText="Nessun risultato"
                                getOptionLabel={(option) => option.Via.toString()}
                                sx={{ width: 300 }}
                                clearOnBlur={false}
                                clearOnEscape={false}
                                clearText="Cancella"
                                freeSolo
                                renderInput={(params) => <TextField
                                    {...params}
                                    value={el.name}
                                    key={index}
                                    onFocus={() => setFocused(index)}
                                    label={`Viaggio #${index + 1}`}
                                    onChange={(e) => handleInputChange(e, index)}
                                />}
                                renderOption={(props, options) => renderOptionHandler(props, options)}
                            />

                            <CustomButton label="RIMUOVI" color={colorPalette.Error} onClickHandler={() => removeViaggioCode(index)} />
                        </div>
                    })
                }
                <div>
                    <p className="text-text text-xs font-medium">*Il Prezzo del carburante viene preso dall'ultimo carico utile fatto nella cisterna</p>
                    <p className="text-text text-xs font-medium">*Aggiungi piu filtri per autocompletare con dati provenienti da piu viaggi</p>
                </div>

            </div>
            <div className="flex justify-between mt-4">
                <CustomButton label="AGGIUNGI FILTRO" color={colorPalette.Info} onClickHandler={addViaggioCode} />
                <CustomButton label="INVIA" color={colorPalette.Accent} onClickHandler={retrieveDataFromDieselWebAndFill} />
            </div>
        </div>
    )
}


export default DieselWebAutoComplete