import React, { useEffect, useState } from 'react'
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface IProps {
    remainder: number,
    capacity: number,

}

const CircularProgressWithLabel: React.FC<IProps> = ({ remainder, capacity }) => {

    const [rateValue, SetRateValue] = useState<number>();

    const calcRate = (val1: number, val2: number) => {
        let rate = (val1 / val2) * 100;
        SetRateValue(rate);
    }

    useEffect(() => {
        calcRate(remainder, capacity);
    }, [])

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', marginBottom: 2 }}>

            <CircularProgress sx={{ position: 'absolute', color: '#DCDCDC' }} size={100} variant="determinate" value={100} />
            <CircularProgress size={100} variant="determinate" value={rateValue} color={(rateValue < 50) ? (rateValue < 20) ? "error" : "warning" : "success"} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography sx={{ fontSize: 24, fontWeight: 'medium', color: (rateValue < 50) ? (rateValue < 20) ? "#F93636" : "#F7A51F" : "#08A908" }} variant="caption" component="div" >
                    {`${Math.round(rateValue)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel