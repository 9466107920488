import { deleteDataById } from "../../utils/deleteData";
import { endpoints } from "../../utils/endpoints";

export const deleteOrder = async (id: string) => {
    try {
        const response = await deleteDataById(endpoints.source + endpoints.deleteOrderById + id);

        return response;
    } catch (error: any) {
        console.log("ERROR:", error)
        return new Error(error);
    }
};