import { Autocomplete, TextField } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ItemExpense } from "../redux-store/mod-analitics/reportType"
import { colorPalette } from "../utils/colorPalette"
import CustomButton from "./CustomButton"
import { searchItemTemplates } from "../api/mod-analitics/searchItemTemplate"

interface IProps {
    deleteHandler?: (index: number, expensesState: ItemExpense[], setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>) => void,
    onChangeUpdate: (index: number, field: string, value: string | number, expensesState: ItemExpense[], setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>) => void,
    itemExpense: ItemExpense,
    index: number,
    expensesState: ItemExpense[],
    setExpensesState: Dispatch<SetStateAction<ItemExpense[]>>
}

const Expense: React.FC<IProps> = ({ deleteHandler, onChangeUpdate, itemExpense, index, expensesState, setExpensesState }) => {

    const [options, setOptions] = useState<any[]>([]);
    const [nameQuery, setNameQuery] = useState<string>("");

    useEffect(() => {
        // run db query only when user ends typing
        if (nameQuery.length > 1) {
            const timer = setTimeout(() => {
                handleAutocompleteChange(nameQuery);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [nameQuery]);

    const handleAutocompleteChange = (value: string) => {
        console.log('called query for value: ', value);

        if (value.length > 1) {
            searchItemTemplates(value)
                .then(data => {
                    const retrievedData = data.map((item: any, index: number) => { return { ...item, index: index + 1 } })

                    setOptions(retrievedData);
                })
        }
    }

    const handleInput = (value: string) => {
        setNameQuery(value);
        onChangeUpdate(index, "name", value, expensesState, setExpensesState)
    }

    const handleClick = (element: any) => {
        onChangeUpdate(index, "price", element.price, expensesState, setExpensesState)
    }

    const renderOptionHandler = (props: any, option: any) => {
        return (
            <div {...props} key={option.index} className="">
                <div onClick={() => handleClick(option)} className="flex flex-row justify-between p-4 cursor-pointer hover:bg-gray-100">
                    <p className="font-medium">#{option.index} {option.name}</p>
                    <p className="font-medium">{option.price}€</p>
                </div>
            </div >
        );
    }


    return (
        <div className="flex flex-row h-full w-full justify-between items-center mb-4 mt-4 gap-4">
            <p>{itemExpense.date}</p>

            <Autocomplete
                id="name-autocomplete"
                options={options}
                noOptionsText="Nessun risultato"
                groupBy={(option) => option.sourceName}
                getOptionLabel={(option) => option.name || itemExpense.name}
                sx={{ flex: 1 }}
                clearOnBlur={false}
                clearOnEscape={false}
                clearText="Cancella"
                freeSolo
                includeInputInList
                value={itemExpense.name}


                renderInput={(params) => <TextField
                    {...params}
                    onChange={(e) => handleInput(e.target.value)}
                    onBlur={(e) => handleInput(e.target.value)}
                    label="Nome Spesa"
                />}
                renderOption={(props, options) => renderOptionHandler(props, options)}
            />


            <TextField label="Prezzo/U" value={itemExpense.price} onChange={(e) => onChangeUpdate(index, "price", e.target.value, expensesState, setExpensesState)} type='number' />
            <TextField label="Qta" value={itemExpense.qta} onChange={(e) => onChangeUpdate(index, "qta", e.target.value, expensesState, setExpensesState)} type='number' />

            <div>
                <p className="font-medium">Costo: {(itemExpense.price * itemExpense.qta).toFixed(2)}€</p>
            </div>

            <CustomButton onClickHandler={() => deleteHandler(index, expensesState, setExpensesState)} color={colorPalette.Error} label='Rimuovi' />
        </div>
    )
}

export default Expense