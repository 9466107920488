import { IFuelData, ITotElement } from "../redux-store/mod-analitics/reportType";

export const calculateValue = (data: IFuelData[]) => {
    let result: number[] = [];
    data.map(item => {
        if (typeof item.dailyTot !== 'undefined') {
            result.push((item.dailyTot === 0) ? 0 : (item.dailyTot / 100));
        };
        if (typeof item.monthlyTot !== 'undefined') {
            result.push((item.monthlyTot === 0) ? 0 : (item.monthlyTot / 100));
        };
        if (typeof item.yearlyTot !== 'undefined') {
            result.push((item.yearlyTot === 0) ? 0 : (item.yearlyTot / 100));
        }
    })
    return result;
};

export const filterDataByValue = (data: Record<string, any>[], value: string, field: string) => {
    let result: number[] = [];
    data.map((item: any) => {
        const response = item[field].find((rif: any) => rif.identifier === value);
        result.push((response) ? (response.tot / 100) : 0);
    });
    return result;
};