import { useEffect } from "react"
import { Link } from "react-router-dom"
import AsyncLoader from "../components/AsyncLoader"
import CustomButton from "../components/CustomButton"
import { useAppDispatch, useAppSelector } from "../redux-store/store"
import GlobalPageTemplate from "../templates/GlobalPageTemplate"
import { colorPalette } from "../utils/colorPalette"
import { fetchReports } from "../redux-store/mod-analitics/actions/fetchReports"
import { DataTable } from "../components/DataTable"


const HEADER_FIELDS = ["Nome", "Spese", "Data Inizio", "Data Fine", "Note"];
const fieldsToPrint = ["name", "expense", "date.startDate", "date.endDate", "notes"];

const ReportsView: React.FC = () => {
    const dispatch = useAppDispatch()
    const reports = useAppSelector(state => state.report.reports);

    useEffect(() => {
        dispatch(fetchReports());
    }, [dispatch]);

    return <GlobalPageTemplate headerElement={<HeaderButton />} title='Report'>
        <>
            {/* ALL REPORTS */}
            {(reports.length > 0)
                ? <DataTable
                    defaultSortField={'createdAt'}
                    defaultSortDirection="desc"
                    headerFields={HEADER_FIELDS}
                    data={reports}
                    rowsOptions={[10, 25, 50]}
                    showDetailsButton={true}
                    size='small'
                    toFixedField
                    fieldsToPrint={fieldsToPrint}
                    redirectPath="/monitoraggio/report/details?id="
                />
                : <p className="p-8 text-center text-sm font-medium text-text">Nessun report trovato...</p>
            }
        </>
    </GlobalPageTemplate>
}

const HeaderButton: React.FC = () => {
    return <Link to='/monitoraggio/report/create'>
        <CustomButton color={colorPalette.Accent} label='Crea Report' />
    </Link>
}

export default ReportsView