import React from "react";
import moment from "moment";
import CustomButton from "./CustomButton";
import { filterValue } from "../views/FuelView";
import { colorPalette } from "../utils/colorPalette";

type Props = {
    onClickSubmit?: (value: filterValue, year?: number) => void;
};

const FuelDataFilter: React.FC<Props> = ({ onClickSubmit }) => {
    const currentYear = moment().year();
    const startYear = 2017;

    const renderButtons = () => {
        const buttons = [];
        for (let year = currentYear - 1; year >= startYear; year--) {
            buttons.push(
                <CustomButton key={year} onClickHandler={() => onClickSubmit(filterValue.year, year)} color={colorPalette.Accent} label={year.toString()} />
            );
        }
        return buttons;
    };

    return <div className="flex flex-col gap-2 mt-4 mb-4">
        <div className="flex flex-col gap-1 w-full">
            <p className="font-light text-base text-center text-gray-600 mb-1">Anno Corrente</p>
            <div className="flex justify-between items-center">
                <p className="font-light text-sm text-gray-600">Dati del mese corrente</p>
                <CustomButton onClickHandler={() => onClickSubmit(filterValue.currentMonth)} color={colorPalette.Accent} label='Mese corrente' />
            </div>
            <div className="flex justify-between items-center">
                <p className="font-light text-sm text-gray-600">Dati degli ultimi tre mesi</p>
                <CustomButton onClickHandler={() => onClickSubmit(filterValue.lastThreeMonths)} color={colorPalette.Accent} label='Ultimi 3 mesi' />
            </div>
            <div className="flex justify-between items-center">
                <p className="font-light text-sm text-gray-600">Dati degli ultimi sei mesi</p>
                <CustomButton onClickHandler={() => onClickSubmit(filterValue.lastSixMonths)} color={colorPalette.Accent} label='Ultimi 6 mesi' />
            </div>
        </div>
        <p className="font-light text-base text-center text-gray-600 mb-1">Anni passati</p>
        <div className="flex flex-wrap justify-left gap-2">
            <CustomButton onClickHandler={() => onClickSubmit(filterValue.lastYear)} color={colorPalette.Accent} label='Anno corrente' />
            {renderButtons()}
            <CustomButton onClickHandler={() => onClickSubmit(filterValue.allTime)} color={colorPalette.Accent} label='da inizio' />
        </div>
    </div>;
};

export default FuelDataFilter;