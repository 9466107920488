import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React from 'react'
import AssetsMarker from './AssetsMarker';

interface IProps {
    centerLatidude: number;
    centerLongitude: number;
    data: any;
}

const GeoMap: React.FunctionComponent<IProps> = ({ centerLatidude, centerLongitude, data }) => {

    const configValue: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: configValue
    });

    // TODO: FARE REFACTORING GESTIONE LAT E LON

    return (
        (!isLoaded || configValue === undefined)
            ? <div>Loading...</div>
            :
            (data)
                ? <div className='w-full h-96'>
                    <GoogleMap
                        center={{ lat: centerLatidude, lng: centerLongitude }}
                        mapContainerClassName={"w-full h-full"}
                        zoom={10}
                    >
                        {
                            (data.length > 0)
                                ? data.map((item: any, index: number) => {
                                    console.log(parseFloat(item.latitude));
                                    return (
                                        <AssetsMarker key={index} latitude={parseFloat(item.Latitude)} longitude={parseFloat(item.Longitude)} />
                                    )
                                })
                                : (data)
                                    ? <>
                                        <Marker
                                            icon={{ url: ('/images/MarkerIcon.svg') }}
                                            position={{ lat: parseFloat(data.Latitude), lng: parseFloat(data.Longitude) }}
                                        />
                                    </>
                                    : null
                        }
                    </GoogleMap >
                </div>

                : <h1>Loading.....</h1>
    )
}

interface IPropsV2 {
    centerLatidude: string;
    centerLongitude: string;
}

export const GeoMapV2: React.FunctionComponent<IPropsV2> = ({ centerLatidude, centerLongitude }) => {

    const configValue: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: configValue
    });

    return (
        (!isLoaded || configValue === undefined)
            ? <div>Loading...</div>
            : <div className='w-full h-96'>
                <GoogleMap
                    center={{ lat: parseFloat(centerLatidude), lng: parseFloat(centerLongitude) }}
                    mapContainerClassName={"w-full h-full"}
                    zoom={10}
                >
                    <AssetsMarker latitude={parseFloat(centerLatidude)} longitude={parseFloat(centerLongitude)} />
                </GoogleMap >
            </div>
    )
}

export default GeoMap