import { endpoints } from "../../utils/endpoints";
import { fetchData } from "../../utils/fetchData";

export const searchMezziTarga = async (value: string) => {
    try {
        const items = await fetchData(endpoints.searchMezziTarga + `?search=${value}`);

        return items.data;
    } catch (error: any) {
        console.log("ERROR fetching mezzi: ", error)
    }
}