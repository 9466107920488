import React, { useEffect, useState } from 'react';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import { fetchTankRefills } from '../redux-store/mod-dieselweb/actions/fetchTankRefills';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import AsyncLoader from '../components/AsyncLoader';
import { DataTable } from '../components/DataTable';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const HEADER_FIELDS = ["#", "Data/Ora", "Carico", "Fornitore", "Doc.", "N Doc.", "Data Doc.", "Q.ta", "Prezzo/u", "Sistema", "Cisterna"];
const fieldsToPrint = ["Num", "DataOra", "Carico", "FisForni", "FisDocTipo", "FisDocNum", "FisDocData", "FisQtaLt", "FisPrezzo", "SisCode", "CisCode"];

const DieselwebTankRefills: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setvalue] = useState('');

    // REDUX 
    const dispatch = useAppDispatch();
    const tankRefills = useAppSelector(state => state.dieselweb.tankRefills);

    useEffect(() => {
        if (searchParams.get("filter_field") === null)
            setSearchParams(`limit=15&page=1`);
    }, []);

    useEffect(() => {
        if (searchParams.get("limit") !== null)
            dispatch(fetchTankRefills(`?${searchParams.toString()}`));
    }, [searchParams]);

    const fetchNextPage = () => {
        setSearchParams(`limit=15&page=${tankRefills.currentPage + 1}&${value}`);
    };

    const fetchBackPage = () => {
        setSearchParams(`limit=15&page=${tankRefills.currentPage - 1}&${value}`);
    };

    const onClickFilter = (value: string) => {
        setvalue(value)
        setSearchParams(`limit=15&page=1&${value}`);
    };

    return (
        <GlobalPageTemplate title='Carichi Cisterne' >
            <>
                <div className='flex flex-col w-full items-center pb-4'>
                    <h1 className="pb-2 text-xl">Filtro Sistema:</h1>
                    <ToggleButtonGroup
                        color="primary"
                        value={(searchParams.get('field_value') !== null) ? searchParams.get('field_value') : 'All'}
                        exclusive
                        sx={{ width: '100%', justifyContent: 'center' }}>
                        <ToggleButton sx={{ width: '10%' }} value='1' onClick={() => onClickFilter("filter_field=SisCode&field_value=1")}>1</ToggleButton>
                        <ToggleButton sx={{ width: '10%' }} value='2' onClick={() => onClickFilter("filter_field=SisCode&field_value=2")}>2</ToggleButton>
                        <ToggleButton sx={{ width: '10%' }} value='3' onClick={() => onClickFilter("filter_field=SisCode&field_value=3")}>3</ToggleButton>
                        <ToggleButton sx={{ width: '10%' }} value='All' onClick={() => onClickFilter("")}>All</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <AsyncLoader height={500} condition={tankRefills.data.length > 0}>
                    <DataTable
                        data={tankRefills.data}
                        defaultSortField={"DataOra"}
                        defaultSortDirection='desc'
                        headerFields={HEADER_FIELDS}
                        fieldsToPrint={fieldsToPrint}
                        size='small'
                        showDetailsButton={false}
                        dataCurrentPage={tankRefills.currentPage - 1}
                        dataCount={tankRefills.totalItems}
                        rowsPage={15}
                        rowsOptions={[]}
                        nextIconButtonHandler={fetchNextPage}
                        backIconButtonHandler={fetchBackPage}
                    />
                </AsyncLoader>
            </>
        </GlobalPageTemplate>
    )
}

export default DieselwebTankRefills