import { IUser } from "../redux-store/auth/authType";

export const deleteDataById = async (url: string) => {

    const value = localStorage.getItem('user');
    const user: IUser = JSON.parse(value)

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Access-Control-Allow-Origin', '*');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');
    if (user !== null) {
        requestHeaders.append("Authorization", "Bearer " + user.accessToken);
    };

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: requestHeaders
        });


        const data = await response.json();
        return data.data

    } catch (error: any) {
        console.log("ERROR:", error)
        return new Error(error);
    }

};
