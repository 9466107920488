import { fetchData } from "../../../utils/fetchData";
import { endpoints } from "../../../utils/endpoints";

export const getReportById = async (id: string) => {
    try {
        const data = await fetchData(endpoints.source + endpoints.fetchReportById + id);
        // check error and return error 
        if (data instanceof Error) {
            throw new Error(data.message);
        }

        return data.data

    } catch (error: any) {
        console.log("ERROR fetching orders: ", error)
    }
}