import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { endpoints } from "../../../utils/endpoints"
import { replaceDrivers } from "../dieselwebSlice";

export const fetchDrivers = (searchParams: string = '') => {
    return async (dispatch: Dispatch) => {
        try {
            const drivers = await fetchData(endpoints.fetchDrivers + searchParams);
            dispatch(
                replaceDrivers({
                    drivers: drivers.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching drivers: ", error)
        }
    }
}