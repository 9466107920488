import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import { fetchTanks } from '../redux-store/mod-dieselweb/actions/fetchTanks';
import { ITank } from '../redux-store/mod-dieselweb/dieselwebType';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';
import { CardContainer } from '../components/CardContainer';
import { Link } from 'react-router-dom';
import FeedbackModal from '../utils/FeedbackModal';
import CustomInputForm from '../components/customInputForm';
import { setSnackbar } from '../redux-store/uiManager/actions/setSnackbar';
import { updateTankName } from '../api/mod-dieselweb/updateTankName';

const TankStatus: React.FC = () => {

    const dispatch = useAppDispatch();
    const tanks = useAppSelector(state => state.dieselweb.tanks);
    const [modal, setModal] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [id, setId] = useState<string>("");

    useEffect(() => {
        dispatch(fetchTanks());
    }, [dispatch]);

    const headerLink = (value: number) => (
        <Link to={`/dieselweb/cisterne?limit=15&page=1&filter_field=SisCode&field_value=${value}`}>
            <div className='flex flex-row items-center justify-end p-4'>
                <p className='text-info text-base font-medium text-right'>Visualizza Carichi</p>
            </div>
        </Link>
    );

    const updateOrderHandler = async () => {
        if (name?.length > 0) {
            dispatch(setSnackbar("Aggiornamento nome cisterna in corso...", "info"));

            const newName = { name: name };

            const response = await updateTankName(newName, id);

            if (response instanceof Error) {
                dispatch(setSnackbar("Errore durante l'aggiornamento del nome", "error"));
                return;
            }
            setModal(false);
            await dispatch(fetchTanks());
            dispatch(setSnackbar("Nome aggiornato con successo!", "success"));
        } else {
            dispatch(setSnackbar("Inserire un nome", "error"));
        }
    };

    const openModalHandler = (id: string) => {
        setModal(true);
        setId(id);
    }


    return (
        <>
            <h1 className="font-bold text-3xl mb-4 mt-10 text-text">Stato Cisterne</h1>
            <div className='flex gap-4'>
                {
                    tanks.map((tank: ITank, index) => {
                        return <CardContainer key={index} title={tank.name} headerElement={headerLink(tank.linkedSisCode)} >
                            <p className='text-base mb-2 font-medium text-text'>Dati Cisterna:</p>
                            <div className='w-full flex flex-col items-center justify-between text-text text-sm font-medium'>
                                <div className='mb-4'>
                                    <p>Giacenza: {tank.stock / 100} Litri</p>
                                    <p>Capienza: {tank.capacity} Litri</p>
                                </div>
                                <CircularProgressWithLabel remainder={tank.stock / 100} capacity={tank.capacity} />
                                <p className='self-end text-xs text-info cursor-pointer' onClick={() => openModalHandler(tank._id)}>Modifica nome</p>

                            </div>

                        </CardContainer>
                    })
                }
                <FeedbackModal
                    isOpen={modal}
                    setIsOpen={setModal}
                    navigateBack={false}
                    title='Modifica nome cisterna'
                    desc="Cambiare il nome della cisterna influira soltando all'interno di questa dashboard."
                    standardButton={{ onClick: updateOrderHandler, text: 'Invia' }}
                >
                    <CustomInputForm value={name} setValue={setName} />
                </FeedbackModal>
            </div >
        </>
    )
}

export default TankStatus