import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getReportById } from '../redux-store/mod-analitics/actions/getReportById';
import ReportTable from '../components/ReportTable';
import { IReport } from '../redux-store/mod-analitics/reportType';
import AsyncLoader from '../components/AsyncLoader';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import CustomButton from '../components/CustomButton';
import { downloadCSV, onClickDownload } from '../utils/downloadCSV';
import { colorPalette } from '../utils/colorPalette';
import { deleteReport } from '../api/mod-analitics/deleteReport';
import { setSnackbar } from '../redux-store/uiManager/actions/setSnackbar';
import { useAppDispatch } from '../redux-store/store';
import FeedbackModal from '../utils/FeedbackModal';

const ReportDetails: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [report, setReport] = useState<IReport>(undefined);
    const [downloadLink, setDownloadLink] = useState<HTMLAnchorElement>(undefined);
    const [objectUrl, setObjectUrl] = useState<string>(undefined);

    useEffect(() => {
        const id = searchParams.get("id");
        getReport(id);

        return () => {
            // clean memory on unmount
            if (typeof objectUrl !== 'undefined') {
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, []);

    useEffect(() => {
        if (typeof report !== 'undefined') {
            const linkElement = downloadCSV(report, "report_" + report.name.replace(/\s/g, ''))
            setDownloadLink(linkElement.link);
            setObjectUrl(linkElement.urlObject);
        }
    }, [report])


    const getReport = async (id: string) => {
        const response = await getReportById(id);
        setReport(response)
    };

    const exportCSVFile = () => {
        onClickDownload(downloadLink);
    };

    const deleteReportHandler = async () => {
        // call delete order api
        const response: any = await deleteReport(report._id)

        // check if the response is ok
        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante l'eliminazione del report", "error"))
            return;
        }


        // navigate to orders list
        navigate("/monitoraggio/report");

        dispatch(setSnackbar("Report eliminato correttamente.", "success"))
    }

    const headerButtons = (
        <div className='flex gap-2'>
            <CustomButton color={colorPalette.Info} label="Export CSV" onClickHandler={exportCSVFile} padding='pt-2 pb-2' />
            <CustomButton color={colorPalette.Error} label="Elimina" onClickHandler={() => setDeleteModal(true)} padding='pt-2 pb-2' />
        </div>

    )

    return (
        <GlobalPageTemplate title={`Report ${report?.name?.toUpperCase()}`} headerElement={headerButtons}>
            <>
                <AsyncLoader condition={typeof report !== 'undefined'} height={500}>
                    <ReportTable newReport={report} />
                </AsyncLoader>
                <FeedbackModal isOpen={deleteModal} setIsOpen={setDeleteModal} title='Sicuro di volerlo eliminare?' desc='Eliminando questo report non potrai piu recuperarlo!' standardButton={{ onClick: deleteReportHandler, text: 'Elimina' }} />
            </>
        </GlobalPageTemplate>
    )
}

export default ReportDetails