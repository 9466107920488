import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as FaIcons from 'react-icons/fa';
import { colorPalette } from '../utils/colorPalette';
import CustomText from '../components/CustomText';
import PopoverContainer from '../components/PopoverContainer';

const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: start;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #ffffff;
`

interface IProps {
    showSidebar: Dispatch<SetStateAction<void>>
};

const Navbar: React.FC<IProps> = ({ showSidebar }) => {

    const moment = require('moment');
    let now = moment().format('ddd, DD MMMM YYYY');
    //Popover state 
    const [anchorEl, setAnchorEl] = useState<SVGElement>(null);
    const [popover, setPopover] = useState<boolean>(false);

    const popoverBody = (
        <div className='flex flex-col bg-gray-100 rounded-lg p-2 mt-4 mb-4'>
            <p className='text-sm font-bold capitalize'>Mago</p>
            <p className='text-xs font-medium text-justify'>Testo notifica</p>
            <p className='text-xs font-thin text-right'>3/16/2023 16:30</p>
        </div>
    );

    const buttonHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        showSidebar();
    };


    const handleSubmit = (event: React.MouseEvent<SVGElement>) => {
        setAnchorEl(event.currentTarget)
        setPopover(true);
    };

    return (
        <div className='flex h-20 justify-between items-center shadow-sm z-10 bg-background'>
            <MenuIconOpen to="#" onClick={buttonHandler}>
                <FaIcons.FaBars color={colorPalette.Primary} />
            </MenuIconOpen>
            <div className='flex flex-row items-center justify-end'>
                <Link to="/account">
                    <FaIcons.FaUser size={32} className='p-2 cursor-pointer' color={colorPalette.Primary} />
                </Link>
                {/* <FaIcons.FaBell size={32} className='p-2 cursor-pointer' onClick={handleSubmit} color={colorPalette.Primary} /> */}
                <div className='flex gap-3 ml-5 mr-5 items-center'>
                    <FaIcons.FaCalendarAlt size={32} className='p-2' color={colorPalette.Primary} />
                    <CustomText customStyle={{ fontWeight: 450 }} size='md' label={now} />
                </div>
            </div>
            <PopoverContainer anchorEl={anchorEl} isOpen={popover} setIsOpen={setPopover} title='Notifiche' content={popoverBody} button label='Visualizza Notifiche' />
        </div>
    )
}

export default Navbar