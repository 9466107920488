import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, SubTitle } from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend
);

interface IProps {
    labels: string[];
    label: string;
    title: string;
    value: number[];
    backgroundColor: string;
    borderColor: string;
};

const BarChart: React.FunctionComponent<IProps> = ({ labels, label, title, value, backgroundColor, borderColor }) => {
    const data = ({
        labels: labels,
        datasets: [{
            label: label,
            data: value,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 3
        }]
    });

    return <div className='w-full mt-8 h-full'>
        <Bar
            options={{
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: title
                    }
                }
            }} data={data} />
    </div>;
};

export default BarChart;