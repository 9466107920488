import { fetchData } from "../../utils/fetchData";
import { endpoints } from "../../utils/endpoints";

export const loginService = async (email: string, password: string) => {
    try {
        const auth = await fetchData(endpoints.loginRoute, { email, password }, 'POST');

        return auth.userData;

    } catch (error: any) {
        console.log("ERROR login: ", error)
    }
};