export const SORT_DIRECTION = {
    ASCENDING: 'ascending',
    DESCENDING: 'descending'
}

//Sort: by date (GENERAL FIELD)
export const sortArrayByDate = (sortDirection: 'ascending' | 'descending', field: any, sourceArray: any[]) => {
    const arraySortedResult = [...sourceArray]

    switch (sortDirection) {
        case SORT_DIRECTION.ASCENDING:
            arraySortedResult.sort((a, b) => <any>new Date(b[`${field}`]) - <any>new Date(a[`${field}`]));
            break;

        case SORT_DIRECTION.DESCENDING:
            arraySortedResult.sort((a, b) => <any>new Date(a[`${field}`]) - <any>new Date(b[`${field}`]));
            break;

        default:
            break;
    }

    return arraySortedResult;
};