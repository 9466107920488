import React from 'react'
import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import { closeSnackbar } from '../redux-store/uiManager/actions/closeSnackbar';

const SnackBar = () => {
    const snackBar = useAppSelector(state => state.uiManager.snackbar);
    const dispatch = useAppDispatch();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    return (
        <Snackbar
            open={snackBar.isOpen}
            onClose={handleClose}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            key={'bottom - right'}
        >
            <Alert severity={snackBar.severity}>{snackBar.message}</Alert>
        </Snackbar>
    )
}

export default SnackBar