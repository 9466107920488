import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { endpoints } from "../../../utils/endpoints"
import { replaceTankRefills } from "../dieselwebSlice";

export const fetchTankRefills = (searchParams: string = '') => {
    return async (dispatch: Dispatch) => {
        try {
            const tankRefills = await fetchData(endpoints.fetchTankRefills + searchParams);
            dispatch(
                replaceTankRefills({
                    tankRefills: tankRefills.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching tankRefills: ", error)
        }
    }
}