import React, { useEffect, useState } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { useSearchParams } from "react-router-dom";
import { movimatica_machinery_list } from "../data/machinery_list";
import AsyncLoader from "../components/AsyncLoader";
import GeoMap from "../components/GeoMap";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/endpoints";
import { ApiAction } from "./MacchinariAlmecDetails";
import { DataTable } from "../components/DataTable";
import { Divider } from "@mui/material";

interface IPropsDefaultInfo {
    machineSelected: any;
}

const MacchinariMovimaticaDetails: React.FunctionComponent = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [machineSelected, setMarchineSelected] = useState(undefined);
    const [apiData, setApiData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [position, setPosition] = useState<any>();

    // status api actions data
    const [activeAlarms, setActiveAlarms] = useState<any>(undefined);
    const [recentPositions, setRecentPositions] = useState<any>(undefined);
    const [telemetry, setTelemetry] = useState<any>(undefined);
    const [merlomobilityTelemetry, setMerlomobilityTelemetry] = useState<any>(undefined);
    const [activitiesData, setActivitiesData] = useState<any>([]);

    useEffect(() => {
        const id = searchParams.get("id");
        setMarchineSelected(movimatica_machinery_list.find((machine) => machine.id === id));

        // reset status api actions data
        setActiveAlarms(undefined);
        setRecentPositions(undefined);
        setTelemetry(undefined);
        setMerlomobilityTelemetry(undefined);

        // call api and set api data to state apiData
        retrieveApiData(id);
    }, []);

    const retrieveApiData = async (id: string | null) => {
        try {
            setIsLoading(true);
            const response = await fetchData(endpoints.fetchMovimaticaVehicleById.replace(":id", id || ""));
            const responsePosition = await fetchData(endpoints.fetchMovimaticaVehiclePositions.replace(":id", id || ""));

            const currentVehiclePosition = responsePosition.find((p: any) => p.id == id);

            setApiData(response);
            setPosition(currentVehiclePosition);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    const retrieveActiveAlarms = async () => {
        try {
            const response = await fetchData(endpoints.fetchMovimaticaVehicleActiveAlarms.replace(":id", machineSelected.id));
            setActiveAlarms(response);
        } catch (error) {
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    const retrieveRecentPositions = async () => {
        try {
            const response = await fetchData(endpoints.fetchMovimaticaVehicleTrips.replace(":id", machineSelected.id));

            const formattedPlaces = response.map((item: any) => {
                return {
                    id: item.id,
                    distance: item.distance + " km",
                    startRegion: item.start.place.state,
                    startAddress: item.start.place.address,

                    endRegion: item.stop.place.state,
                    endAddress: item.stop.place.address,

                    workTime: item.workTime

                }
            })

            setRecentPositions(formattedPlaces);
        } catch (error) {
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    const retrieveTelemetry = async () => {
        try {
            const response = await fetchData(endpoints.fetchMovimaticaVehicleTelemetry.replace(":id", machineSelected.id));

            console.log(JSON.stringify(response, null, 4))

            setTelemetry(response);
        } catch (error) {
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    const retrieveMerlomobilityTelemetry = async () => {
        try {
            const response = await fetchData(endpoints.fetchMovimaticaVehicleTelemetryStats.replace(":id", machineSelected.id));

            console.log(JSON.stringify(response, null, 4))

            setMerlomobilityTelemetry(response);
        } catch (error) {
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    const retrieveActivities = async () => {
        try {
            const response = await fetchData(endpoints.fetchMovimaticaVehicleActivities.replace(":id", machineSelected.id));

            console.log(JSON.stringify(response, null, 4))

            setActivitiesData(response);
        } catch (error) {
            console.log("ERROR fetchin movimatica: " + error)
        }
    }

    return (
        <AsyncLoader condition={typeof machineSelected !== 'undefined'} height={300}>
            <GlobalPageTemplate title={`Dettagli Macchinario: ${machineSelected?.name}`}>
                <>
                    <DefaultInfo machineSelected={machineSelected} />
                    {/* api retrieved info */}
                    {(!isLoading)
                        ? <>
                            <div className="flex flex-row items-center mt-8">
                                <p className="text-2xl font-medium">Informazioni da Api Movimatica</p>
                            </div>

                            <div className="flex flex-col mt-4">
                                <p className="text-lg font-medium">Nome: {apiData?.name}</p>
                                <p className="text-lg font-medium">Targa: {apiData?.plate}</p>
                                <p className="text-lg font-medium">Modello: {apiData?.model}</p>
                                <p className="text-lg font-medium">Marca: {apiData?.brand}</p>
                                <p className="text-lg font-medium">Telaio: {apiData?.chassis}</p>
                                <p className="text-lg font-medium">Odometro: {apiData?.odometer}</p>
                                <p className="text-lg font-medium">Ore Motore: {apiData?.engineHours}</p>
                            </div>

                            <p className="text-2xl font-medium mt-8">Geolocalizzazione (ultima posizione registrata):</p>
                            {
                                (typeof position !== 'undefined') ?
                                    <GeoMap centerLatidude={position.gps.lat} centerLongitude={position.gps.lng} data={{
                                        Latitude: position.gps.lat,
                                        Longitude: position.gps.lng
                                    }} />
                                    : <p className="p-8 text-center text-sm font-medium text-text">Caricamento...</p>
                            }

                            <div className="flex flex-row items-center mt-8">
                                <p className="text-2xl font-medium mt-8">Azioni Macchinario</p>
                            </div>

                            <div className="flex flex-col mt-4">
                                {/* <ApiAction name="Visualizza Allarmi Attivi" action={retrieveActiveAlarms} />
                                {
                                    (typeof activeAlarms !== 'undefined')
                                        ? (activeAlarms.length > 0)
                                            ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                                <p className="text-xl font-medium">Informazioni Allarmi </p>

                                                <DataTable
                                                    size="small"
                                                    headerFields={["id", "valore", "stato", "allarme", "sensore", "data inizio", "data fine"]}
                                                    fieldsToPrint={["id", "last_value", "status", "alarm", "sensor", "firedDateIn", "firedDateOut"]}
                                                    data={activeAlarms}
                                                    defaultSortField={"id"}
                                                    defaultSortDirection={"asc"}
                                                    showDetailsButton={false}
                                                    redirectPath=""
                                                />
                                            </div>
                                            : <p className="text-md font-medium p-4">Nessun allarme trovato.</p>
                                        : null
                                } */}

                                <ApiAction name="Visualizza Attivita" action={retrieveActivities} />


                                <ApiAction name="Visualizza Posizioni Recenti" action={retrieveRecentPositions} />
                                {
                                    (typeof recentPositions !== 'undefined')
                                        ? (recentPositions.length > 0)
                                            ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                                <p className="text-xl font-medium">Informazioni Posizioni </p>

                                                <DataTable
                                                    size="small"
                                                    headerFields={["id", "Distanza", "Regione Partenza", "indirizzo Partenza", "Regione Arrivo", "Indirizzo Arrivo", "Tempo di lavoro"]}
                                                    fieldsToPrint={['id', "distance", "startRegion", "startAddress", "endRegion", "endAddress", "workTime"]}
                                                    data={recentPositions}
                                                    defaultSortField={"id"}
                                                    defaultSortDirection={"asc"}
                                                    showDetailsButton={false}
                                                    redirectPath=""
                                                />
                                            </div>
                                            : <p className="text-md font-medium p-4">Nessuna posizione trovata.</p>
                                        : null
                                }

                                <ApiAction name="Visualizza Telemetria" action={retrieveTelemetry} />
                                {
                                    (typeof telemetry !== 'undefined')
                                        ? (telemetry.length > 0)
                                            ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                                <p className="text-xl font-medium">Informazioni Telemetria </p>

                                                {telemetry.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index} className="flex flex-col mt-4">
                                                            <p className="text-lg font-medium">Data: {item.timestamp}</p>
                                                            <p className="text-lg font-medium">Posizione: {(item.place.state, ", " + item.place.place + ", " + item.place.address + ", " + item.place.zip)}</p>
                                                            <p className="text-lg font-medium mt-2">Valori Telemetria</p>
                                                            {item.measures.map((telemetryItem: any, index: number) => {
                                                                return (
                                                                    <div key={index} className="flex flex-col mt-2 mb-2">
                                                                        <p className="text-md font-medium">identificativo: {telemetryItem.id}</p>
                                                                        <p className="text-md font-medium">nome: {telemetryItem.label}</p>
                                                                        <p className="text-md font-medium">valore: {(telemetryItem.value + ' ' + telemetryItem.unit)}</p>
                                                                    </div>
                                                                )

                                                            })}

                                                            <Divider />

                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            : <p className="text-md font-medium p-4">Nessuna telemetria trovata.</p>
                                        : null
                                }

                                <ApiAction name="Visualizza Telemetria Merlomobility" action={retrieveMerlomobilityTelemetry} />
                                {
                                    (typeof merlomobilityTelemetry !== 'undefined')
                                        ? <div className="bg-slate-100 rounded-2xl p-4 mt-4 mb-4 w-full">
                                            <p className="text-xl font-medium mb-4">Informazioni Telemetria Merlomobility </p>

                                            <div className="flex-col text-md mb-4">
                                                <p className="text-md font-medium" >Consumo adBlue (in litri): {merlomobilityTelemetry.adBlueConsumptionLiters}</p>
                                                <p className="text-md font-medium" >Percentuale di carburante durante la guida: {merlomobilityTelemetry.drivingPercentFuel}</p>
                                                <p className="text-md font-medium" >Percentuale di tempo durante la guida: {merlomobilityTelemetry.drivingPercentTime}</p>
                                                <p className="text-md font-medium" >Consumo di carburante (in litri): {merlomobilityTelemetry.fuelConsumptionLiters}</p>
                                                <p className="text-md font-medium" >Percentuale di carburante in stato inattivo: {merlomobilityTelemetry.idlePercentFuel}</p>
                                                <p className="text-md font-medium" >Percentuale di tempo in stato inattivo: {merlomobilityTelemetry.idlePercentTime}</p>
                                                <p className="text-md font-medium" >Chilometri: {merlomobilityTelemetry.km}</p>
                                                <p className="text-md font-medium" >Percentuale di carburante durante il carico: {merlomobilityTelemetry.loadingPercentFuel}</p>
                                                <p className="text-md font-medium" >Percentuale di tempo durante il carico: {merlomobilityTelemetry.loadingPercentTime}</p>
                                                <p className="text-md font-medium" >Percentuale di tempo su stabilizzatori: {merlomobilityTelemetry.onStabilizersPercent}</p>
                                                <p className="text-md font-medium" >Percentuale di tempo sulle ruote: {merlomobilityTelemetry.onWheelsPercent}</p>
                                                <p className="text-md font-medium" >Ore di funzionamento: {merlomobilityTelemetry.operatingHours}</p>
                                                <p className="text-md font-medium" >Totale delle ore di funzionamento: {merlomobilityTelemetry.totalOperatingHours}</p>
                                                <p className="text-md font-medium" >Percentuale di utilizzo della cabina: {merlomobilityTelemetry.useCabPercent}</p>
                                                <p className="text-md font-medium" >Percentuale di utilizzo della piattaforma: {merlomobilityTelemetry.usePlatformPercent}</p>
                                                <p className="text-md font-medium" >Percentuale di utilizzo della radio: {merlomobilityTelemetry.useRadioPercent}</p>
                                            </div>
                                        </div>
                                        : null

                                }
                            </div>


                        </>
                        : <p className="p-8 text-center text-sm font-medium text-text">Caricamento...</p>
                    }


                </>
            </GlobalPageTemplate>
        </AsyncLoader>
    )
}


const DefaultInfo: React.FunctionComponent<IPropsDefaultInfo> = ({ machineSelected }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Nome Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Tipo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.type}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Data Creazione:</p>
                        <p className="text-lg">{machineSelected?.created_at}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MacchinariMovimaticaDetails;