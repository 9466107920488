/**
 * PRODUCTION
 */
export const mainSource: string = 'https://api.zecchini-dashboard.com:3000/api';
export const mainSourceSocket: string = 'https://api.zecchini-dashboard.com:8000';

/**
 * DEVELOPMENT
 */
// export const mainSource: string = 'http://localhost:3001/api';
// export const mainSourceSocket: string = 'http://localhost:8000';