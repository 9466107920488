export const tenec_machinery_list = [
    {
        id: "1",
        name: 'SCARRABILE CBS 10240',
        type: 'SCARRABILE CBS',
        created_at: '16-05-2023'
    }
]

export const almec_machinery_list = [
    {
        id: "1",
        name: 'DU7500',
        type: 'Zecchini Group DU7500',
        created_at: '16-05-2023'
    }
]

export const movimatica_machinery_list = [
    {
        id: "10418",
        name: 'Vogele SUPER 1603-3i',
        type: 'FINITRICE',
        targa: 'I0462',
        created_at: '07-06-2023'
    },
    {
        id: "12783",
        name: 'HAMM DV+90i VV-S',
        type: 'Rullo compressore',
        targa: 'I0954',
        created_at: '07-06-2023'
    }
]

export const trackvision_machinery_list = [
    {
        id: "517395L00054",
        name: 'RX 70-25',
        type: 'CARRELLI',
        created_at: '16-05-2023'
    }
]

export const waynet_machinery_list = [
    {
        id: "5662",
        name: 'YANMAR TELAIO-LNYJ62140',
        type: 'YANMAR VIO38',
        created_at: '16-05-2023'
    }
]