import { endpoints } from "../../utils/endpoints";
import { updateData } from "../../utils/updateData";

export const updateTankName = async (newName: { name: string }, id: string) => {
    try {
        const data = await updateData(endpoints.updateTankName + id, newName);

        if (data instanceof Error || typeof data === "undefined") {
            throw new Error(data.message);
        }

        return data;
    } catch (error: any) {
        console.log("ERROR updating tank name: ", error)
        return new Error(error);
    }
}
