import React, { useEffect } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import CustomButton from "../components/CustomButton";
import { colorPalette } from "../utils/colorPalette";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import { Link } from "react-router-dom";
import { fetchCustomItem } from "../redux-store/mod-analitics/actions/fetchCustomItem";
import { DataTable } from "../components/DataTable";
import { fetchMagoItem } from "../redux-store/mod-analitics/actions/fetchMagoItem";

const HEADER_FIELDS = ["Nome", "Categoria", "Prezzo", "Data Creazione", "Data Ultima Modifica"];
const fieldsToPrint = ["name", "category", "price", "createdAt", "updatedAt"];


const OrderView: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const customItem = useAppSelector(state => state.report.customItem);
    const magoItem = useAppSelector(state => state.report.magoItem);

    useEffect(() => {
        dispatch(fetchCustomItem(15, 1));
        dispatch(fetchMagoItem(15, 1));
    }, [dispatch]);

    const fetchCustomNextPage = () => {
        dispatch(fetchCustomItem(15, customItem.currentPage + 1))
    };

    const fetchCustomBackPage = () => {
        dispatch(fetchCustomItem(15, customItem.currentPage - 1))
    };

    const fetchMagoNextPage = () => {
        dispatch(fetchMagoItem(15, magoItem.currentPage + 1))
    };

    const fetchMagoBackPage = () => {
        dispatch(fetchMagoItem(15, magoItem.currentPage - 1))
    };

    return <GlobalPageTemplate headerElement={<HeaderButton />} title='Oggetti' >
        <>
            {/* CUSTOM RETRIEVED ITEM TEMPLATES */}
            <p className="text-xl mb-4">Oggetti Personalizzati</p>
            {
                (customItem?.data?.length > 0)
                    ? <DataTable
                        defaultSortField={"name"}
                        headerFields={HEADER_FIELDS}
                        fieldsToPrint={fieldsToPrint}
                        data={customItem.data}
                        size='small'
                        rowsPage={15}
                        rowsOptions={[]}
                        dataCurrentPage={customItem.currentPage - 1}
                        dataCount={customItem.totalItems}
                        redirectPath="/monitoraggio/oggetti/update/?id="
                        toFixedField
                        nextIconButtonHandler={fetchCustomNextPage}
                        backIconButtonHandler={fetchCustomBackPage}
                    />
                    : <p className="p-8 text-center text-sm font-medium text-text">Nessun oggetto personalizzato trovato...</p>
            }


            {/* MAGO RETRIEVED ITEM TEMPLATES */}
            <p className="text-xl mb-4 mt-4">Oggetti Mago</p>
            {
                (magoItem?.data?.length > 0)
                    ? <DataTable
                        defaultSortField={"name"}
                        headerFields={["Nome", "Fornitore", "Prezzo", "Data Creazione", "Data Ultima Modifica"]}
                        fieldsToPrint={["name", "fornitore", "price", "createdAt", "updatedAt"]}
                        data={magoItem.data}
                        size='small'
                        rowsPage={15}
                        rowsOptions={[]}
                        dataCurrentPage={magoItem.currentPage - 1}
                        dataCount={magoItem.totalItems}
                        showDetailsButton={false}
                        toFixedField
                        nextIconButtonHandler={fetchMagoNextPage}
                        backIconButtonHandler={fetchMagoBackPage}
                    />
                    : <p className="p-8 text-center text-sm font-medium text-text">Nessun oggetto Mago trovato...</p>
            }
        </>
    </GlobalPageTemplate>
}

const HeaderButton: React.FC = () => {
    return <div className="flex">
        <Link to='/monitoraggio/oggetti/create'>
            <CustomButton color={colorPalette.Accent} label='Crea Oggetto' />
        </Link>
    </div>
}

export default OrderView;