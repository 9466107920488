import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import { colorPalette } from "../utils/colorPalette";
import { IMonthlyMovement } from "../redux-store/mod-mago/magoType";
import BillingBox from "../components/BillingBox";
import { Billing } from "../enums/enumBilling";
import moment from "moment";

interface IProps {
    movement: IMonthlyMovement
};

const MonthlyMovement: React.FunctionComponent<IProps> = ({ movement }) => {

    const navigate = useNavigate();


    const showDetails = () => {
        // navigate to details page
        navigate(`/mago/movement/details/?id=${movement._id}`);
    };

    return <div
        className="flex w-full rounded-lg shadow-md p-4 mb-10 mt-4"
        style={{ backgroundColor: colorPalette.Background }}
    >
        <div className="flex justify-between w-full">
            <div className="flex flex-col justify-between h-full gap-4 w-full">
                <div className="flex flex-row justify-between w-full">
                    <div className="w-2/5">
                        <p className="text-lg font-semibold uppercase mb-2" style={{ color: colorPalette.Text }}>{"Resoconto " + moment(movement.startDate).format('MMMM YYYY')}</p>
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Movimenti: ${movement.totPerFornitore.length}`}</p>
                        <p className="text-sm font-medium" style={{ color: colorPalette.Text }}>{`Inizio: ${moment(movement.startDate).format("dddd DD MMMM")}`}</p>
                        <p className="text-sm font-medium" style={{ color: colorPalette.Text }}>{`Fine: ${moment(movement.endDate).format("dddd DD MMMM")}`}</p>
                    </div>

                    <div className="w-full flex  flex-col">
                        <p className="text-lg font-medium mb-2" style={{ color: colorPalette.Text }}>Fatturazione:</p>
                        <div className="flex gap-6">
                            <BillingBox billingValue={movement.totExpense} billingType={Billing.EXPENSE} />
                            <BillingBox billingValue={movement.totDiscount} billingType={Billing.DISCOUNT} />
                        </div>

                        <div className="flex flex-row mt-8 gap-4 justify-end">
                            <CustomButton label="Visualizza" color={colorPalette.Info} onClickHandler={showDetails} />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
};


export default MonthlyMovement;