
import { IReport } from "../../redux-store/mod-analitics/reportType";
import { createData } from "../../utils/createData";
import { endpoints } from "../../utils/endpoints";

export const createReport = async (newData: IReport) => {
    try {
        const data = await createData(endpoints.source + endpoints.createReport, newData);

        if (data instanceof Error || typeof data === "undefined") {
            throw new Error(data.message);
        }

        return data;
    } catch (error: any) {
        console.log("ERROR: ", error)
        return new Error(error);
    }
}