
import { Step, StepLabel, Stepper } from '@mui/material';
import { useState, useEffect } from 'react';
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { useSearchParams } from 'react-router-dom';
import { IReport } from '../redux-store/mod-analitics/reportType';
import { InitReport } from '../templates/createReport/InitReport';
import { AddExpenseReport } from '../templates/createReport/AddExpensesReport';
import { SubmitReport } from '../templates/createReport/SubmitReport';
import { useLocalStorage } from '../utils/useLocalStorage';

const createReportStep = ["Commessa", "Spese", "Riepilogo e Conferma"]

const initialReportObject: IReport = {
    commessaId: '',
    autorId: '',
    name: '',
    description: '',
    date: {
        startDate: new Date(),
        endDate: new Date()
    },
    credit: 0,
    expense: 0,
    profit: 0,
    notes: '',
    itemExpenses: []
}

const ManageReport: React.FunctionComponent = () => {
    const { getItemLocalStorage } = useLocalStorage();
    const user = getItemLocalStorage("user");

    const [activeStep, setActiveStep] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [newReport, setNewReport] = useState<IReport>({ ...initialReportObject, autorId: user._id })

    useEffect(() => {
        const stepParam = searchParams.get("step");
        if (newReport.commessaId === '' && Number(stepParam) !== 0) {
            setSearchParams(`?step=0`);
        } else {
            setActiveStep(Number(stepParam))
        }
    }, [searchParams]);

    const renderStep = () => {
        if (activeStep === 0) {
            return <InitReport newReport={newReport} setNewReport={setNewReport} />
        }
        if (activeStep === 1) {
            return <AddExpenseReport newReport={newReport} setNewReport={setNewReport} />
        }
        if (activeStep === 2) {
            return <SubmitReport newReport={newReport} />
        }
    }

    return <GlobalPageTemplate title="Creazione Report">
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {createReportStep.map((step) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                        <Step key={step} {...stepProps}>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}

            </Stepper>
            {renderStep()}
        </>
    </GlobalPageTemplate>
}


export default ManageReport;