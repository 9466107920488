import React from 'react';
import { SidebarDataAdmin, SidebarDataUser } from './SideBarData';
import { colorPalette } from '../utils/colorPalette';
import { NavLink } from 'react-router-dom';
import { useLocalStorage } from '../utils/useLocalStorage';
import { Button } from '@material-ui/core';

const activeLink = 'hover:bg-gray-300 pl-5 h-12 w-full flex justify-start items-center no-underline bg-gray-300 rounded-md';
const normalLink = 'hover:bg-gray-300 pl-5 h-12 w-full flex justify-start items-center no-underline rounded-md ';

interface IProps {
    close: boolean
};

const Sidebar: React.FC<IProps> = ({ close }) => {
    const { getItemLocalStorage, removeItemLocalStorage } = useLocalStorage();
    const user = getItemLocalStorage("user");

    const logOutHandler = () => {
        removeItemLocalStorage("user");
        window.location.href = '/';
    };

    const sidebarData = (user.role !== 'admin') ? SidebarDataUser : SidebarDataAdmin;

    return (
        <div className={`flex flex-col justify-between h-full overflow-y-scroll shadow-sm z-20 bg-background duration-200 `} style={{ maxWidth: (close) ? 0 : 220 }}>
            <div className='flex-1'>
                <img className="w-full p-6" src="/images/zecchini_logo_xs.png" alt="logo" />
                {(!close)
                    ? sidebarData.map((item, index) => {
                        return (
                            <div className='p-2' key={index}>
                                <p className='ml-4 mb-2 font-bold text-base text-left  text-text'>{item.title}</p>
                                {(item.subNav)
                                    ? item.subNav.map((item, index) => {
                                        return (
                                            <div key={index} className="flex justify-end pl-3 pr-3 pb-1 rounded-md">
                                                <NavLink
                                                    className={({ isActive }) =>
                                                        isActive ? activeLink : normalLink}
                                                    to={item?.path}>
                                                    {item.icon}
                                                    <p className='ml-4 font-medium text-sm' style={{ color: colorPalette.Text }} >{item.title}</p>
                                                </NavLink>
                                            </div>
                                        );
                                    })
                                    : null
                                }
                            </div>
                        )
                    })
                    : null}
            </div>
            <Button onClick={logOutHandler} style={{ backgroundColor: 'rgb(254, 202, 202)' }} className="w-36 flex self-center rounded-md cursor-pointer" >
                <p className="text-center text-error text-sm font-bold cursor-pointer">Log Out</p>
            </Button>
            <br />
        </div>
    )
}

export default Sidebar