import React, { useState } from 'react';
import { Snackbar, LinearProgress } from '@material-ui/core';
import { useAppSelector } from '../redux-store/store';
import * as FaIcons from 'react-icons/fa';

const UploadProgressSnackbar: React.FC = () => {
    const uploadProgress = useAppSelector(state => state.report.uploadProgress);

    const [isOpen, setIsOpen] = useState(true);

    const handleSnackbarClose = () => {
        setIsOpen(false);
    };

    const handleSnackbarOpen = () => {
        setIsOpen(true);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            key={'bottom - right'}
            open={Object.keys(uploadProgress).length > 0}
            autoHideDuration={null}
            onClose={handleSnackbarClose}
        >
            {
                isOpen
                    ? <div className="fixed bottom-8 left-2/3 right-8 bg-secondary rounded-lg shadow-lg p-4 max-h-56 overflow-auto">
                        {Object.entries(uploadProgress).map(([name, percent], index: number) => (
                            <React.Fragment key={index}>
                                <p className="text-text font-medium">{(name.length > 25) ? `${name.slice(0, 25)}...` : name}</p>
                                <div key={index} className="flex items-center gap-4">
                                    <LinearProgress
                                        variant="determinate"
                                        value={percent}
                                        className="flex-grow h-3"
                                        classes={{
                                            root: 'bg-gray-200',
                                            bar: 'bg-blue-500',
                                        }}
                                    />
                                    <p className="text-text font-medium">{percent}%</p>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    : <div className="fixed bottom-8 right-8 bg-secondary rounded-lg shadow-lg px-4 py-2" onClick={handleSnackbarOpen}>
                        <div className="flex items-center" >
                            <FaIcons.FaUpload />
                        </div>
                    </div>
            }
        </Snackbar>

    )
};

export default UploadProgressSnackbar;
