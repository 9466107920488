export const downloadCSV = (data: any, fileName: string) => {
    const csvData = arrayToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', `${fileName}.csv`);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    return { link: downloadLink, urlObject: url }
};

export const onClickDownload = (button: HTMLAnchorElement) => {
    button.click();
};

export function arrayToCSV(data: any) {
    let response: any[] = [];
    const array = Array.isArray(data) ? data : [data];

    const header = Object.keys(array[0]).join(" ");

    const rows = array.map((obj: any) => Object.values(obj).map((val: any) => {
        if (val.length) {
            return null
        }
        if (typeof val === 'object') {
            response.push(arrayToCSV(val));
        } else {
            return val;
        }
    }).join(";"));

    return [header, ...rows].join('\r\n') + response?.map((csv) => { return "\n\n" + csv });

};