import { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { colorPalette } from '../utils/colorPalette';
import CustomButton from './CustomButton';
import moment from 'moment';

interface IProps {
    disablePagination?: boolean,
    rowsPage?: number,
    rowsOptions?: number[],
    defaultSortField: string | number | symbol,
    defaultSortDirection?: 'asc' | 'desc',
    size?: "small" | "medium" | undefined,
    headerFields: string[],
    data: { [key: string]: any }[],
    showDetailsButton?: boolean,
    redirectPath?: string,
    fieldsToPrint?: string[],
    customElement?: React.ReactNode,
    actionUpdate?: (arg0?: string) => React.ReactNode,
    dataCount?: number,
    dataCurrentPage?: number,
    toFixedField?: boolean,
    nextIconButtonHandler?: () => void,
    backIconButtonHandler?: () => void
};


/**
 * React FunctionComponent for rendering a table with pagination and sorting functionality
 * @param {IProps} param - Component props
 * @returns {JSX.Element} - Rendered component
 */
export const DataTable: React.FunctionComponent<IProps> = ({
    disablePagination = false,
    rowsPage = 10,
    rowsOptions = [5, 10, 25],
    defaultSortField,
    defaultSortDirection = 'asc',
    size = 'medium',
    headerFields,
    data,
    showDetailsButton = true,
    redirectPath = '',
    fieldsToPrint = [],
    customElement,
    actionUpdate,
    dataCount = data.length,
    dataCurrentPage,
    toFixedField = false,
    nextIconButtonHandler,
    backIconButtonHandler
}: IProps): JSX.Element => {

    // Type alias for sorting direction
    type Order = 'asc' | 'desc';

    // State variables for sorting and pagination
    const [orderDirection, setOrderDirection] = useState<Order>(defaultSortDirection);
    const [valueToOrderBy, setValueToOrderBy] = useState(defaultSortField);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPage);

    // {**
    //    Logic for sorting data

    /**
    * Compares two values in descending order.
    * @template T
    * @param {T} a The first value.
    * @param {T} b The second value.
    * @param {keyof T} orderBy The property to sort by.
    * @returns {number} -1 if a < b, 1 if a > b, 0 if a == b.
    */
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    /**
     * Gets a comparator function for the specified order and property.
     * @template Key
     * @param {Order} order The order to sort in.
     * @param {Key} orderBy The property to sort by.
     * @returns {(a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number} A comparator function.
     */
    function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)

    }

    /**
    * Sorts an array of objects based on a given comparator function.
    * @template T - Type of the elements in the array.
    * @param {T[]} rowArray - The array of elements to sort.
    * @param {(a: T, b: T) => number} comparator - The comparator function to use for sorting.
    * @returns {T[]} - A new array of sorted elements.
    */
    function sortedRowInformation<T>(rowArray: T[], comparator: (a: T, b: T) => number) {
        const stabilizedRowArray = rowArray.map((el, index) => [el, index] as [T, number]);
        stabilizedRowArray.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        })
        return stabilizedRowArray.map((el) => {
            return el[0]
        })
    }

    /**
    * Handle request sort function.
    * @param {React.MouseEvent<unknown>} event - The mouse event.
    * @param {keyof any} property - The property to sort by.
    * @returns {void}
    */
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
        setOrderDirection(isAscending ? 'desc' : 'asc')
        setValueToOrderBy(property);
    }

    const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    }
    //
    // **}

    // {**
    //    Logic for pagination
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    //
    // **}


    /**
     * Renders formatted data from an object, based on the specified fields to print.
     * Format date using regex and moment.
     * @param {Object} object - The object to format.
     * @param {Array} fieldsToPrint - The array of fields to print, which can include nested properties separated by dots.
     * @returns {Array} - An array of table cells, containing the formatted data for each specified field.
     */
    const renderFormattedData = (object: { [key: string]: any }, fieldsToPrint: Array<string>): Array<any> => {
        const formattedData = formatter(object, fieldsToPrint);
        const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

        return Object.values(formattedData).map((item: any, index: number) => {
            const response = regex.test(item);
            return <TableCell key={index} align={(index === 0) ? 'left' : 'right'} component="th" scope="row" >
                {
                    (response)
                        ? <p>{moment(item).format('YYYY-MM-DD hh:mm:ss')}</p>
                        : (toFixedField && typeof item === 'number')
                            ? <p>{item.toFixed(2)}</p>
                            : <p>{item}</p>
                }
            </TableCell >
        })
    };

    /**
     * Formats an object to include only specified fields and their nested properties.
     *
     * @param {Object} obj - The object to format.
     * @param {Array} fieldsToPrint - An array of strings representing the fields to include in the formatted object.
     * @returns {Object} - The formatted object with only the specified fields and their nested properties.
     */
    const formatter = (obj: { [key: string]: any }, fieldsToPrint: Array<string>): object => {
        return fieldsToPrint.reduce((acc, curr) => {
            const properties = curr.split('.'); // split the property by dot to access nested properties
            let value = obj;
            for (const property of properties) {
                value = value?.[property]; // use optional chaining operator to access nested properties
            }
            acc[curr] = value;
            return acc;
        }, {} as { [key: string]: any })
    };

    return (
        <>
            {(disablePagination) ? null : <TablePagination
                labelRowsPerPage='Elementi visualizzati:'
                rowsPerPageOptions={rowsOptions}
                component="div"
                count={(dataCount) ? dataCount : data.length}
                rowsPerPage={rowsPerPage}
                page={(dataCurrentPage) ? dataCurrentPage : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={(nextIconButtonHandler) ? { onClick: nextIconButtonHandler } : null}
                backIconButtonProps={(backIconButtonHandler) ? { onClick: backIconButtonHandler, disabled: false } : null}
            />}
            <TableContainer sx={{ maxWidth: '100%', borderRadius: 3 }} component={Paper} >
                <Table size={size} sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#fff' }}>
                            {
                                headerFields.map((item, index) => {
                                    return <TableCell key={index} className="capitalize" align={(index === 0) ? 'left' : 'right'} sx={{ color: '#000', fontSize: 16 }}>
                                        <TableSortLabel
                                            active={fieldsToPrint[index] === valueToOrderBy}
                                            direction={fieldsToPrint[index] === (valueToOrderBy) ? orderDirection : 'asc'}
                                            onClick={createSortHandler(fieldsToPrint[index])}
                                        >
                                            {item}
                                        </TableSortLabel>
                                    </TableCell>
                                })
                            }

                            {
                                (showDetailsButton)
                                    ? <TableCell align={'right'} component="th" scope="row" sx={{ color: '#000', fontSize: 16 }}>
                                        <div>Dettagli</div>
                                    </TableCell>

                                    : null
                            }

                            {
                                (customElement)
                                    ? <TableCell align={'right'} component="th" scope="row" sx={{ color: '#000', fontSize: 16 }}>
                                        <div>Azioni</div>
                                    </TableCell>

                                    : null
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedRowInformation(data, getComparator(orderDirection, valueToOrderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((asset, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: '#F8F8F8' } }}
                                    >
                                        {renderFormattedData(asset, fieldsToPrint)}
                                        {
                                            (showDetailsButton)
                                                ? <TableCell align={'right'} component="th" scope="row">
                                                    <Link to={(typeof asset.id !== 'undefined')
                                                        ? `${redirectPath}${asset.id}`
                                                        : (typeof asset._id !== 'undefined')
                                                            ? `${redirectPath}${asset._id}`
                                                            : `${redirectPath}${asset.Id}`
                                                    }>
                                                        <CustomButton color={colorPalette.Info} label='Info' />
                                                    </Link>
                                                </TableCell>

                                                : null
                                        }

                                        {
                                            (customElement)
                                                ? <TableCell align={'right'} component="th" scope="row">
                                                    {customElement}
                                                </TableCell>

                                                : null
                                        }
                                        {
                                            (actionUpdate)
                                                ? <TableCell align={'right'} component="th" scope="row">
                                                    {actionUpdate(
                                                        (typeof asset.id !== 'undefined')
                                                            ? asset.id
                                                            : (typeof asset._id !== 'undefined')
                                                                ? asset._id
                                                                : asset.Id
                                                    )}
                                                </TableCell>

                                                : null
                                        }

                                    </TableRow>
                                )
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}