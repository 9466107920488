import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Chip } from '@mui/material';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import AsyncLoader from '../components/AsyncLoader';
import ReportTable from '../components/ReportTable';
import CustomButton from '../components/CustomButton';
import { DataTable } from '../components/DataTable';
import BillingBox from '../components/BillingBox';
import { Billing } from '../enums/enumBilling';
import FeedbackModal from '../utils/FeedbackModal';
import { colorPalette } from '../utils/colorPalette';
import { downloadCSV, onClickDownload } from '../utils/downloadCSV';
import { useAppDispatch } from '../redux-store/store';
import { IOrder, IReport, ICategoryExpenses } from '../redux-store/mod-analitics/reportType';
import { getOrderById } from '../redux-store/mod-analitics/actions/getOrderById';
import { getReportById } from '../redux-store/mod-analitics/actions/getReportById';
import { setSnackbar } from '../redux-store/uiManager/actions/setSnackbar';
import { fetchOrders } from '../redux-store/mod-analitics/actions/fetchOrders';
import { deleteOrder } from '../api/mod-analitics/deleteOrder';
import { updateOrder } from '../api/mod-analitics/updateOrder';


const HEADER_FIELDS = ["Nome", "Spese", "Data Inizio", "Data Fine", "Note"];
const fieldsToPrint = ["name", "expense", "date.startDate", "date.endDate", "notes"];

const OrderDetails: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const [modal, setModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [order, setOrder] = useState<IOrder>(undefined);

    const [reports, setReports] = useState<IReport[]>(undefined);
    const [unifiedReport, setUnifiedReport] = useState<IReport>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [downloadLink, setDownloadLink] = useState<HTMLAnchorElement>(undefined);
    const [objectUrl, setObjectUrl] = useState<string>(undefined);

    useEffect(() => {
        const id = searchParams.get("id");
        getOrder(id);

        return () => {
            // clean memory on unmount
            if (typeof objectUrl !== 'undefined') {
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, []);

    useEffect(() => {
        if (typeof unifiedReport !== 'undefined' && unifiedReport?.itemExpenses.length > 0) {
            const linkElement = downloadCSV(unifiedReport, "report_" + unifiedReport.name)
            setDownloadLink(linkElement.link);
            setObjectUrl(linkElement.urlObject);

            setIsLoading(false);
        }
    }, [unifiedReport])

    const getOrder = async (id: string) => {
        const response = await getOrderById(id);
        setOrder(response)

        // return linked reports data
        const fetchedReports = await retrieveLinkedReportsData(response.reports);

        // unify reports for data visualization
        const unifiedReport = unifyReports(fetchedReports);
        setUnifiedReport(unifiedReport);
    };

    const retrieveLinkedReportsData = async (reportsId: string[]) => {
        const reports: IReport[] = [];

        for (const reportId of reportsId) {
            const report = await getReportById(reportId);
            reports.push(report);
        }

        setReports(reports);

        return reports;
    }


    const unifyReports = (reportsToUnify: IReport[]) => {
        let unifiedReport: IReport = {
            name: "ReportUnificato",
            autorId: "Zecchini",
            commessaId: searchParams.get("id"),
            itemExpenses: []
        }


        // retrieve all unique itemExpenses name fields values like "Carburante", "Manodopera", "Materiale", etc...
        // by mapping the array of reports and then creating an array of unique values
        // without duplicates and without Set() because it's not supported by IE11
        const uniqueItemExpensesNameFields = reportsToUnify
            .map(report => report.itemExpenses.map(itemExpense => itemExpense.name))
            .flat()
            .filter((value, index, self) => self.indexOf(value) === index);

        // create a new itemExpenses array in the unified report
        // by mapping the array of unique itemExpenses name fields
        unifiedReport.itemExpenses = []
        for (const name of uniqueItemExpensesNameFields) {
            const newCategoryExpense: ICategoryExpenses = {
                name: name,
                expenses: []
            }

            unifiedReport.itemExpenses.push(newCategoryExpense)
        }

        // add all the expenses of each report to the unified report
        for (const report of reportsToUnify) {
            for (const itemExpense of report.itemExpenses) {
                const categoryExpense = unifiedReport.itemExpenses.find(categoryExpense => categoryExpense.name === itemExpense.name);
                categoryExpense.expenses.push(...itemExpense.expenses);
            }
        }

        // sort all the itemExpenses ICategoriesExpenses expenses by date in the unified report
        for (const itemExpense of unifiedReport.itemExpenses) {
            itemExpense.expenses.sort((a, b) => {
                // if date is undefined return 
                if (!a.date || !b.date) {
                    return 0;
                }

                // sort by date in ascending order
                const formattedInitialDate = moment(a.date, 'DD/MM/YYYY').toDate();
                const formattedFinalDate = moment(b.date, 'DD/MM/YYYY').toDate();

                return formattedInitialDate.getTime() - formattedFinalDate.getTime();
            })
        }

        return unifiedReport;
    };

    const exportToCsv = () => {
        onClickDownload(downloadLink);
    };

    const deleteOrderHandler = async () => {
        // call delete order api
        const response: any = await deleteOrder(order?._id);

        // check if the response is ok
        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante l'eliminazione della commessa", "error"))
            return;
        }

        // navigate to orders list
        navigate("/monitoraggio/commesse");
    };

    /**
     * This function create an IOrder object with the status updated,
     * and pass to another function that will call the query to update the status. 
     */
    const updateStatusHandler = async () => {
        if (order)
            try {
                const newOrder: IOrder = { ...order, status: (order?.status === 'Completata') ? 'In corso' : 'Completata' };

                await updateOrderHandler(newOrder, order?._id);
                setStatusModal(false);
            } catch (error) {
                console.log("ERROR updating status order: " + error)
            }
    };

    /**
     * This query get in input an IOrder object and the id of that order,
     * call the query to update the order and set a snackbar for the user feedback as 'Pending response', 'Error reponse' or 'Success response'.
     * 
     * @param order An IOrder object that rappresent an Order
     * @param id The Id of that order
     */
    const updateOrderHandler = async (order: IOrder, id: string) => {
        dispatch(setSnackbar("Aggiornamento stato commessa in corso...", "info"));
        const response = await updateOrder(order, id);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante l'aggiornamento dello stato commessa", "error"));
            return;
        }

        await dispatch(fetchOrders());
        dispatch(setSnackbar("Stato commessa aggiornata con successo!", "success"));
        getOrder(id);
    };

    const updateOrderRedirect = () => {
        // navigate to update page
        navigate(`/monitoraggio/commesse/update/?id=${order?._id}`);
    };

    const headerActions = (
        <div className="flex gap-2">
            <div className='px-4'>
                <p className='text-center text-sm font-semibold text-text'>Modifica</p>
                <CustomButton label="Dati" color={colorPalette.Info} onClickHandler={updateOrderRedirect} />
                <CustomButton label="Stato" color={colorPalette.Info} onClickHandler={() => setStatusModal(true)} />
            </div>
            <div className='px-4'>
                <p className='text-center text-sm font-semibold text-text'>Azioni</p>
                <CustomButton label="Export CSV" color={colorPalette.Accent} onClickHandler={exportToCsv} />
                <CustomButton label="Elimina" color={colorPalette.Error} onClickHandler={() => setModal(true)} />
            </div>
        </div>
    );


    return (
        <GlobalPageTemplate title={`Commessa: ${order?.name}`} headerElement={headerActions}>
            <>
                <div className='flex flex-row align-center'>
                    <p className="font-medium text-xl mb-4">Dettagli Commessa</p>
                    <Chip className="ml-4 font-medium" label={`${order?.status}`} color={(order?.status === 'Completata') ? 'success' : 'warning'}></Chip>
                </div>
                <AsyncLoader condition={typeof order !== 'undefined'} height={300}>
                    <div >
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Codice RDC: ${order?.rdcCode}`}</p>
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Numero report caricati: ${order?.reports.length}`}</p>
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Data creazione commessa: ${moment(order?.date).format('DD MMMM YYYY')}`}</p>
                    </div>
                </AsyncLoader>

                <div className="flex gap-6 my-8">
                    <BillingBox billingValue={order?.expense} billingType={Billing.EXPENSE} />
                    <BillingBox billingValue={order?.credit} billingType={Billing.CREDIT} />
                    <BillingBox billingValue={order?.profit} billingType={Billing.PROFIT} />
                </div>

                <p className='font-medium text-xl'>Lista Report Collegati</p>
                <AsyncLoader condition={reports?.length > 0} height={300}>
                    <DataTable
                        defaultSortField={HEADER_FIELDS[0]}
                        headerFields={HEADER_FIELDS}
                        data={reports}
                        rowsOptions={[10, 25, 50]}
                        showDetailsButton={true}
                        size='small'
                        fieldsToPrint={fieldsToPrint}
                        redirectPath="/monitoraggio/report/details?id="
                    />
                </AsyncLoader>

                <p className="font-medium text-xl my-8">Lista Spese Commessa (da report collegati)</p>
                <AsyncLoader condition={isLoading === false} height={500}>
                    <ReportTable newReport={unifiedReport} />
                </AsyncLoader>
                <FeedbackModal isOpen={modal} setIsOpen={setModal} navigateBack={false} title='Sicuro di volerla eliminare?' desc='Eliminando questa commessa non potrai piu recuperarla!' standardButton={{ onClick: deleteOrderHandler, text: 'Elimina' }} />
                <FeedbackModal isOpen={statusModal} setIsOpen={setStatusModal} navigateBack={false} title='Stato della commessa' desc='Cambia lo stato come:' standardButton={{ onClick: updateStatusHandler, text: (order?.status === 'Completata') ? 'In corso' : ' Completata' }} />
            </>
        </GlobalPageTemplate>
    )
}

export default OrderDetails
