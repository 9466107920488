import moment from "moment";
import { Dispatch } from "redux";
import { getChartLabels } from "../../../utils/getChartLabels";
import { replaceFuelDataLabels } from "../reportSlice";

/**
 * Fetches fuel data from the server between the given start and end dates.
 * If no start or end date is provided, defaults to the start of the current month
 * and the current date, respectively.
 * 
 * @param {string} [startDate] The start date in YYYY-MM-DD format (default: start of current month)
 * @param {string} [endDate] The end date in YYYY-MM-DD format (default: current date)
 * 
 * @returns {Function} An async function that dispatches the fetched fuel data to the store
 */
export const getFuelDataLabels = (
    startDate: string = moment().startOf("month").format("YYYY-MM-DD"),
    endDate: string = moment().format("YYYY-MM-DD")
) => {
    return (dispatch: Dispatch) => {
        try {
            const fuelDataLabels = getChartLabels({ startDate: startDate, endDate: endDate });
            dispatch(
                replaceFuelDataLabels({
                    fuelDataLabels: fuelDataLabels
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching orders: ", error)
        }
    }
}