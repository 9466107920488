import moment from "moment";
import 'moment/locale/it'; // import the Italian locale

moment.locale('it'); // Set locale to Italian

export interface IDateLabel {
    dateLabels: {
        days?: string[];
        months?: string[];
        years?: string[];
    }
};



/**
 * Converts start and end dates in string format (YYYY-MM-DD) to JavaScript Date objects.
 * 
 * @param startDate The start date in string format (YYYY-MM-DD).
 * @param endDate The end date in string format (YYYY-MM-DD).
 * 
 * @returns An object containing the start and end dates as JavaScript Date objects.
 */
export const handleFormatDates = (startDate: string, endDate: string) => {

    const newDates = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
    };

    return newDates;
};




/**
 * Returns an object containing labels for the chart's x-axis, based on the provided start and end dates.
 * If the date range is less than or equal to `daysLimit`, day, month, and year labels are returned.
 * If the date range is less than or equal to `monthsLimit`, month and year labels are returned.
 * Otherwise, only year labels are returned.
 * 
 * @param date An object containing the start and end dates in string format (YYYY-MM-DD).
 * @param daysLimit The maximum number of days for which day, month, and year labels will be returned.
 * @param monthsLimit The maximum number of months for which month and year labels will be returned.
 * 
 * @returns An object containing arrays of day, month, and year labels.
 */
export const getChartLabels = (date: { startDate: string, endDate: string }, daysLimit = 31, monthsLimit = 12): IDateLabel => {

    // Format the dates as JavaScript Date objects using the handleFormatDates function.
    const formattedDate = handleFormatDates(date.startDate, date.endDate);

    // Calculate the difference between the start and end dates in days and months.
    const diffInDays: number = moment(formattedDate.endDate).diff(moment(formattedDate.startDate), 'days');
    const diffInMonths: number = moment(formattedDate.endDate).diff(moment(formattedDate.startDate), 'months');

    // If the date range is less than or equal to daysLimit, return day, month, and year labels.
    if (diffInDays <= daysLimit) {
        return {
            dateLabels: {
                days: getDayLabels(formattedDate.startDate, formattedDate.endDate),
                months: getMonthLabels(formattedDate.startDate, formattedDate.endDate),
                years: getYearLabels(formattedDate.startDate, formattedDate.endDate)
            }
        };

    }
    // If the date range is less than or equal to monthsLimit, return month and year labels.
    else if (diffInMonths <= monthsLimit) {
        return {
            dateLabels: {
                days: [],
                months: getMonthLabels(formattedDate.startDate, formattedDate.endDate),
                years: getYearLabels(formattedDate.startDate, formattedDate.endDate)
            }
        };

    }

    // Otherwise, return only year labels.
    return { dateLabels: { days: [], months: [], years: getYearLabels(formattedDate.startDate, formattedDate.endDate) } };
};



/**
 * This function generates an array of day labels between the provided start and end dates.
 * The labels are generated in the format "DD MMMM", where "DD" is the day number, and "MMMM" is the full month name(e.g. 01 Gennaio).
 *
 * @param startDate The start date for which to generate labels
 * @param endDate The end date for which to generate labels
 *
 * @returns An array of day labels
 */
const getDayLabels = (startDate: Date, endDate: Date) => {
    const initialDate = moment(startDate);
    const dayNames: string[] = [];
    while (initialDate.isSameOrBefore(endDate)) {
        dayNames.push(initialDate.format('DD MMMM'));
        initialDate.add(1, 'day');
    };
    return dayNames;
};



/**
 * This function generates an array of month labels between the provided start and end dates.
 * The labels are generated in the format "MMMM Y", where "MMMM" is the full month name (e.g. Gennaio), and "Y" is the four-digit year.
 *
 * @param startDate The start date for which to generate labels
 * @param endDate The end date for which to generate labels
 *
 * @returns An array of month labels
 */
const getMonthLabels = (startDate: Date, endDate: Date) => {
    const initialDate = moment(startDate);
    const monthNames: string[] = [];
    while (initialDate.isSameOrBefore(endDate)) {
        monthNames.push(initialDate.format('MMMM Y'));
        initialDate.add(1, 'month');
    };
    return monthNames;
};



/**
 * This function generates an array of year labels between the provided start and end dates.
 * The labels are generated in the format "Y", where "Y" is the four-digit year.
 *
 * @param startDate The start date for which to generate labels
 * @param endDate The end date for which to generate labels
 *
 * @returns An array of year labels
 */
const getYearLabels = (startDate: Date, endDate: Date) => {
    const initialDate = moment(startDate);
    const years: string[] = [];
    while (initialDate.isSameOrBefore(endDate)) {
        years.push(initialDate.format('Y'));
        initialDate.add(1, 'year');
    };
    return years
};



/**
 * Returns an object with an array of the days of the current month formatted as 'DD MMM'.
 * The returned object has an empty array for the 'months' and 'years' properties.
 * @returns {IDateLabel} Object with an array of the days of the current month.
 */
export const getDaysCurrentMonth = (): IDateLabel => {
    const currentMonth = moment().month();
    const daysInMonth = moment().daysInMonth();
    const daysArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
        const date = moment().month(currentMonth).date(i);
        daysArray.push(date.format('DD MMM'));
    }
    return {
        dateLabels: {
            days: daysArray,
            months: [],
            years: []
        }
    }
};