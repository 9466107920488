export enum colorPalette {
    Primary = "#595758",
    Secondary = "#B7D1DA",
    Accent = "#3E5C76",
    Background = "#FEFCFD",
    Border = "#97DB4F",
    Text = "#333333",
    Success = "#67C23A",
    Info = "#409EFF",
    Warning = "#E6A23C",
    Error = "#F56C6C",
    DarkGray = "#444444",
    MediumGray = "#C0C0C0",
    LightGray = "#F2F2F2"
}

// "Primary" (#595758): A dark, neutral gray used as the primary color for the dashboard. This color is versatile and pairs well with many other colors.

// "Secondary" (#B7D1DA):  Pale blue-green color that adds a sense of calm and complements the primary color. Works well as a background for the sidebar.

// "Accent" (#3E5C76): Deep blue color that adds depth and stability. Works well as an accent color for buttons or highlights in your design

// "Background" (#FEFCFD): A light, off-white color that can be used as the background color for the dashboard.

// "Border" (#97DB4F): A bright, light green that can be used as a border color for dashboard elements.

// "Text" (#333333): A dark, neutral gray that can be used as the text color for the dashboard.

// "Success" (#67C23A): A bright, saturated green that can be used to indicate success or positive outcomes.

// "Info" (#409EFF): A bright, saturated blue that can be used to convey information or provide context.

// "Warning" (#E6A23C): A warm, muted yellow that can be used to indicate caution or warn about potential issues.

// "Error" (#F56C6C): A bright, saturated red that can be used to indicate errors or negative outcomes.

// "DarkGray" (#444444): A very dark, neutral gray that can be used as an accent or complementary color.

// "LightGray" (#D3D3D3): A light, neutral gray that can be used as an alternative to white or as a background color.