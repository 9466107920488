import React, { useEffect } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import Order from "../templates/Order";
import CustomButton from "../components/CustomButton";
import { colorPalette } from "../utils/colorPalette";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import { fetchOrders } from "../redux-store/mod-analitics/actions/fetchOrders";
import { Link } from "react-router-dom";
import AsyncLoader from "../components/AsyncLoader";
import moment from "moment";

const OrdersView: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const orders = useAppSelector(state => state.report.orders);

    useEffect(() => {
        dispatch(fetchOrders());
    }, [dispatch]);

    return <GlobalPageTemplate headerElement={<HeaderButton />} title='Commesse'>
        <>
            {/* ALL ORDERS */}
            {(orders?.length > 0)
                ? orders?.map((order, index) => {
                    return <Order
                        key={index}
                        RDC={order.rdcCode}
                        expense={order.expense}
                        profit={order.profit}
                        title={order.name}
                        credit={order.credit}
                        status={order.status}
                        id={order._id}
                        reports={order.reports}
                        date={moment(order.date).format('DD/MM/YYYY')}
                        order={order}
                    />
                })
                : <p className="p-8 text-center text-sm font-medium text-text">Nessuna commessa trovata...</p>
            }
        </>
    </GlobalPageTemplate>
}

const HeaderButton: React.FC = () => {
    return <Link to='/monitoraggio/commesse/create'>
        <CustomButton color={colorPalette.Accent} label='Nuova Commessa' />
    </Link>
}

export default OrdersView;