import React, { useEffect, useState } from "react";
import { colorPalette } from "../utils/colorPalette";
import { useSearchParams } from "react-router-dom";
import { IMonthlyMovement } from "../redux-store/mod-mago/magoType";
import { getMonthlyMovementById } from "../api/mod-mago/getMonthlyMovementById";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import moment from "moment";
import AsyncLoader from "../components/AsyncLoader";
import BillingBox from "../components/BillingBox";
import { Billing } from "../enums/enumBilling";
import { DataTable } from "../components/DataTable";

const HEADER_FIELDS = ["Fornitore", "Totale Spesa", "Totale Sconto"];
const fieldsToPrint = ["job", "totalAmount", "totalDiscount"];


const MagoMovementDetails: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [movement, setMovement] = useState<IMonthlyMovement>(undefined);

    useEffect(() => {
        const id = searchParams.get("id");
        getMovement(id);
    }, []);

    const getMovement = async (id: string) => {
        const response = await getMonthlyMovementById(id);
        setMovement(response);
    };

    return <GlobalPageTemplate title={`Movimenti ${moment(movement?.startDate).format("MMMM YY").toUpperCase()}`}>
        <AsyncLoader condition={typeof movement !== 'undefined'} height={400}>
            <>

                <p className='font-medium text-xl mb-4'>Dettagli movimenti</p>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Inizio: ${moment(movement?.startDate).format("dddd DD MMMM")}`}</p>
                        <p className="text-md font-medium" style={{ color: colorPalette.Text }}>{`Fine: ${moment(movement?.endDate).format("dddd DD MMMM")}`}</p>
                    </div>
                    <p className="text-lg font-semibold" style={{ color: colorPalette.Text }}>{`Fatture: ${movement?.totPerFornitore.length}`}</p>
                </div>

                <div className='w-full flex justify-center gap-2 my-4'>
                    <BillingBox billingValue={Number(movement?.totExpense.toFixed(2))} billingType={Billing.EXPENSE} />
                    <BillingBox billingValue={Number(movement?.totDiscount.toFixed(2))} billingType={Billing.DISCOUNT} />
                </div>
                <p className='font-medium text-xl mb-4'>Lista totale per fornitore</p>
                <DataTable
                    headerFields={HEADER_FIELDS}
                    fieldsToPrint={fieldsToPrint}
                    data={movement?.totPerFornitore}
                    rowsPage={movement?.totPerFornitore.length}
                    disablePagination
                    defaultSortField={'totalAmount'}
                    defaultSortDirection="desc"
                    showDetailsButton={false}
                    size='small'
                    toFixedField
                />
            </>
        </AsyncLoader>
    </GlobalPageTemplate>
    // <Box sx={{ padding: 4, width: '100%' }}>
    //     <h1 className="font-bold text-3xl pb-4">Dettagli Fattura</h1>
    //     
    //     {
    //         (bill)
    //             ? <DataTable defaultSortField={HEADER_FIELDS[0]} headerFields={HEADER_FIELDS} data={bill} rowsPage={bill.length} rowsOptions={[]} showDetailsButton={false} size='small' />
    //             : null
    //     }
    // </Box>

}

export default MagoMovementDetails;