import Skeleton from '@mui/material/Skeleton';

interface IProps {
    condition: boolean;
    children: React.ReactNode;
    height: number | string;
};

const AsyncLoader: React.FunctionComponent<IProps> = ({ condition, children, height }) => {

    if (!condition) {
        return <Skeleton variant="rectangular" sx={{ width: '100%', height: height, marginTop: 2, borderRadius: 2 }} />
    }

    return <>
        {children}
    </>
};

export default AsyncLoader;