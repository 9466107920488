import { IUser } from "../redux-store/auth/authType";

export const fetchData = async (url: string, body?: { [key: string]: any }, REST = 'GET') => {

  const value = localStorage.getItem('user');
  const user: IUser = JSON.parse(value)

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Access-Control-Allow-Origin', '*');
  requestHeaders.set('Content-Type', 'application/json');
  if (user !== null) {
    requestHeaders.append("Authorization", "Bearer " + user.accessToken);
  }

  try {
    const response = await fetch(url, {
      method: REST,
      headers: requestHeaders,
      body: JSON.stringify(body)
    });

    const data = await response.json();
    return data

  } catch (error: any) {
    console.log("ERROR:", error)
    return new Error(error);
  }

};
