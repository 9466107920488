import { ItemTemplate } from "../../redux-store/mod-analitics/reportType";
import { endpoints } from "../../utils/endpoints";
import { updateData } from "../../utils/updateData";

export const updateItemTemplate = async (newData: ItemTemplate, id: string) => {
    try {
        const data = await updateData(endpoints.source + endpoints.updateItemTemplate + id, newData);

        if (data instanceof Error || typeof data === "undefined") {
            throw new Error(data.message);
        }

        return data;
    } catch (error: any) {
        console.log("ERROR: ", error)
        return new Error(error);
    }
}