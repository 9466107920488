import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import moment from "moment";
import { useAppDispatch } from '../redux-store/store';
import { fetchFuelData } from '../redux-store/mod-analitics/actions/fetchFuelData';
import { getFuelDataLabels } from '../redux-store/mod-analitics/actions/getFuelDataLabels';

moment.locale('it');

type labelType = string[];

interface IProps {
  anchorEl: SVGElement | HTMLButtonElement;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  year?: number
}


const MenuListContainer: React.FC<IProps> = ({ anchorEl, isOpen, setIsOpen, year }) => {
  const dispatch = useAppDispatch();
  const [labels, setLabels] = useState<string[]>()

  useEffect(() => {
    const months = getOptionNames(year);
    setLabels(months);
  }, [year])


  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleFilterDataByMonth = (month: number) => {
    const startOfMonth = moment().year(year).month(month).startOf('month').format("YYYY-MM-DD");
    const endOfMonth = moment().year(year).month(month).endOf('month').format("YYYY-MM-DD");

    setIsOpen(false);
    dispatch(fetchFuelData(startOfMonth, endOfMonth));
    dispatch(getFuelDataLabels(startOfMonth, endOfMonth));

  };

  const getOptionNames = (year: number): labelType => {
    const currentDate = moment();
    const isCurrentYear = (year === currentDate.year());

    const months = moment.months();

    if (isCurrentYear) {
      const currentMonthIndex = currentDate.month();
      return months.slice(0, currentMonthIndex + 1);
    } else {
      return months;
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 200,
        },
      }}
    >
      {labels?.map((label, index) => {
        return <MenuItem key={index} onClick={() => handleFilterDataByMonth(index)}>{label}</MenuItem>
      })}
    </Menu>
  )
}

export default MenuListContainer