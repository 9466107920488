import { useEffect, useState } from 'react';
import BarChart from "../components/BarChart";
import { CardContainer } from "../components/CardContainer";
import CustomButton from '../components/CustomButton';
import MenuListContainer from '../components/MenuListContainer';
import { IFuelData } from "../redux-store/mod-analitics/reportType";
import { calculateValue, filterDataByValue } from '../utils/analyticsCalculator';
import { colorPalette } from '../utils/colorPalette';
import { IDateLabel } from "../utils/getChartLabels";
import AsyncLoader from '../components/AsyncLoader';
import AutoComplete from '../components/AutoComplete';
import { searchMezziTarga } from '../api/mod-analitics/searchMezziTarga';
import { searchAutCodes } from '../api/mod-analitics/searchAutCodes';

interface IProps {
    data: IFuelData[];
    labels: IDateLabel;
    year: number
};

type labelsType = 'GG' | 'MM' | 'AA';

const Fuel: React.FunctionComponent<IProps> = ({ data, labels, year }) => {
    const [filteredLabels, setFilteredLabels] = useState<string[]>(labels.dateLabels.days);
    const [fuelData, setFuelData] = useState<number[]>();
    const [filteredByVehicles, setFilteredByVehicles] = useState<number[]>()
    const [filteredByDriver, setFilteredByDriver] = useState<number[]>()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [menuList, setMenuList] = useState<boolean>(false);
    const [autSelected, setAutSelected] = useState<Record<string, string>>({
        Desc: '',
        Code: '',
        Id: ''
    });
    const [veicSelected, setVeicSelected] = useState({
        Desc: '',
        Code: '',
        Targa: ''
    });
    // const [viagSelected, setViagSelected] = useState({
    //     Code: ''
    // });

    /**
    * Sets the filtered labels based on the selected type (days, months, or years) and the available date labels.
    * 
    * @param type - The selected type of label to display (GG for days, MM for months, AA for years).
    */
    const setLabels = (type: labelsType) => {
        if (type === 'GG' && labels.dateLabels.days?.length > 0) {
            setFilteredLabels(labels.dateLabels.days);
        } else if (type === 'MM' && labels.dateLabels.months?.length > 0) {
            setFilteredLabels(labels.dateLabels.months);
        } else if (type === 'AA' && labels.dateLabels.years?.length > 0) {
            setFilteredLabels(labels.dateLabels.years);
        };
    };

    useEffect(() => {
        if (labels.dateLabels.days?.length > 0) {
            setLabels('GG');
        } else if (labels.dateLabels.months?.length > 0) {
            setLabels('MM');
        } else if (labels.dateLabels.years?.length > 0) {
            setLabels('AA');
        };
        const filteredData = calculateValue(data);
        setFuelData(filteredData);
    }, [labels, data]);

    useEffect(() => {
        if (autSelected.Code.length !== 0) {
            const response = filterDataByValue(data, autSelected.Code, 'totPerAutista');
            setFilteredByDriver(response);
        };
        if (veicSelected.Code.length !== 0) {
            const response = filterDataByValue(data, veicSelected.Code, 'totPerMezzo');
            setFilteredByVehicles(response);
        };
    }, [veicSelected, autSelected, data]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuList(true)
        setAnchorEl(event.currentTarget);
    };

    return <div className="flex flex-col gap-2">
        <div className="flex w-1/2 self-center">

            <AsyncLoader condition={(fuelData?.length > 0)} height={400}>
                <CardContainer title="Carburante" additionalStyle='h-96' headerElement={(typeof year !== 'undefined') ? <CustomButton color={colorPalette.Accent} label='Gestisci' onClickHandler={handleClick} /> : null}>
                    <BarChart
                        labels={filteredLabels}
                        label=' Litri'
                        title='CARBURANTE CONSUMATO'
                        value={fuelData}
                        backgroundColor='#00BFFF'
                        borderColor='#0080FF' />
                </CardContainer>
            </AsyncLoader>

        </div>
        <div className="flex gap-4">

            <CardContainer title="Autisti" additionalStyle='w-1/2 h-96' wFull={false} headerElement={<AutoComplete itemSelected={autSelected} setItemSelected={setAutSelected} fetchCallBack={searchAutCodes} width={'50%'} valueGroupBy='Abilita' valueToShow='Desc' fieldLabel='Cerca Nome Autista' />}>
                <AsyncLoader condition={fuelData?.length > 0} height={300}>
                    {(autSelected.Code.length !== 0)
                        ? <BarChart
                            labels={filteredLabels}
                            label='Litri'
                            title={`CONSUMO PER ${autSelected.Desc.toUpperCase()}`}
                            value={filteredByDriver}
                            backgroundColor='#FFD700'
                            borderColor='#FFC200 ' />
                        : <p className="h-full flex items-center justify-center p-8 text-sm font-light text-text">Cerca un autista per l'analisi...</p>
                    }
                </AsyncLoader>
            </CardContainer>

            <CardContainer title="Mezzi" additionalStyle='w-1/2 h-96' wFull={false} headerElement={<AutoComplete itemSelected={veicSelected} setItemSelected={setVeicSelected} fetchCallBack={searchMezziTarga} width={'50%'} valueGroupBy='GruCode' valueToShow='Desc' fieldLabel='Cerca Targa Mezzo' />}>
                <AsyncLoader condition={fuelData?.length > 0} height={300}>
                    {(veicSelected.Code.length !== 0)
                        ? <BarChart
                            labels={filteredLabels}
                            label='Litri'
                            title={`CONSUMO PER ${veicSelected.Desc.toUpperCase()}`}
                            value={filteredByVehicles}
                            backgroundColor='#7FFFD4'
                            borderColor='#00CED1' />
                        : <p className="h-full flex items-center justify-center p-8 text-sm font-light text-text">Cerca una targa mezzo per l'analisi...</p>
                    }
                </AsyncLoader>
            </CardContainer>

            {/*             
            <CardContainer title="Viaggi" headerElement={<CustomButton disabled={labels.dateLabels.days?.length !== 0} color={colorPalette.Accent} label='Gestisci' onClickHandler={handleClick} />}>
                <BarChart
                    labels={filteredLabels}
                    label='Litri'
                    title='CONSUMO PER VIAGGIO'
                    value={[65, 59, 80, 81, 56, 55, 40, 34, 12, 55, 90, 100]}
                    backgroundColor='#FFA07A'
                    borderColor='#FF7F50' />
            </CardContainer> */}

        </div>
        <MenuListContainer
            anchorEl={anchorEl}
            isOpen={menuList}
            setIsOpen={setMenuList}
            year={year}
        />


    </div >
}


export default Fuel