import { createSlice, Slice } from "@reduxjs/toolkit";
import { IUser } from "./authType";

interface IAuthState {
    users: IUser[];
}

const initialState: IAuthState = {
    users: [],
};

export const AuthSlice: Slice<IAuthState> = createSlice({
    name: "auth",
    initialState,
    reducers: {
        replaceUsers(state, action) {
            state.users = action.payload.users
        }
    },
});

export const { replaceUsers } = AuthSlice.actions;
export default AuthSlice.reducer;
