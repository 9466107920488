import { fetchData } from "../../utils/fetchData"
import { endpoints } from "../../utils/endpoints"

export const getMonthlyMovementById = async (id: string) => {
    try {
        const data = await fetchData(endpoints.fetchMonthlyMovementById + id);

        // check error and return error 
        if (data instanceof Error) {
            throw new Error(data.message);
        }

        return data.data

    } catch (error: any) {
        console.log("ERROR fetching monthly movement by id: ", error)
    }
}