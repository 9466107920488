import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData";
import { endpoints } from "../../../utils/endpoints";
import { replaceMagoItem } from "../reportSlice";

export const fetchMagoItem = (limitTofetch: number, actualPage: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await fetchData(endpoints.source + endpoints.fetchItemTemplates + "limit=" + limitTofetch + "&page=" + actualPage + '&filter_field=sourceName&field_value=MAGO');

            dispatch(
                replaceMagoItem({
                    magoItem: response.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching orders: ", error)
        }
    }
}
