import React, { useEffect, useState } from 'react';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import FileUpload from '../components/FileUpload';
import CustomButton from '../components/CustomButton';
import { colorPalette } from '../utils/colorPalette';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import { fetchStorageFiles } from '../redux-store/mod-analitics/actions/fetchStorageFiles';
import StorageTable from '../components/StorageTable';
import FeedbackModal from '../utils/FeedbackModal';
import CreateFolder from '../components/CreateFolder';
import { addHistory, popHistory, replaceS3Elements } from '../redux-store/mod-analitics/reportSlice';
import AsyncLoader from '../components/AsyncLoader';
import { fetchData } from '../utils/fetchData';
import { endpoints } from '../utils/endpoints';

const Archive: React.FC = () => {

    const dispatch = useAppDispatch();
    const storageHistory = useAppSelector(state => state.report.storageHistory);

    const [fileModal, setFileModal] = useState(false);
    const [folderModal, setFolderModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //redirect to https://zecchinigroupsrl-my.sharepoint.com/personal/stefanozecchini_zecchinigroupsrl_onmicrosoft_com/_layouts/15/onedrive.aspx?view=0 in a new page
        window.open("https://zecchinigroupsrl-my.sharepoint.com/personal/stefanozecchini_zecchinigroupsrl_onmicrosoft_com/_layouts/15/onedrive.aspx?view=0", "_blank");
    }, [])

    useEffect(() => {
        dispatch(fetchStorageFiles("zecchini2023/"));
    }, [dispatch]);

    const navigateToFolder = async (folder: { name: string, prefix: string }, isNext: boolean = true) => {
        try {
            setIsLoading(true);
            setFolderModal(false);
            setFileModal(false);
            const response = await fetchData(endpoints.fetchS3Elements, { prefix: folder.prefix }, 'POST');
            dispatch(
                replaceS3Elements(response)
            )

            if (isNext)
                dispatch(addHistory({ "name": folder.name, "prefix": folder.prefix }));

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            console.log("ERROR fetching report: ", error)
        }
    };

    /**
     * Navigate to a specific folder in the history
     * @param id - The id of the folder to navigate to
     */
    const goBackToFolder = async (folder: { name: string, prefix: string }) => {
        try {
            setIsLoading(true);
            let arr = [...storageHistory].reverse();
            for (let i = 0; i < arr.length; i++) {
                const folderHistory = arr[i];
                if (folderHistory.prefix === folder.prefix) {

                    const response = await fetchData(endpoints.fetchS3Elements, { prefix: folder.prefix }, 'POST');
                    dispatch(
                        replaceS3Elements(response)
                    )

                    break;
                } else {
                    dispatch(popHistory(storageHistory));
                }
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            console.log("ERROR fetching report: ", error)
        }
    };

    const headerElement = (
        <div className='flex gap-4'>
            <CustomButton color={colorPalette.Accent} label='Crea cartella' onClickHandler={() => setFolderModal(true)} />
            <CustomButton color={colorPalette.Accent} label='Aggiungi File' onClickHandler={() => setFileModal(true)} />
        </div>
    );

    return (
        <GlobalPageTemplate title='Archivio'>
            <>
                <p className='text-text text-xs font-bold mb-4'>
                    {/* redirect text */}
                    <p className='text-text text-md font-bold mb-4'>
                        Stai per essere reindirizzato alla cartella OneDrive...
                    </p>
                    <p className='text-text text-md font-bold mb-4'>
                        Se il reindirizzamento non è andato a buon fine, fai clic sul pulsante sottostante.
                    </p>
                    <button className='text-white text-md uppercase mb-4 bg-blue-500 p-4 rounded-xl shadow-md' onClick={() => window.open("https://zecchinigroupsrl-my.sharepoint.com/personal/stefanozecchini_zecchinigroupsrl_onmicrosoft_com/_layouts/15/onedrive.aspx?view=0", "_blank")}>
                        Vai alla cartella OneDrive
                    </button>
                </p>
                {/* <div className='flex w-full gap-1 mb-4 flex-wrap'>
                    {storageHistory.map((folder, index) => (
                        <p key={index} onClick={() => goBackToFolder(folder)} className='cursor-pointer text-text font-bold text-xs'>
                            {folder.name}
                            <span className='cursor-default text-xs'>{index < storageHistory.length - 1 && " >"}</span>
                        </p>
                    ))}
                </div>

                <AsyncLoader condition={!isLoading} height={200}>
                    <StorageTable handleNavigate={navigateToFolder} />
                </AsyncLoader>

                <FeedbackModal isOpen={fileModal} setIsOpen={setFileModal} title='Aggiungi uno o più file a questa cartella (Max 1GB)' navigateBack={false} >
                    <FileUpload refetch={navigateToFolder} setModal={setFileModal} />
                </FeedbackModal>
                <FeedbackModal isOpen={folderModal} setIsOpen={setFolderModal} title='Crea una cartella' desc={`Questa cartella verrà creata all'interno dell'attuale cartella "${storageHistory.slice(-1)[0].name}"`} navigateBack={false} >
                    <CreateFolder refetch={navigateToFolder} />
                </FeedbackModal> */}
            </>
        </GlobalPageTemplate >
    )
}

export default Archive