import { fetchData } from "../../../utils/fetchData";
import { endpoints } from "../../../utils/endpoints";
import moment from "moment";

export const fetchRifsByDates = async (dates: { startDate: Date; endDate: Date; }) => {
    const formattedDates = {
        startDate: moment(dates.startDate).format('YYYY-MM-DD'),
        endDate: moment(dates.endDate).format('YYYY-MM-DD')
    };

    try {
        const totDailyByCis = await fetchData(endpoints.dailyRifCisDates, formattedDates, 'POST');
        totDailyByCis.map((item: any) => {
            item.Cll /= 100
        })
        return totDailyByCis;

    } catch (error: any) {
        console.log("ERROR fetching orders: ", error)
    }
}
