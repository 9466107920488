import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import CustomButton from '../components/CustomButton';
import { colorPalette } from '../utils/colorPalette';
import FeedbackModal from '../utils/FeedbackModal';
import CustomInputForm from '../components/customInputForm';
import { registerService } from '../api/auth/registerService';
import { IUser } from '../redux-store/auth/authType';
import { setSnackbar } from '../redux-store/uiManager/actions/setSnackbar';
import { DataTable } from '../components/DataTable';
import { fetchUsers } from '../redux-store/auth/actions/fetchUsers';
import { changePasswordService } from '../api/auth/changePasswordService';
import { useLocalStorage } from '../utils/useLocalStorage';

const Account: React.FC = () => {
    const dispatch = useAppDispatch();
    const users = useAppSelector(state => state.auth.users);
    const { getItemLocalStorage } = useLocalStorage();
    const user = getItemLocalStorage("user");

    const [modalPassword, setModalPassword] = useState(false);
    const [modalNewUser, setModalNewUser] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState("");

    // Rejex
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    useEffect(() => {
        dispatch(fetchUsers());
    }, [])


    const closeModalHandler = () => {
        setModalNewUser(false);
        setModalPassword(false);
        setName("");
        setSurname("");
        setEmail("");
        setId("");
        setNewPassword("");
        setConfirmNewPassword("");
    };

    const changePasswordHandler = async () => {
        if (newPassword !== confirmNewPassword) {
            setError('Le password non coincidono');
            return;
        };
        if (newPassword.length === 0) {
            setError('Inserire una password');
            return;
        };

        closeModalHandler()
        dispatch(setSnackbar("Cambio Password in corso", "info"));
        const response = await changePasswordService(id, newPassword);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore nel cambiare la password ", "error"));
        } else {
            dispatch(setSnackbar("Nuova password salvata", "success"));
        }
    };

    const createUserHandler = async () => {
        if (newPassword !== confirmNewPassword) {
            setError('Le password non coincidono');
            return;
        };

        if (name.length === 0 || surname.length === 0) {
            setError('Inserire nome e cognome');
            return;
        };

        if (!emailRegex.test(email)) {
            setError("Inserisci un'email valida");
            return;
        };

        const newUser: IUser = {
            name: name,
            email: email,
            surname: surname,
            password: newPassword
        };

        closeModalHandler()
        dispatch(setSnackbar("Creazione nuovo utente in corso", "info"));
        const response = await registerService(newUser);

        if (typeof response === 'undefined') {
            dispatch(setSnackbar("Errore nel cambiare la password ", "error"));
        } else {
            dispatch(setSnackbar("Nuova password salvata", "success"));
        }
    };

    // const openChangePasswordHandler = (userId: string) => {
    //     setId(userId);
    //     setModalPassword(true);
    // };

    const headerButtons = (
        <CustomButton color={colorPalette.Accent} label='Crea Utente' onClickHandler={() => setModalNewUser(true)} />
    );

    // const newPasswordModal = (userId: string) => {
    //     return (<CustomButton color={colorPalette.Info} label='Cambia password' onClickHandler={() => openChangePasswordHandler(userId)} />)
    // };

    return (
        <GlobalPageTemplate title='Profilo' headerElement={headerButtons} >
            <>
                <div className='flex flex-col items-center gap-2'>
                    <div className='flex text-lg text-text font-semibold'>
                        <p>{user.name} {user.surname}</p>
                    </div>
                    <p className='text-sm text-text font-medium'>{user.email}</p>
                </div>
                <DataTable
                    data={users}
                    defaultSortField={"name"}
                    headerFields={["Id", "Nome", "Cognome", "Email", "Ruolo", "Data Creazione"]}
                    fieldsToPrint={["_id", "name", "surname", "email", "role", "createdAt"]}
                    size='small'
                    showDetailsButton={false}
                // actionUpdate={newPasswordModal}
                />
                <FeedbackModal isOpen={modalPassword} handleCloseCallback={closeModalHandler} title='Cambia Password' standardButton={{ text: 'Cambia password', onClick: changePasswordHandler }}   >
                    <div className='flex flex-col m-2 gap-2'>
                        <CustomInputForm isDisabled={true} label='Id' value={id} setValue={setId} />
                        <CustomInputForm label='Nuova password' type='password' value={newPassword} setValue={setNewPassword} />
                        <CustomInputForm label='Conferma password' type='password' value={confirmNewPassword} setValue={setConfirmNewPassword} />
                        {error && <p style={{ color: colorPalette.Error, fontSize: 12, padding: 5 }}>{error}</p>}
                    </div>
                </FeedbackModal>
                <FeedbackModal isOpen={modalNewUser} handleCloseCallback={closeModalHandler} title='Crea nuovo utente' standardButton={{ text: 'Crea', onClick: createUserHandler }}  >
                    <div className='flex flex-col m-2 gap-2'>
                        <CustomInputForm label='Nome' value={name} setValue={setName} />
                        <CustomInputForm label='Cognome' value={surname} setValue={setSurname} />
                        <CustomInputForm label='Email' value={email} setValue={setEmail} />
                        <CustomInputForm label='Password' type='password' value={newPassword} setValue={setNewPassword} />
                        <CustomInputForm label='Conferma password' type='password' value={confirmNewPassword} setValue={setConfirmNewPassword} />
                        {error && <p style={{ color: colorPalette.Error, fontSize: 12, padding: 5 }}>{error}</p>}
                    </div>
                </FeedbackModal>
            </>
        </GlobalPageTemplate>
    )
}

export default Account