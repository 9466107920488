import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { endpoints } from "../../../utils/endpoints"
import { replaceTanks } from "../dieselwebSlice";

export const fetchTanks = () => {
    return async (dispatch: Dispatch) => {
        try {
            const tanks = await fetchData(endpoints.fetchTanks);
            dispatch(
                replaceTanks({
                    tanks: tanks.data
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching tanks: ", error)
        }
    }
}