import { IUser } from "../redux-store/auth/authType";

export const useLocalStorage = () => {

    const setItemLocalStorage = (key: string, value: string) => {
        localStorage.setItem(key, value);
    };


    const getItemLocalStorage = (key: string): IUser => {
        const value = localStorage.getItem(key);
        return JSON.parse(value);
    };

    const removeItemLocalStorage = (key: string) => {
        localStorage.removeItem(key);
    };

    return { setItemLocalStorage, getItemLocalStorage, removeItemLocalStorage };
};