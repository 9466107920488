import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Box, SxProps } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";

type Props = {
    children?: JSX.Element,
    isOpen: boolean,
    setIsOpen?: Dispatch<SetStateAction<any>>,
    linkButton?: {
        text: string,
        onClick: () => void,
        path: string
    },
    standardButton?: {
        text: string,
        onClick: MouseEventHandler<HTMLButtonElement>
    },
    title?: string,
    desc?: string,
    navigateBack?: boolean,
    handleCloseCallback?: () => void
};

const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    maxHeight: '80%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 4,
    borderRadius: 1,
    p: 2
};

const FeedbackModal: React.FC<Props> = ({ children, isOpen, setIsOpen, linkButton, standardButton, title, desc, navigateBack = true, handleCloseCallback }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        setIsOpen(false);
        if (navigateBack)
            navigate(-1);
    };


    return (
        <Modal open={isOpen} onClose={(handleCloseCallback) ? handleCloseCallback : handleClose}>
            <Box sx={modalStyle}>
                <p className='text-text font-bold text-lg text-center'>{title}</p>
                <p className='text-text font-medium text-xs text-center'>{desc}</p>
                {children}
                <div className='flex justify-around mt-4'>
                    {
                        (linkButton)
                            ? <Link to={`${linkButton.path}`}>
                                <button onClick={linkButton.onClick} className='font-semibold bg-blue-500 hover:bg-blue-600 text-white p-4 pt-2 pb-2 rounded-lg'>{linkButton.text}</button>
                            </Link>
                            : (standardButton)
                                ? <button onClick={standardButton?.onClick} className='font-semibold bg-primary hover:bg-darkGray text-white p-4 pt-2 pb-2 rounded-lg'>{standardButton.text}</button>
                                : null
                    }
                </div>

            </Box>
        </Modal>
    );
};

export default FeedbackModal;
