import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { IReport } from '../../redux-store/mod-analitics/reportType';
import { colorPalette } from '../../utils/colorPalette';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch } from '../../redux-store/store';
import { setSnackbar } from '../../redux-store/uiManager/actions/setSnackbar';
import { fetchOrders } from '../../redux-store/mod-analitics/actions/fetchOrders';
import ReportTable from '../../components/ReportTable';
import { createReport } from '../../api/mod-analitics/createReport';

interface IProps {
    newReport: IReport;
    setNewReport?: Dispatch<SetStateAction<IReport>>;
};

export const SubmitReport: React.FC<IProps> = ({ newReport }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const validator = newReport.commessaId.length > 0 && newReport.itemExpenses.length > 0;

    const handleBack = () => {
        navigate(-1);
    };

    const onSubmit = async () => {
        dispatch(setSnackbar("Creazione nuovo report in corso...", "info"));
        const response = await createReport(newReport);

        if (response instanceof Error) {
            dispatch(setSnackbar("Errore durante la creazione del report", "error"));
            return;
        }

        await dispatch(fetchOrders());
        dispatch(setSnackbar("Report creato con successo!", "success"));
        navigate('/monitoraggio/report', { replace: true });
    };

    return <>
        <ReportTable newReport={newReport} addStyle='mt-8' />

        <div className='flex justify-between mt-8' >
            <CustomButton
                color={colorPalette.Warning}
                label='Back'
                onClickHandler={handleBack} />
            <CustomButton
                disabled={!validator}
                color={colorPalette.Accent}
                label={'Next'}
                onClickHandler={onSubmit} />
        </div >
    </>
}
