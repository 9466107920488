import React, { useEffect, useState } from 'react';
import GlobalPageTemplate from '../templates/GlobalPageTemplate';
import { fetchSupplies } from '../redux-store/mod-dieselweb/actions/fetchSupplies';
import { useAppDispatch, useAppSelector } from '../redux-store/store';
import AsyncLoader from '../components/AsyncLoader';
import { DataTable } from '../components/DataTable';
import { useSearchParams } from 'react-router-dom';
import CustomInputForm from '../components/customInputForm';

const HEADER_FIELDS = ["#", "Data/Ora", "Litri", "Mezzo", "Targa", "Autista", "Codice Viaggio", "Km", "Sistema", "Cisterna", "Pompa"];
const fieldsToPrint = ["Num", "Ora", "Cll", "Mex", "Tar", "Aux", "Via", "Kmm", "Sis", "Cis", "Pom"];

const DieselwebSupplies: React.FC = () => {

    const [searchString, setSearchString] = useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();

    // REDUX 
    const dispatch = useAppDispatch();
    const supplies = useAppSelector(state => state.dieselweb.supplies);

    useEffect(() => {
        setSearchParams(`limit=15&page=1`);
    }, []);

    useEffect(() => {
        if (searchParams.get("limit") !== null)
            dispatch(fetchSupplies(`?${searchParams.toString()}`));
    }, [searchParams]);

    useEffect(() => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=1&filter_field=Mex&field_value=${searchString.toUpperCase()}&isSearch=true`);
        }
        if (searchString?.length === 0) {
            setSearchParams("?limit=15&page=1");
        }
    }, [searchString]);

    const fetchNextPage = () => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=${supplies.currentPage + 1}&filter_field=Mex&field_value=${searchString.toUpperCase()}&isSearch=true`);
        } else {
            setSearchParams(`?limit=15&page=${supplies.currentPage + 1}`);
        }
    };

    const fetchBackPage = () => {
        if (searchString?.length > 0) {
            setSearchParams(`?limit=15&page=${supplies.currentPage - 1}&filter_field=Mex&field_value=${searchString.toUpperCase()}&isSearch=true`);
        } else {
            setSearchParams(`?limit=15&page=${supplies.currentPage - 1}`);
        }
    };

    return (
        <GlobalPageTemplate title='Rifornimenti' >
            <>
                <CustomInputForm value={searchString} setValue={setSearchString} label='Filtra per nome mezzo' style='mb-4' />
                <AsyncLoader height={500} condition={supplies.data.length !== 0}>
                    <DataTable
                        data={supplies.data}
                        defaultSortField={"Ora"}
                        defaultSortDirection='desc'
                        headerFields={HEADER_FIELDS}
                        fieldsToPrint={fieldsToPrint}
                        size='small'
                        showDetailsButton={false}
                        dataCurrentPage={supplies.currentPage - 1}
                        dataCount={supplies.totalItems}
                        rowsPage={15}
                        rowsOptions={[]}
                        nextIconButtonHandler={fetchNextPage}
                        backIconButtonHandler={fetchBackPage}
                    />
                </AsyncLoader>
            </>
        </GlobalPageTemplate>
    )
}

export default DieselwebSupplies