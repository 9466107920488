import { TextField } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { colorPalette } from '../utils/colorPalette'

interface IProps {
    value: string | number,
    setValue: Dispatch<SetStateAction<string | number>>,
    label?: string,
    type?: string,
    style?: string,
    isDisabled?: boolean
};

const CustomInputForm: React.FunctionComponent<IProps> = ({ value, setValue, label, type = "text", style, isDisabled }) => {
    return <div className={`w-full ${style}`}>
        <p className="font-medium text-sm mb-2" style={{ color: colorPalette.Text }}>{label}</p>
        <TextField disabled={isDisabled} type={type} fullWidth value={value} onChange={(event) => setValue(event.target.value)} />
    </div>
}

export default CustomInputForm