import { useEffect, useState } from 'react';
import RoutesPage from './routes/RoutesPage';
import Navbar from './routes/Navbar';
import Sidebar from './routes/Sidabar';
import LoginForm from './views/LoginForm';
import { useLocalStorage } from './utils/useLocalStorage';
import UploadProgressSnackbar from './components/UploadProgressSnackbar';
import { checkToken } from './api/auth/checkToken';

function App() {
  const { removeItemLocalStorage, getItemLocalStorage, setItemLocalStorage } = useLocalStorage();
  const user = getItemLocalStorage('user')
  const showSidebar = () => setClose(!close);
  const [close, setClose] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(undefined);

  useEffect(() => {
    checkUserToken()

    // Run the token expiration check periodically
    if (!!user) {
      const interval = setInterval(() => {
        checkUserToken()
      }, 60000); // Check every minute 

      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  const checkUserToken = async () => {
    try {
      // Server request to check user's Token validation
      const response = await checkToken();

      // If a new JWT was sent, it update the local storage user 
      if (response.newJwt) {
        const updatedUser = { ...user, accessToken: response.newJwt };
        setItemLocalStorage('user', JSON.stringify(updatedUser));
      }

      if (response.isTokenValid) {
        setIsTokenValid(true);
      } else {
        removeItemLocalStorage("user");
        setIsTokenValid(false);
      }
    } catch (error: any) {
      console.log("ERROR while checking user token: ", error.message)
    }
  };

  if (isTokenValid && typeof isTokenValid !== 'undefined') {
    return <div className='flex w-full h-full'>

      {/* Sidebar */}
      <Sidebar close={close} />

      <div className=' flex flex-col flex-grow h-full'>

        {/* Navbar */}
        <Navbar showSidebar={showSidebar} />

        {/* Route */}
        <RoutesPage />

      </div>

      {/* SnackBar for Upload on S3 Progress */}
      <UploadProgressSnackbar />
    </div>
  }

  if (!isTokenValid && typeof isTokenValid !== 'undefined') {
    return <LoginForm setIsTokenValid={setIsTokenValid} />
  }
}

export default App;
