import React, { useState } from "react";
import { colorPalette } from "../utils/colorPalette";
import CustomText from "../components/CustomText";
import { loginService } from "../api/auth/loginService";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";

interface IProps {
    setIsTokenValid: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginForm: React.FC<IProps> = ({ setIsTokenValid }) => {
    const { setItemLocalStorage } = useLocalStorage();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    // Rejex
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (password.length === 0) {
            setError('Inserire Password');
            return;
        };

        if (!emailRegex.test(email)) {
            setError("Inserisci un' email valida");
            return;
        };

        try {
            const response = await loginService(email.trim(), password);
            if (typeof response.email !== 'undefined') {
                setItemLocalStorage('user', JSON.stringify(response));
                setIsTokenValid(true)
                // Redirect to home page after successful login
                navigate('/monitoraggio/commesse', { replace: true })
            }
        } catch (error) {
            setError("Email o password errate!");
            console.error('ERROR Login: ' + error);
        }
    };


    return (
        <div className="flex w-full h-full justify-center">
            <form className="w-72 h-fit flex flex-col items-center mt-12 border-2 p-5 rounded-md shadow-md bg-white" style={{ borderColor: colorPalette.Primary }} onSubmit={handleSubmit}>
                <CustomText label="Login" size='lg' />
                <div className="flex flex-col mb-4 w-full">
                    <label style={{ color: colorPalette.Text }} htmlFor="username">Username:</label>
                    <input
                        className="p-2 border rounded-md text-base"
                        style={{ borderColor: colorPalette.Primary }}
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div >
                <div className="flex flex-col mb-4 w-full">
                    <label style={{ color: colorPalette.Text }} className="mb-1" htmlFor="password">Password:</label>
                    <input
                        className="p-2 border rounded-md text-base"
                        style={{ borderColor: colorPalette.Primary }}
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                {error && <p style={{ color: colorPalette.Error, fontSize: 12, padding: 5 }}>{error}</p>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginForm;
