import { useLocalStorage } from "../utils/useLocalStorage";
interface IProps {
    children: JSX.Element;
}
const AdminRoute: React.FC<IProps> = ({ children }) => {
    const { getItemLocalStorage } = useLocalStorage();
    const user = getItemLocalStorage("user");

    if (user.role !== 'admin') {
        return <p className="text-center w-full text-lg font-bold text-warning mt-60">Non hai le autorizzazioni per visualizzare questa pagina!</p>;
    }

    return children;
};

export default AdminRoute;