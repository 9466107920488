import { Dispatch } from "redux";
import { fetchData } from "../../../utils/fetchData"
import { endpoints } from "../../../utils/endpoints"
import { replaceUsers } from "../authSlice";

export const fetchUsers = () => {
    return async (dispatch: Dispatch) => {
        try {
            const users = await fetchData(endpoints.fetchUsers);
            dispatch(
                replaceUsers({
                    users: users
                })
            )
        } catch (error: any) {
            console.log("ERROR fetching users: ", error)
        }
    }
}