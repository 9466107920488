import React, { useEffect, useState } from "react";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { useSearchParams } from "react-router-dom";
import { tenec_machinery_list } from "../data/machinery_list";
import AsyncLoader from "../components/AsyncLoader";
import { Chip } from "@material-ui/core";
import GeoMap from "../components/GeoMap";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/endpoints";

interface IPropsDefaultInfo {
    machineSelected: any;
}

const HEADER_FIELDS = ["#", "Nome Macchinario", "Tipo Macchinario", "Data Creazione"];
const fieldsToPrint = ["id", "name", "type", "created_at"];


const MacchinariTenecDetails: React.FunctionComponent = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [machineSelected, setMarchineSelected] = useState(undefined);
    const [apiData, setApiData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        const id = searchParams.get("id");
        setMarchineSelected(tenec_machinery_list.find((machine) => machine.id === id));

        // call api and set api data to state apiData
        retrieveApiData(id);
    }, []);

    const retrieveApiData = async (id: string | null) => {
        try {
            setIsLoading(true);
            const response = await fetchData(endpoints.fetchTenecData);
            setApiData(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
            console.log("ERROR fetchin tenec: " + error)
        }
    }

    return (
        <AsyncLoader condition={typeof machineSelected !== 'undefined'} height={300}>
            <GlobalPageTemplate title={`Dettagli Macchinario: ${machineSelected?.name}`}>
                <>
                    <DefaultInfo machineSelected={machineSelected} />
                    {/* api retrieved info */}
                    {(!isLoading)
                        ? <>
                            <div className="flex flex-row items-center mt-8">
                                <p className="text-2xl font-medium">Informazioni da Api Tenec</p>
                                <Chip label={`${apiData.compactor[0].Online === 0 ? "Stato: Offline" : "Stato: Online"}`} color={`${apiData.compactor[0].Online === 0 ? "secondary" : "primary"}`} className="ml-4" />
                            </div>

                            <div className="flex flex-col mt-4">
                                <p className="text-lg font-medium">Numero chiamata giornaliera: {apiData.Day_call}</p>
                                <p className="text-lg font-medium mb-4">Sono rimaste {`${50 - apiData.Day_call}`} chiamate disponibili.</p>

                                <p className="text-lg font-medium">Codice Macchinario: {apiData.compactor[0].Code}</p>
                                <p className="text-lg font-medium">Ultimo aggiornamento: {apiData.compactor[0].Last_update}</p>
                                <p className="text-lg font-medium">Contatore motore: {apiData.compactor[0].Motor_counter}</p>
                                <p className="text-lg font-medium">Carico: {apiData.compactor[0].Load}</p>
                            </div>

                            <p className="text-2xl font-medium mt-8">Geolocalizzazione:</p>
                            <GeoMap centerLatidude={parseFloat(apiData.compactor[0].Latitude)} centerLongitude={parseFloat(apiData.compactor[0].Longitude)} data={apiData.compactor} />
                        </>
                        : <p className="p-8 text-center text-sm font-medium text-text">Caricamento...</p>
                    }
                </>
            </GlobalPageTemplate>
        </AsyncLoader>
    )
}


const DefaultInfo: React.FunctionComponent<IPropsDefaultInfo> = ({ machineSelected }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Nome Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Tipo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.type}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Data Creazione:</p>
                        <p className="text-lg">{machineSelected?.created_at}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MacchinariTenecDetails;