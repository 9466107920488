import React, { ReactNode } from "react";
import { colorPalette } from "../utils/colorPalette";

interface IContainerCard {
    children: ReactNode,
    title?: string,
    additionalStyle?: string,
    wFull?: boolean,
    titleCenter?: boolean,
    headerElement?: JSX.Element,
};

export const CardContainer: React.FunctionComponent<IContainerCard> = ({ children, title, additionalStyle, wFull = true, titleCenter = false, headerElement }) => {
    return (
        <div className={`flex flex-col p-4 mt-4 mb-4 rounded-lg shadow-md ${(wFull) ? 'w-full' : ''}  ${additionalStyle}`} style={{ backgroundColor: colorPalette.Background }}>
            <div className={`flex items-center ${(titleCenter) ? 'justify-center' : 'justify-between'}`}>
                <h1 className={`text-xl font-semibold`} style={{ color: colorPalette.Text }}>{title}</h1>
                {headerElement}
            </div>
            {children}
        </div>
    )
}
