import { useEffect, useState } from "react";
import AsyncLoader from "../components/AsyncLoader";
import GeoMap, { GeoMapV2 } from "../components/GeoMap";
import GlobalPageTemplate from "../templates/GlobalPageTemplate";
import { Chip } from "@mui/material";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/endpoints";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { trackvision_machinery_list } from "../data/machinery_list";


const MacchinariTrackVisionDetails: React.FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [machine, setMachine] = useState(undefined);
    const [apiData, setApiData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const id = searchParams.get("id");
        trackvision_machinery_list.forEach((machine) => {
            if (machine.id === id) {
                setMachine(machine);
            }
        })

        // call api and set api data to state apiData
        retrieveApiData();
    }, []);

    const retrieveApiData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(endpoints.fetchTrackVisionMachineData);
            const data = response.data;

            data.forEach((machine: any) => {
                if (machine?.machine_identification === searchParams.get("id")) {
                    console.log(machine)
                    setApiData(machine);
                }
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
            console.log("ERROR fetchin tenec: " + error)
        }
    }
    

    useEffect(() => {
        console.log("MACHINE: " + JSON.stringify(machine, null, 4));
    }, [machine]);

    return (
            <GlobalPageTemplate title={`Dettagli Macchinario: ${machine?.name}`}>
                <>
                    {/* api retrieved info */}
                    {(!isLoading)
                        ? <>
                            <DefaultInfo machineSelected={machine} />

                            <div className="flex flex-col mt-4">
                                <p className="text-lg font-medium">Firmware: {apiData?.firmware}</p>

                                <p className="text-lg font-medium">Ultima comunicazione: {apiData?.last_communication}</p>
                                <p className="text-lg font-medium">Data ultima posizione gps registrata: {apiData?.last_gps_position_date}</p>
                                <p className="text-lg font-medium">Ultima posizione gps registrata: {apiData?.address}</p>
                                <p className="text-lg font-medium">Latitudine: {apiData?.latitude}</p>
                                <p className="text-lg font-medium">Longitudine: {apiData?.longitude}</p>
                            </div>

                            <p className="text-2xl font-medium mt-8">Geolocalizzazione:</p>
                            <GeoMapV2 centerLatidude={apiData?.latitude} centerLongitude={apiData?.longitude} />
                            
                        </>
                        : <p className="p-8 text-center text-sm font-medium text-text">Caricamento...</p>
                    }
                </>
            </GlobalPageTemplate>
    )
}

interface IPropsDefaultInfo {
    machineSelected: any
}

const DefaultInfo: React.FunctionComponent<IPropsDefaultInfo> = ({ machineSelected }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Identificativo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Nome Macchinario:</p>
                        <p className="text-lg">{machineSelected?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Tipo Macchinario:</p>
                        <p className="text-lg">{machineSelected?.type}</p>
                    </div>
                    <div className="flex flex-row">
                        <p className="text-lg mr-2">Data Creazione:</p>
                        <p className="text-lg">{machineSelected?.created_at}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MacchinariTrackVisionDetails;