import { Autocomplete, TextField } from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';

interface IProps {
    width: string | number,
    fieldLabel: string,
    valueToShow: string,
    valueGroupBy: string,
    itemSelected: Record<string, string>,
    setItemSelected: Dispatch<React.SetStateAction<Record<string, string>>>,
    fetchCallBack: (arg0: string) => Promise<any>
};

const AutoComplete: React.FunctionComponent<IProps> = ({ width = '100%', fieldLabel, valueToShow, valueGroupBy, itemSelected, setItemSelected, fetchCallBack }) => {

    const [options, setOptions] = useState<any[]>([]);
    const [nameQuery, setNameQuery] = useState<string>("");
    const expenseKeys = Object.keys(itemSelected);

    useEffect(() => {
        // run db query only when user ends typing
        if (nameQuery?.length > 1) {
            const timer = setTimeout(() => {
                handleAutocompleteChange(nameQuery);
            }, 500);

            return () => clearTimeout(timer);
        };
    }, [nameQuery]);

    const handleAutocompleteChange = (value: string) => {
        console.log('called query for value: ', value);
        if (value?.length > 1) {
            fetchCallBack(value)
                .then(data => {
                    const retrievedData = data.map((item: any, index: number) => { return { ...item, index: index + 1 } })
                    setOptions(retrievedData);
                })
        };
    };

    const handleInput = (value: string) => {
        setNameQuery(value);
        setItemSelected({ ...itemSelected, [expenseKeys[0]]: value });
    };

    const handleClickAutoComplete = (element: any) => {
        const newExpenseCopy: Record<string, string> = { ...itemSelected };
        expenseKeys.map(key => { newExpenseCopy[key] = element[key] });
        setItemSelected(newExpenseCopy);
    };

    const renderOptionHandler = (props: any, option: any) => {
        return (
            <div {...props} key={option.index}>
                <div onClick={() => handleClickAutoComplete(option)} className="flex flex-row justify-between cursor-pointer hover:bg-gray-100">
                    <p className="font-medium">#{option.index} {option[`${valueToShow}`]}</p>
                </div>
            </div >
        );
    };

    return (
        <Autocomplete
            value={itemSelected[expenseKeys[0]]}
            options={options}
            getOptionLabel={(option) => option.name || itemSelected[expenseKeys[0]]}
            groupBy={(option) => option[`${valueGroupBy}`]}
            sx={{ width: width }}
            clearOnBlur={false}
            clearOnEscape={false}
            renderOption={(props, options) => renderOptionHandler(props, options)}
            renderInput={(params) => <TextField
                {...params}
                onChange={(e) => handleInput(e.target.value)}
                onBlur={(e) => handleInput(e.target.value)}
                label={fieldLabel}
            />}
            freeSolo
            includeInputInList
            clearText="Cancella"
            noOptionsText="Nessun risultato"
        />
    )
}

export default AutoComplete